import { makeRequestSQL } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptMiddleware } from '../services/decryptMiddleware';

export const fetchFindFailStatuses = async () => {
  try {
    const response = await makeRequestSQL.get('/find-fail/find-fail-statuses');
    const decryptedData = await  decryptMiddleware(response?.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchFindFailCategories = async () => {
  try {
    const response = await makeRequestSQL.get(
      '/find-fail/find-fail-categories',
    );
    const decryptedData = await  decryptMiddleware(response?.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchFindFail = async ({
  page,
  limit,
  sortBy,
  order,
  category,
  status,
  full_name
}) => {
  try {
    const response = await makeRequestSQL.get('/find-fail/', {
      params: { page, limit, sortBy, order, category, status, full_name },
    });

    const decryptedData = await  decryptMiddleware(response?.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createMutationFindFail = async data => {
  try {
    const response = await makeRequestSQL.post('/find-fail/', data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateMutationFindFail = async data => {
  try {
    const response = await makeRequestSQL.put('/find-fail/', data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteMutationFindFail = async find_fail_id => {
  try {
    const response = await makeRequestSQL.delete(`/find-fail/`, {
      params: {
        find_fail_id,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};
