import { Outlet } from "react-router-dom";
import { Main } from "../../Main/Main";
import { useNavigate } from "react-router";
import { UserContext } from "../../Context/UserContext";
import React, { useContext, useState, useEffect } from "react";
import { formattedDateToDayMonthYear } from "../../../helpers/dateAndTime/formattedDateToDayMonthYear";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { uk, ru } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import { translateLabel } from "../../../helpers/reactDateRange/translateLabel";
import { useQuery } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "../../Modal/Modal";
import {
  SaveButton,
  NavContainer,
  AddedNewButton,
  Thead,
  TableContainer,
  WiretappingTable,
  WiretappingTr,
  WiretappingHeadTr,
  WiretappingTd,
  WiretappingTh,
  WiretappingFaCheckCircle,
  WiretappingFaTimesCircle,
  WiretappingFaMinusCircle,
  AddedSelectedContainer,
  AddedSelect,
  AddedOption,
  SearchContainer,
  SearchInput,
  SearchButton,
  LinkButton,
} from "./CheckList.styled";
import { wiretappingCheckList } from "../../../services/componentText";
import { fetchOrderStatus, fetchCheckList } from "../../../api/wiretapping";
import { fetchFineStatuses } from "../../../api/fines";
import { fetchUsers } from "../../../api/users";
import ReactH5AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { ClipLoader } from "react-spinners";
import { v4 as uuidv4 } from "uuid";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import { AiOutlineSearch } from "react-icons/ai";
import { Pagination } from "../../Pagination/Pagination";
const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);

export const CheckList = () => {
  const { currentUser, language } = useContext(UserContext);
  const navigate = useNavigate();
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [isImageModalOpen, setIsImageOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const currentDate = new Date().getDate();
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedCreatedEmployee, setSelectedCreatedEmployee] = useState("");
  const [selectedOrderStatusId, setSelectedOrderStatusId] = useState("");
  const [selectedFinesStatusId, setSelectedFinesStatusId] = useState("");
  const [period, setPeriod] = useState([
    {
      startDate:
        currentDate >= 3
          ? new Date(currentYear, currentMonth - 1, currentDate - 1)
          : new Date(currentYear, currentMonth - 1, 1),
      endDate: new Date(currentYear, currentMonth - 1, currentDate),
      key: "selection",
    },
  ]);
  const formattedStartDate = format(period[0]?.startDate, "dd.MM.yyyy");
  const formattedEndDate = format(period[0]?.endDate, "dd.MM.yyyy");
  const formattedStartDateRequest = format(period[0]?.startDate, "yyyy.MM.dd");
  const formattedEndDateRequest = format(period[0]?.endDate, "yyyy.MM.dd");
  const [sortBy, setSortBy] = useState("createdAt");
  const [order, setOrder] = useState("DESC");
  const [searchQuery, setSearchQuery] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  const {
    data: wiretappingData,
    refetch,
    isLoading,
  } = useQuery(
    [
      "wiretappingCheckList",
      formattedStartDateRequest,
      formattedEndDateRequest,
      sortBy,
      order,
      selectedEmployee,
      selectedCreatedEmployee,
      selectedOrderStatusId,
      selectedFinesStatusId,
      searchQuery,
      currentPage,
      pageLimit,
    ],
    () => {
      if (
        currentUser?.access_level !== null &&
        currentUser?.access_level !== undefined
      ) {
        return fetchCheckList({
          startDate: formattedStartDateRequest,
          endDate: formattedEndDateRequest,
          sortBy,
          order,
          employee_create_full_name: accessLevel
            ? selectedCreatedEmployee
            : currentUser?.full_name,
          employee_full_name: selectedEmployee,
          searchQuery,
          currentPage,
          pageLimit,
          order_status_id: selectedOrderStatusId,
          fines_status_id: selectedFinesStatusId,
        });
      }
    }
  );

  const { data: usersData } = useQuery(["getAllEmployees"], () =>
    fetchUsers({ sortBy: "full_name", order: "ASC", isBlocked: "0" })
  );

  const { data: orderStatusData } = useQuery("orderStatus", fetchOrderStatus);

  const { data: finesStatusData } = useQuery(
    "finesStatusData",
    fetchFineStatuses
  );

  const customDefaultStaticRanges = defaultStaticRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, language === "russian" ? "ru" : "uk"),
  }));

  const customDefaultInputRanges = defaultInputRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, language === "russian" ? "ru" : "uk"),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  const handleSortChange = (field) => {
    if (sortBy === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  const renderSortIcon = (field) => {
    if (sortBy === field) {
      return order === "ASC" ? "▲" : "▼";
    }
    return "";
  };

  const handleClick = (item) => {
    return navigate(`/wiretapping/change-check-list/${item?.id}`);
  };

  const getIcon = (value) => {
    if (value !== null && Number(value) === 1) {
      return <WiretappingFaCheckCircle fill="green" />;
    } else if (value !== null && Number(value) === 0) {
      return <WiretappingFaTimesCircle fill="red" />;
    } else {
      return <WiretappingFaMinusCircle fill="gray" />;
    }
  };

  const createImageURL = (imageData, imageType) => {
    const binaryData = new Uint8Array(imageData);
    const blob = new Blob([binaryData], { type: `image/${imageType}` });
    return URL.createObjectURL(blob);
  };

  const createAudioURL = (audioData, audioType) => {
    const binaryData = new Uint8Array(audioData);
    const blob = new Blob([binaryData], { type: `audio/${audioType}` });
    return URL.createObjectURL(blob);
  };

  const handleImageClick = (image) => {
    setIsImageOpenModal(true);
    setSelectedImage(image);
  };

  const handleSearch = (event) => {
    event?.preventDefault();
    setSearchQuery(inputValue);
    refetch();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    if (wiretappingData?.data) {
      setTotalPages(wiretappingData?.data?.totalPages);
    }
  }, [wiretappingData?.data, pageLimit]);

  const handleClearValue = () => {
    setSelectedEmployee("");
    setSelectedCreatedEmployee("");
    setSelectedOrderStatusId("");
    setSelectedFinesStatusId("");
    setInputValue("");
    setSearchQuery("");
  };

  return (
    <div>
      <Main>
        <NavContainer>
          <div
            style={{ marginRight: "10px" }}
          >{`${formattedStartDate} - ${formattedEndDate}`}</div>
          <AddedNewButton onClick={() => setIsModalDateOpen(true)}>
            {wiretappingCheckList[language][0]?.choosePeriod}
          </AddedNewButton>
          <LinkButton to={`/wiretapping/new-check-list`}>
            {wiretappingCheckList[language][0]?.addNewCheckList}
          </LinkButton>
          <AddedNewButton onClick={() => handleClearValue()}>
            {wiretappingCheckList[language][0]?.clearAllSearchValue}
          </AddedNewButton>
        </NavContainer>
        {isModalDateOpen && (
          <Modal closeModal={() => setIsModalDateOpen(false)}>
            <DateRangePicker
              onChange={(item) => setPeriod([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              locale={currentUser?.language === "russian" ? ru : uk}
              staticRanges={customDefaultStaticRanges}
              inputRanges={customDefaultInputRanges}
              months={2}
              ranges={period}
              direction="horizontal"
              style={{ margin: "20px" }}
            />
            <SaveButton onClick={() => handleDate()}>
              {wiretappingCheckList[language][0]?.update}
            </SaveButton>
          </Modal>
        )}

        {isImageModalOpen && (
          <Modal closeModal={() => setIsImageOpenModal(false)}>
            <div style={{ width: "95%" }}>
              <img
                src={createImageURL(selectedImage?.data, selectedImage?.type)}
                alt="Скріншот"
                width="100%"
                height="100%"
              />
            </div>
          </Modal>
        )}

        <div
          style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
        >
          <SearchContainer onSubmit={(e) => handleSearch(e)}>
            <SearchInput
              type="text"
              placeholder={wiretappingCheckList[language][0]?.enterSearchQuery}
              value={inputValue}
              onChange={(e) => setInputValue(e?.target?.value)}
              onKeyDown={handleKeyPress}
            />
            <SearchButton type="submit" onClick={(e) => handleSearch(e)}>
              <AiOutlineSearch size="24px" />
            </SearchButton>
          </SearchContainer>

          <PageLimitSelector
            pageLimit={pageLimit}
            onChange={handlePageLimitChange}
          />
        </div>

        <AddedSelectedContainer>
          <div>
            <label>
              {wiretappingCheckList[language][0]?.chooseEmployeeOne}
            </label>
            <AddedSelect
              value={selectedEmployee || ""}
              onChange={(e) => setSelectedEmployee(e.target.value)}
              style={{ backgroundColor: "#f5f7fa" }}
            >
              <AddedOption value="">
                {wiretappingCheckList[language][0]?.chooseEmployeeTwo}
              </AddedOption>
              {usersData?.usersData?.map(
                (user) =>
                  (Number(user?.id_job_title) === 3  ||
                    Number(user?.id_job_title) === 5 ||
                    Number(user?.id_job_title) === 9 ||
                    Number(user?.id_job_title) === 10 ||
                    Number(user?.id_job_title) === 11 ||
                    Number(user?.id_job_title) === 12 ||
                    Number(user?.id_job_title) === 13) && (
                    <AddedOption value={user?.full_name} key={user?.full_name}>
                      {user?.full_name}
                    </AddedOption>
                  )
              )}
            </AddedSelect>
          </div>
          {accessLevel && (
            <div style={{ marginLeft: "10px" }}>
              <label>
                {wiretappingCheckList[language][0]?.chooseManagerOne}
              </label>
              <AddedSelect
                value={selectedCreatedEmployee || ""}
                onChange={(e) => setSelectedCreatedEmployee(e.target.value)}
                style={{ backgroundColor: "#f5f7fa" }}
              >
                <AddedOption value="">
                  {wiretappingCheckList[language][0]?.chooseManagerTwo}
                </AddedOption>
                {usersData?.usersData?.map(
                  (user) =>
                    Number(user?.id_job_title) === 4 && (
                      <AddedOption
                        value={user?.full_name}
                        key={user?.full_name}
                      >
                        {user?.full_name}
                      </AddedOption>
                    )
                )}
              </AddedSelect>
            </div>
          )}
        </AddedSelectedContainer>

        <AddedSelectedContainer>
          <div>
            <label>{wiretappingCheckList[language][0]?.orderStatus}</label>
            <AddedSelect
              value={selectedOrderStatusId || ""}
              onChange={(e) => setSelectedOrderStatusId(e.target.value)}
              style={{ backgroundColor: "#f5f7fa" }}
            >
              <AddedOption value="">
                {wiretappingCheckList[language][0]?.orderStatus}
              </AddedOption>
              {orderStatusData?.map((status) => (
                <AddedOption value={status?.id} key={status?.id}>
                  {language === "russian"
                    ? status?.status_title_ru
                    : status?.status_title_ua}
                </AddedOption>
              ))}
            </AddedSelect>
          </div>

          <div>
            <label>{wiretappingCheckList[language][0]?.finesStatus}</label>
            <AddedSelect
              value={selectedFinesStatusId || ""}
              onChange={(e) => setSelectedFinesStatusId(e.target.value)}
              style={{ backgroundColor: "#f5f7fa" }}
            >
              <AddedOption value="">
                {wiretappingCheckList[language][0]?.finesStatus}
              </AddedOption>
              {finesStatusData?.map((status) => (
                <AddedOption value={status?.id} key={status?.id}>
                  {language === "russian"
                    ? status?.fine_status_title_ru
                    : status?.fine_status_title_ua}
                </AddedOption>
              ))}
            </AddedSelect>
          </div>
        </AddedSelectedContainer>

        <TableContainer>
          <WiretappingTable>
            <Thead>
              <WiretappingHeadTr>
                <WiretappingTh onClick={() => handleSortChange("createdAt")}>
                  {wiretappingCheckList[language][0]?.createdDate}
                  {renderSortIcon("createdAt")}
                </WiretappingTh>
                <WiretappingTh
                  onClick={() => handleSortChange("employee_full_name")}
                >
                  {wiretappingCheckList[language][0]?.fullName}
                  {renderSortIcon("employee_full_name")}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.orderNumber}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.productTitle}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.statusOrder}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.statusFines}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.finesAmount}
                </WiretappingTh>
                <WiretappingTh
                  onClick={() => handleSortChange("employee_create_full_name")}
                >
                  {wiretappingCheckList[language][0]?.createdFullName}
                  {renderSortIcon("employee_create_full_name")}
                </WiretappingTh>
                <WiretappingTh style={{ width: "320px" }}>
                  {wiretappingCheckList[language][0]?.finesCategory}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.managerComment}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.screenshot}
                </WiretappingTh>
                <WiretappingTh style={{ width: "298px" }}>
                  {wiretappingCheckList[language][0]?.audioFile}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.greetting}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.identifyingNeeds}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.identifyingNeeds}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.presentation}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.offerOfKits}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.offeringAnImprovedVersion}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.crossailOffer}
                </WiretappingTh>
                <WiretappingTh>
                  {
                    wiretappingCheckList[language][0]
                      ?.theClientImmediatelyAgreed
                  }
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.handlingObjections}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.undercharacterization}
                </WiretappingTh>
                <WiretappingTh>
                  {
                    wiretappingCheckList[language][0]
                      ?.characteristicsStatedCorrectly
                  }
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.fullNameVerification}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.fullNameCorrect}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.addressVerification}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.addressCorrect}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.npDepartmentVerification}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.npDepartmentCorrect}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.orderListVerification}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.orderListCorrect}
                </WiretappingTh>
                <WiretappingTh>
                  {
                    wiretappingCheckList[language][0]
                      ?.totalOrderAmountVerification
                  }
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.totalOrderAmountCorrect}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.plusDeliveryAmount}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.orderApprovedByClient}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.motivationForRansom}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.hundredPercentCashback}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.orderStatusCorrect}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.callTimeExceeded}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.thirdPartyVerification}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.thirdPartyCorrect}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.callingBackThirdParty}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.conversationReset}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.tryCallBack}
                </WiretappingTh>
                <WiretappingTh>
                  {
                    wiretappingCheckList[language][0]
                      ?.operatorHandleObjectionToSave
                  }
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.operatorSpeakCorrectly}
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.isOperatorLeftComment}
                </WiretappingTh>
                <WiretappingTh>
                  {
                    wiretappingCheckList[language][0]
                      ?.isOperatorProposalClubCard
                  }
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.isCorrectClaimProcessing}
                </WiretappingTh>
                <WiretappingTh>
                  {
                    wiretappingCheckList[language][0]
                      ?.isCorrectInsertIntoBlackList
                  }
                </WiretappingTh>
                <WiretappingTh>
                  {wiretappingCheckList[language][0]?.isEmptyOrderDrain}
                </WiretappingTh>
              </WiretappingHeadTr>
            </Thead>
            <tbody>
              {isLoading && (
                <WiretappingTr>
                  <WiretappingTd
                    colSpan={13}
                    style={{
                      width: "100%",
                      textAlign: "start",
                      paddingLeft: "8%",
                    }}
                  >
                    <ClipLoader color={"#123abc"} size={50} />
                  </WiretappingTd>
                </WiretappingTr>
              )}
              {wiretappingData?.data?.data?.length > 0 ? (
                <React.Fragment>
                  {wiretappingData?.data?.data?.map((item) => {
                    const finesCategoryDataString =
                      item?.fines_category || "[]";
                    const finesCategoryData = JSON.parse(
                      finesCategoryDataString
                    );

                    return (
                      <WiretappingTr key={item.id}>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {formattedDateToDayMonthYear(item.createdAt)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {item.employee_full_name}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {item.order_id}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {item.product_title}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {language === "russian"
                            ? item.status_title_ru
                            : item.status_title_ua}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {language === "russian"
                            ? item.fine_status_title_ru
                            : item.fine_status_title_ua}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {item.fine_amount} грн
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {item.employee_create_full_name}
                        </WiretappingTd>
                        <WiretappingTd
                          onClick={() => handleClick(item)}
                          style={{ width: "342px" }}
                        >
                          {Array.isArray(finesCategoryData) ? (
                            finesCategoryData.map((value) => {
                              const key = uuidv4();
                              return (
                                <div key={key} style={{ marginBottom: "10px" }}>
                                  <span>
                                    {language === "russian"
                                      ? value?.fine_title_ru
                                      : value?.fine_title_ua}
                                  </span>
                                </div>
                              );
                            })
                          ) : (
                            <div>-</div>
                          )}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {item.check_list_comment
                            ? item.check_list_comment
                            : "-"}
                        </WiretappingTd>

                        <WiretappingTd
                          onClick={() => handleImageClick(item?.url_image_1)}
                        >
                          {item?.url_image_1?.data &&
                          item?.url_image_1?.type ? (
                            <img
                              src={createImageURL(
                                item.url_image_1.data,
                                item.url_image_1.type
                              )}
                              alt="Скріншот"
                              width="100px"
                              height="100px"
                            />
                          ) : (
                            <div>-</div>
                          )}
                        </WiretappingTd>

                        <WiretappingTd style={{ width: "320px" }}>
                          {item?.url_record_1 && (
                            <ReactH5AudioPlayer
                              autoPlayAfterSrcChange={false}
                              key={item.id}
                              src={createAudioURL(
                                item?.url_record_1?.data,
                                item?.url_record_1?.type
                              )}
                            />
                          )}
                          {item?.url_record_2 && (
                            <ReactH5AudioPlayer
                              autoPlayAfterSrcChange={false}
                              src={createAudioURL(
                                item?.url_record_2?.data,
                                item?.url_record_2?.type
                              )}
                            />
                          )}
                          {item?.url_record_3 && (
                            <ReactH5AudioPlayer
                              autoPlayAfterSrcChange={false}
                              src={createAudioURL(
                                item?.url_record_3?.data,
                                item?.url_record_3?.type
                              )}
                            />
                          )}
                          {item?.url_record_4 && (
                            <ReactH5AudioPlayer
                              autoPlayAfterSrcChange={false}
                              src={createAudioURL(
                                item?.url_record_4?.data,
                                item?.url_record_4?.type
                              )}
                            />
                          )}
                          {item?.url_record_5 && (
                            <ReactH5AudioPlayer
                              autoPlayAfterSrcChange={false}
                              src={createAudioURL(
                                item?.url_record_5?.data,
                                item?.url_record_5?.type
                              )}
                            />
                          )}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_greeting)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_identifying_needs)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_identifying_needs)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_presentation)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_offer_of_kits)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_offering_an_improved_version)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_crosssail_offer)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_the_client_immediately_agreed)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_handling_objections)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_undercharacterization)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.are_characteristics_stated_correctly)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_full_name_verification)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_full_name_correct)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_address_verification)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_address_correct)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_np_department_verification)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_np_department_correct)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_order_list_verification)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_order_list_correct)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_total_order_amount_verification)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_total_order_amount_correct)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_plus_delivery_amount)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_order_approved_by_client)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_motivation_for_ransom)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_hundred_percent_cashback)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_order_status_correct)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_call_time_exceeded)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_third_party_verification)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_third_party_correct)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_calling_back_third_party)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_conversation_reset)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_try_call_back)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_operator_handle_objection_to_save)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_operator_speak_correctly)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_comment)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_proposal_clubcard)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_correct_claim_processing)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_correct_insert_into_black_list)}
                        </WiretappingTd>
                        <WiretappingTd onClick={() => handleClick(item)}>
                          {getIcon(item.is_empty_order_drain)}
                        </WiretappingTd>
                      </WiretappingTr>
                    );
                  })}
                </React.Fragment>
              ) : (
                <WiretappingTr>
                  <WiretappingTd
                    colSpan={13}
                    style={{
                      width: "2000px",
                      textAlign: "start",
                      paddingLeft: "50px",
                    }}
                  >
                    {wiretappingCheckList[language][0]?.notDataOrError}
                  </WiretappingTd>
                </WiretappingTr>
              )}
            </tbody>
          </WiretappingTable>
          {Number(totalPages) > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </TableContainer>
      </Main>
      <Outlet />
    </div>
  );
};
