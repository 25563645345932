import { Main } from '../../components/Main/Main';
import { SharedLayout } from '../../components/SharedLayout/SharedLayout';
import { useContext } from 'react';
import { UserContext } from '../../components/Context/UserContext';
import { ManagerSalary } from '../../components/Salary/ManagerSalary/ManagerSalary';
import { UserSalary } from '../../components/Salary/UserSalary/UserSalary';
import { Outlet } from 'react-router-dom';

export const Salary = () => {
  const { currentUser } = useContext(UserContext);
  const accessLevel = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
  const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
  const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
  const access_level_c = Number(process.env.REACT_APP_C_LEVEL_ACCESS);
  const access_level_h = Number(process.env.REACT_APP_H_LEVEL_ACCESS);

  return (
    <div>
      <SharedLayout />
      <Main>
        {currentUser?.access_level === accessLevel && <ManagerSalary />}
        {(currentUser?.access_level === access_level_e ||
          currentUser?.access_level === access_level_f ||
          currentUser?.access_level === access_level_c ||
          currentUser?.access_level === access_level_h) && <UserSalary />}
      </Main>
      <Outlet />
    </div>
  );
};