import { UserContext } from "../../Context/UserContext";
import React, { useContext, useState, useRef, useEffect } from "react";
import { scriptText } from "../../../services/componentText";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  SaveButton,
  ButtonContainer,
  DeleteButton,
  Input,
  AddedNewButton,
  NavContainer,
  SelectTemplate,
  Option,
} from "./ScriptTemplateItem.styled";
import { MyEditor } from "../../MyEditor/MyEditor";
import { toast } from "react-toastify";
import { Main } from "../../Main/Main";
import {
  createScriptTemplate,
  updateScriptTemplate,
  fetchScriptTemplate,
  deleteScriptTemplate,
  fetchScriptCategory,
} from "../../../api/script";
import { useQuery } from "react-query";
import { fetchProductTitle } from "../../../api/product";
import Select from "react-select";

export const ScriptTemplateItem = () => {
  const { language } = useContext(UserContext);
  const [template, setTemplate] = useState({
    id: "",
    template_title_ua: "",
    template_title_ru: "",
    template_describe_ru: "",
    template_describe_ua: "",
    category_id: "",
    crossail_variation: [],
    before_crossail_variation_ru: "",
    after_crossail_variation_ru: "",
    before_crossail_variation_ua: "",
    after_crossail_variation_ua: "",
  });

  const { data: scriptCategoryData } = useQuery(
    "scriptCategoryData",
    fetchScriptCategory
  );

  const { data: productData } = useQuery("productData", fetchProductTitle);

  const [isPageRussian, setIsPageRussian] = useState(
    language === "russian" ? true : false
  );
  const location = useLocation();
  const navigate = useNavigate();

  const description_ru = useRef("");
  const description_ua = useRef("");
  const before_crossail_variation_ru = useRef("");
  const after_crossail_variation_ru = useRef("");
  const before_crossail_variation_ua = useRef("");
  const after_crossail_variation_ua = useRef("");
  const isCreateNew = location?.pathname === "/script-control-panel/new";

  useEffect(() => {
    const fetchScriptTemplateData = async () => {
      if (isCreateNew) {
        return;
      }

      const idFromPath = location?.pathname?.replace(
        /^\/script-control-panel\/change\//,
        ""
      );

      const response = await fetchScriptTemplate({ id: idFromPath });

      if (response) {
        const fetchedTemplate = response?.data;

        const crossailVariation = JSON?.parse(
          fetchedTemplate[0]?.crossail_variation || "[]"
        );

        setTemplate({
          ...fetchedTemplate[0],
          crossail_variation: crossailVariation,
        });
        description_ru.current = fetchedTemplate[0]?.template_describe_ru || "";
        description_ua.current = fetchedTemplate[0]?.template_describe_ua || "";
        before_crossail_variation_ru.current =
          fetchedTemplate[0]?.before_crossail_variation_ru || "";
        before_crossail_variation_ua.current =
          fetchedTemplate[0]?.before_crossail_variation_ua || "";
        after_crossail_variation_ru.current =
          fetchedTemplate[0]?.after_crossail_variation_ru || "";
        after_crossail_variation_ua.current =
          fetchedTemplate[0]?.after_crossail_variation_ua || "";
      }
    };

    fetchScriptTemplateData();
  }, [location, isCreateNew]);

  const maxLength = 250;

  const handlePreSubmit = () => {
    const newProduct = {
      id: template?.id || "",
      template_title_ua: template?.template_title_ua || "",
      template_title_ru: template?.template_title_ru || "",
      template_describe_ua: description_ua.current || "",
      template_describe_ru: description_ru.current || "",
      category_id: template?.category_id || "",
      crossail_variation: template?.crossail_variation || [],
      before_crossail_variation_ru: before_crossail_variation_ru?.current || "", // Змінено
      after_crossail_variation_ru: after_crossail_variation_ru?.current || "", // Змінено
      before_crossail_variation_ua: before_crossail_variation_ua?.current || "", // Додано
      after_crossail_variation_ua: after_crossail_variation_ua?.current || "", // Додано
    };

    handleSubmit(newProduct);
  };

  const handleSubmit = async (formData) => {
    if (isCreateNew) {
      const response = await createScriptTemplate(formData);

      if (response?.status === 201) {
        toast.success(scriptText[language][0]?.successSave);
        return navigate("/script-control-panel/list");
      }
    }

    if (!isCreateNew) {
      const response = await updateScriptTemplate(formData);

      if (response?.status === 201) {
        toast.success(scriptText[language][0]?.successUpdate);
        return navigate(`/script-control-panel/list`);
      }
    }
  };

  const handleCancelClick = () => {
    return navigate("/script-control-panel/list");
  };

  const handleDelete = async (id) => {
    const response = await deleteScriptTemplate(id);

    if (response?.status === 200) {
      toast.success(scriptText[language][0]?.successRemoved);
      return navigate("/script-control-panel/list");
    }
  };

  const renderSelect = ({ index }) => {
    const key = `select-${index}`;

    const handleChange = (selectedOption) => {
      handleArrayInputChange(
        "crossail_variation",
        index,
        "id",
        selectedOption.value
      );
    };

    const getProductTitle = (productId) => {
      const selectedProduct = productData?.find(
        (product) => Number(product.id) === Number(productId)
      );
      return selectedProduct ? selectedProduct.title_ua : "";
    };

    return (
      <div key={key}>
        <Select
          value={
            template.crossail_variation[index]?.id
              ? {
                  label: getProductTitle(template.crossail_variation[index].id),
                  value: template.crossail_variation[index].id,
                }
              : null
          }
          onChange={handleChange}
          styles={{
            control: (provided) => ({ ...provided, width: "80%" }),
            menu: (provided) => ({ ...provided, zIndex: "3", width: "80%" }),
          }}
          options={[
            {
              label:
                language === "russian"
                  ? "Выберите категорию:"
                  : "Обрати категорію:",
              value: "0",
            },
            ...(productData || []).map((title) => ({
              label: title.title_ua,
              value: title.id,
            })),
          ]}
        />
      </div>
    );
  };

  const numberOfElements = 5;

  const handleArrayInputChange = (arrayName, index, field, value) => {
    const updatedArray = [...template[arrayName]];

    if (!updatedArray[index]) {
      updatedArray[index] = {};
    }

    updatedArray[index][field] = value;

    setTemplate({
      ...template,
      [arrayName]: updatedArray,
    });
  };

  return (
    <Main>
      <NavContainer>
        <AddedNewButton
          onClick={() => setIsPageRussian(false)}
          active={!isPageRussian}
          disabled={!isPageRussian}
        >
          {scriptText[language][0]?.ukraineVersion}
        </AddedNewButton>
        <AddedNewButton
          onClick={() => setIsPageRussian(true)}
          active={isPageRussian}
          disabled={isPageRussian}
        >
          {scriptText[language][0]?.russianVersion}
        </AddedNewButton>
      </NavContainer>
      {isPageRussian ? (
        <div>
          <SelectTemplate
            value={template.category_id}
            onChange={(e) => {
              const updatedTemplate = {
                ...template,
                category_id: e.target.value,
              };
              setTemplate(updatedTemplate);
            }}
          >
            <Option key="0" value={"0"}>
              {language === "russian"
                ? "Выберите категорию:"
                : "Обрати категорію:"}
            </Option>
            {scriptCategoryData &&
              scriptCategoryData?.data?.map((title) => (
                <Option key={title.id} value={title.id}>
                  {title.title_ru}
                </Option>
              ))}
          </SelectTemplate>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              style={{ width: "70%", marginRight: "10px" }}
              value={template?.template_title_ru}
              onChange={(e) => {
                const updatedTemplate = {
                  ...template,
                  template_title_ru: e.target.value,
                };
                if (updatedTemplate?.template_title_ru?.length <= maxLength) {
                  setTemplate(updatedTemplate);
                }
              }}
              placeholder={scriptText[language][0]?.enterProductTitleRU}
              maxLength={maxLength}
              required
            />
            <p style={{ margin: "0" }}>
              {scriptText[language][0]?.leftSymbols}{" "}
              {maxLength - template?.template_title_ru?.length
                ? maxLength - template?.template_title_ru?.length
                : 250}
              /{maxLength}
            </p>
          </div>
        </div>
      ) : (
        <div>
          <SelectTemplate
            value={template.category_id}
            onChange={(e) => {
              const updatedTemplate = {
                ...template,
                category_id: e.target.value,
              };
              setTemplate(updatedTemplate);
            }}
          >
            <Option key="0" value={"0"}>
              {language === "russian"
                ? "Выберите категорию:"
                : "Обрати категорію:"}
            </Option>
            {scriptCategoryData &&
              scriptCategoryData?.data?.map((title) => (
                <Option key={title.id} value={title.id}>
                  {title.title_ua}
                </Option>
              ))}
          </SelectTemplate>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              style={{ width: "70%", marginRight: "10px" }}
              value={template?.template_title_ua}
              onChange={(e) => {
                const updatedTemplate = {
                  ...template,
                  template_title_ua: e.target.value,
                };
                if (updatedTemplate?.template_title_ua?.length <= maxLength) {
                  setTemplate(updatedTemplate);
                }
              }}
              placeholder={scriptText[language][0]?.enterProductTitleUA}
              maxLength={maxLength}
              required
            />
            <p style={{ margin: "0" }}>
              {scriptText[language][0]?.leftSymbols}{" "}
              {maxLength - template?.template_title_ua?.length}/{maxLength}
            </p>
          </div>
        </div>
      )}

      {(Number(template.category_id) > 0 && (Number(template.category_id) === 5 ||
        Number(template.category_id) === 9 ||
        Number(template.category_id) === 8)) ? (
        <>
          {isPageRussian ? (
            <div
              style={{
                width: "100%",
                minHeight: "300px",
                marginBottom: "10px",
              }}
            >
              <MyEditor
                value={before_crossail_variation_ru?.current || ""}
                onChange={(newContent) =>
                  (before_crossail_variation_ru.current = newContent)
                }
                placeholder={scriptText[language][0]?.beforeCrossailVariation}
              />
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                minHeight: "300px",
                marginBottom: "10px",
              }}
            >
              <MyEditor
                value={before_crossail_variation_ua?.current || ""}
                onChange={(newContent) =>
                  (before_crossail_variation_ua.current = newContent)
                }
                placeholder={scriptText[language][0]?.beforeCrossailVariation}
              />
            </div>
          )}

          {[...Array(numberOfElements)].map(
            (_, index) =>
              (Number(template.category_id) === 5 ||
                Number(template.category_id) === 9) &&
              renderSelect({ index })
          )}

          {isPageRussian ? (
            <div
              style={{ width: "100%", minHeight: "300px", marginTop: "10px" }}
            >
              <MyEditor
                value={after_crossail_variation_ru?.current || ""}
                onChange={(newContent) =>
                  (after_crossail_variation_ru.current = newContent)
                }
                placeholder={scriptText[language][0]?.afterCrossailVariation}
              />
            </div>
          ) : (
            <div
              style={{ width: "100%", minHeight: "300px", marginTop: "10px" }}
            >
              <MyEditor
                value={after_crossail_variation_ua?.current || ""}
                onChange={(newContent) =>
                  (after_crossail_variation_ua.current = newContent)
                }
                placeholder={scriptText[language][0]?.afterCrossailVariation}
              />
            </div>
          )}
        </>
      ) : <>
      {isPageRussian ? (
        <div
          style={{
            width: "100%",
            minHeight: "300px",
            marginBottom: "10px",
          }}
        >
          <MyEditor
            value={description_ru?.current || ""}
            onChange={(newContent) => (description_ru.current = newContent)}
            placeholder={scriptText[language][0]?.beforeCrossailVariation}
          />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            minHeight: "300px",
            marginBottom: "10px",
          }}
        >
          <MyEditor
            value={description_ua?.current || ""}
            onChange={(newContent) => (description_ua.current = newContent)}
            placeholder={scriptText[language][0]?.beforeCrossailVariation}
          />
        </div>
      )}
    </>}

      {isCreateNew && (
        <ButtonContainer>
          <SaveButton onClick={() => handlePreSubmit()}>
            {scriptText[language][0]?.save}
          </SaveButton>
          <DeleteButton onClick={() => handleCancelClick()}>
            {scriptText[language][0]?.cancel}
          </DeleteButton>
        </ButtonContainer>
      )}

      {!isCreateNew && (
        <ButtonContainer>
          <SaveButton onClick={() => handlePreSubmit()}>
            {scriptText[language][0]?.update}
          </SaveButton>
          <DeleteButton
            onClick={() => {
              handleDelete(template.id);
            }}
          >
            {scriptText[language][0]?.delete}
          </DeleteButton>
        </ButtonContainer>
      )}
    </Main>
  );
};
