export const navbarText = {
  russian: [
    {
      organizational: 'ОРГАНИЗАЦИОННЫЕ ВОПРОСЫ',
      schedule: 'График работы',
      break: 'Перерывы',
      users: 'Пользователи',
      usersOnline: 'Пользователи онлайн',
      myprofile: 'Мой профиль',
      salary: 'Кошелек',
      fines: 'Штрафы',
      finesList: 'Перечень штрафов',
      news: 'Новости',
      notices: 'Объявления',
      workwithproducts: 'Работа с товарами',
      products: 'Товары',
      scripts: 'Скрипты',
      scriptsTemplate: 'Шаблоны для скриптов',
      productsAvailability: 'Наличие товара',
      knowledgebase: 'База знаний',
      education: 'Обучение',
      wiretapping: 'Прослушка',
      checklist: 'Чек листы',
      statistics: 'Статистики',
      navbarText: 'Нашли ошибку - исправим!',
      everydayStatistics: 'Ежедневная статистика',
      report: 'Отчеты',
      callReport: 'Отчёт по Voip Time',
      allDepartmentReport: 'Отчёт по всем отделам',
      employeeReport: 'Отчет по работе операторов',
      employeeEfficiencyReport: 'Отчет по эффективности операторов',
      orderReport: 'Отчет по заказам',
      statistic: 'Статистика',
      claims: 'Отдел претензий',
      claimsControlPanel: 'Панель управления',
      claimsList: 'Список претензий',
      rating: 'Рейтинг по ежедневной статистике',
      ratingByPeriod: 'Рейтинг за период',
    },
  ],
  ukraine: [
    {
      organizational: 'Організаційні питання',
      schedule: 'Графік роботи',
      break: 'Перерви',
      users: 'Користувачі',
      usersOnline: 'Користувачі онлайн',
      myprofile: 'Мій профіль',
      salary: 'Гаманець',
      fines: 'Штрафи',
      finesList: 'Перелік штрафів',
      news: 'Новини',
      notices: 'Оголошення',
      workwithproducts: 'Робота з товарами',
      products: 'Товари',
      scripts: 'Скріпти',
      scriptsTemplate: 'Шаблони для скриптів',
      productsAvailability: 'Наявність товару',
      knowledgebase: 'База знань',
      education: 'Навчання',
      wiretapping: 'Прослуховування',
      checklist: 'Чек листи',
      statistics: 'Статистики',
      navbarText: 'Знайшли помилку - виправимо!',
      everydayStatistics: 'Щоденна статистика',
      report: 'Звіти',
      callReport: 'Звіт по Voip Time',
      allDepartmentReport: 'Звіт з усіх відділів',
      employeeReport: 'Звіт по роботі операторів',
      employeeEfficiencyReport: 'Звіт по ефективності операторів',
      orderReport: 'Звіт по замовленнях',
      statistic: 'Статистика',
      claims: 'Відділ претензій',
      claimsControlPanel: 'Панель управління',
      claimsList: 'Список претензій',
      rating: 'Рейтинг за щоденною статистикою',
      ratingByPeriod: 'Рейтинг за період'
    },
  ],
};

export const userText = {
  russian: [
    {
      email: 'Электронная почта *',
      fullName: 'ФИО *',
      password: 'Пароль *',
      country: 'Страна',
      city: 'Город',
      address: 'Адрес',
      phoneNumber: 'Номер телефона',
      telegram: 'Телеграм *',
      skype: 'Скайп',
      card: 'Номер карточки',
      birthday: 'День рождения',
      chooseLanguage: 'Выберите язык',
      chooseCountry: 'Выберите страну',
      chooseJobTitle: 'Выберите должность *',
      userList: 'Список пользователей',
      createNewUser: 'Добавить нового пользователя',
      createUser: 'Создать пользователя',
      currentUser: 'Мой профиль',
      successUserCreate: 'Пользователь успешно создан',
      chooseGroupe: 'Выберите группу *',
      language: 'Язык',
      status: 'Статус',
      groupe: 'Группа',
      userListJobTitle: 'Должность',
      userListTelegram: 'Телеграм',
      userListEmail: 'Электронная почта',
      userListFullName: 'ФИО',
      saveEdit: 'Сохранить изменения',
      search: 'Введите ваш запрос и нажмите на лупу',
      usersListError: 'Ошибка при загрузке пользователей',
      usersListNotFound:
        'По вашему запросу ничего не найдено, пожалуйста попробуйте ещё раз',
      unblock: 'Разблокировать',
      block: 'Заблокировать',
      updateUserSuccess: 'Данные пользователя изменены успешно',
      updateUserError: 'При изменении данных пользователя возникла ошибка',
      notFound: 'Извините, такую страницу не найдено',
      chooseEmployeeStatus: 'Выберите статус операторов:',
      notBlocked: 'Не заблокированы',
      blocked: 'Заблокированы',
      botIntegration: 'Интеграция с ботом',
    },
  ],
  ukraine: [
    {
      email: 'Електронна пошта *',
      fullName: 'ПІБ *',
      password: 'Пароль *',
      userListEmail: 'Електронна пошта',
      userListFullName: 'ПІБ',
      country: 'Країна',
      city: 'Місто',
      address: 'Адреса проживання',
      phoneNumber: 'Номер телефону',
      telegram: 'Телеграм *',
      userListTelegram: 'Телеграм',
      skype: 'Скайп',
      card: 'Номер картки',
      birthday: 'День народження',
      chooseLanguage: 'Оберіть мову',
      chooseCountry: 'Оберіть країну',
      chooseJobTitle: 'Оберіть посаду *',
      userListJobTitle: 'Посада',
      userList: 'Список користувачів',
      createNewUser: 'Створити нового користувача',
      createUser: 'Створити користувача',
      currentUser: 'Мій профіль',
      successUserCreate: 'Користувач успішно створений',
      chooseGroupe: 'Оберіть групу *',
      language: 'Мова',
      status: 'Статус',
      groupe: 'Група',
      saveEdit: 'Зберегти зміни',
      search: 'Введіть ваш запит та натисніть на лупу',
      usersListError: 'Помилка під час завантаження користувачів',
      usersListNotFound:
        'За вашим запитом нічого не знайдено, будь ласка спробуйте ще раз',
      unblock: 'Розблокувати',
      block: 'Заблокувати',
      updateUserSuccess: 'Дані користувача успішно змінені',
      updateUserError: 'При зміні даних користувача виникла помилка',
      notFound: 'Вибачте, таку сторінку не знайдено',
      chooseEmployeeStatus: 'Оберіть статус операторів:',
      notBlocked: 'Не заблоковані',
      blocked: 'Заблоковані',
      botIntegration: 'Інтеграція з ботом',
    },
  ],
};

export const weekDays = {
  russian: [
    {
      monday: 'Понедельник',
      tuesday: 'Вторник',
      wednesday: 'Среда',
      thursday: 'Четверг',
      friday: 'Пятница',
      saturday: 'Суббота',
      sunday: 'Воскресенье',
    },
  ],
  ukraine: [
    {
      monday: 'Понеділок',
      tuesday: 'Вівторок',
      wednesday: 'Середа',
      thursday: 'Четвер',
      friday: 'Пятница',
      saturday: 'Субота',
      sunday: 'Неділя',
    },
  ],
};

export const scheduleText = {
  russian: [
    {
      successDelete: 'График успешно удалён',
      errorDelete:
        'При удалении графика что то пошло не так, пожалуйста попробуйте ещё раз',
      successSlot: 'Слот успешно обновлен',
      errorSlot: 'Ошибка при обновлении слота:',
      errorStatusOne: 'При статусе 1 произошла ошибка. Попробуйте позже.',
      errorResponse: 'Ошибка при запросе:',
      successSchedule: 'График успешно сохранен',
      errorSchedule: 'Ошибка при сохранении данных на сервере:',
      warningEndSlot: 'Время окончания не может быть раньше времени начала.',
      warningStartSlot: 'Время начала не может быть позже времени окончания.',
      successResponseDataBase: 'Данные успешно отправлены на сервер.',
      errorResponseDataBase: 'Ошибка при отправке данных на сервер.',
      categoryIdMissing: 'Идентификатор категории отсутствует для дня.',
      prevMonth: 'Прошлый месяц',
      nextMonth: 'Следующий месяц',
      dateForCopy: 'Дата для копирования: ',
      dateStartPeriod: 'Дата начала периода:',
      dateEndPeriod: 'Дата окончания периода:',
      chooseDate: 'Выберите дату',
      changeSchedule: 'Применить расписание',
      chooseCategory: 'Выберите категорию',
      addSlot: 'Добавить поле',
      deleteSchedule: 'Удалить график за выбранный день',
      save: 'Сохранить',
      scheduleForMonth: 'График на месяц:',
      makeChanges: 'Внести изменения',
      cancel: 'Отменить',
      mySchedule: 'Мой график',
      employeesSchedule: 'Общий график',
      acceptEmployeeScheule: 'Поданные графики',
      workingHour: 'Количество рабочих часов',
      scheduleOnHour: 'Почасовый график',
      notFound: 'По вашему запросу ничего не найдено',
      fullName: 'ФИО',
      groupe: 'Группа',
      notFoundSchedule: 'Графиков операторов на рассмотрении нет',
      responseError: 'Ошибка при запросе',
      allOnTheDay: 'Всего часов в день:',
      actions: 'Действия',
      hoursOnMonth: 'Рабочие часы в месяц',
      hoursOnWeek: 'Рабочие часы в неделю',
      workSchedule: 'Рабочий график',
      dutySchedule: 'График дежурств',
      hoursStatistic: 'Статистика по часам',
    },
  ],
  ukraine: [
    {
      successDelete: 'Графік успішно видалено',
      errorDelete:
        'При видаленні графіка щось пішло не так, будь ласка спробуйте ще раз',
      successSlot: 'Слот успішно оновлено',
      errorSlot: 'Помилка при оновленні слота:',
      errorStatusOne: 'При статусі 1 сталася помилка. Спробуй пізніше.',
      errorResponse: 'Помилка при запиті:',
      successSchedule: 'Графік успішно збережено',
      errorSchedule: 'Помилка збереження даних на сервері:',
      warningEndSlot: 'Час закінчення зміни не може бути раніше початку.',
      warningStartSlot:
        'Час початку зміни не може бути пізніше часу закінчення.',
      successResponseDataBase: 'Дані успішно надіслані на сервер.',
      errorResponseDataBase: 'Помилка при надсиланні даних на сервер.',
      categoryIdMissing: 'Ідентифікатор категорії відсутній на день.',
      prevMonth: 'Минулий місяць',
      nextMonth: 'Наступний місяць',
      dateForCopy: 'Дата копіювання:',
      dateStartPeriod: 'Дата початку періоду:',
      dateEndPeriod: 'Дата закінчення періоду:',
      chooseDate: 'Оберіть дату',
      changeSchedule: 'Застосувати розклад',
      chooseCategory: 'Оберіть категорію',
      addSlot: 'Додати поле',
      deleteSchedule: 'Видалити графік за вибраний день',
      save: 'Зберегти',
      scheduleForMonth: 'Графік на місяць:',
      makeChanges: 'Внести зміни',
      cancel: 'Скасувати',
      mySchedule: 'Мій графік',
      employeesSchedule: 'Загальний графік',
      acceptEmployeeScheule: 'Подані графіки',
      workingHour: 'Кількість робочих годин',
      scheduleOnHour: 'Погодинний графік',
      notFound: 'За вашим запитом нічого не знайдено',
      fullName: 'ПІБ',
      groupe: 'Група',
      notFoundSchedule: 'Графіків операторів на розгляді немає',
      responseError: 'Помилка при запиті',
      allOnTheDay: 'Всього годин за день:',
      actions: 'Дії',
      hoursOnMonth: 'Години за місяць',
      hoursOnWeek: 'Робочі години за тиждень',
      workSchedule: 'Робочий графік',
      dutySchedule: 'Графік чергувань',
      hoursStatistic: 'Статистика по годиннах',
    },
  ],
};

export const breakText = {
  russian: [
    {
      timeStartBreak: 'Время начала перерыва:',
      endStartBreak: 'Время завершения перерыва:',
      addBreak: 'Добавить перерыв',
      prevDay: 'Предыдущий день',
      nextDay: 'Следующий день',
      startTime: 'Время начала',
      endTime: 'Время завершения',
      durationTime: 'Продолжительность перерыва',
      actualStartTime: 'Фактическое время начала',
      actualEndTime: 'Фактическое время завершения',
      actualDurationTime: 'Фактическая продолжительность перерыва',
      delete: 'Удалить',
      errorDelete: 'Ошибка удаления перерыва!',
      errorCreate: 'Ошибка при создании перерыва!',
    },
  ],
  ukraine: [
    {
      timeStartBreak: 'Час початку перериву:',
      endStartBreak: 'Час завершення перериву:',
      addBreak: 'Додати перерив',
      prevDay: 'Попередній день',
      nextDay: 'Наступний день',
      startTime: 'Час початку',
      endTime: 'Час завершення',
      durationTime: 'Тривалість перерви',
      actualStartTime: 'Фактичний час початку',
      actualEndTime: 'Фактичний час завершення',
      actualDurationTime: 'Фактична тривалість перерви',
      delete: 'Видалити',
      errorDelete: 'Помилка при видаленні перерви!',
      errorCreate: 'Помилка при створенні перерви!',
    },
  ],
};

export const fmText = {
  russian: [
    {
      date: 'Дата',
      reasonFm: 'Причина ФМ',
      successAdded: 'Запись ФМ успешно создана!',
      successUpdated: 'Запись ФМ успешно обновлена!',
      successDeleted: 'Запись ФМ успешно удалена!',
      chooseEmployeeOne: 'Выберите оператора:',
      chooseEmployeeTwo: 'Выберите оператора',
      chooseDate: 'Выберите дату:',
      reasonDescribeFm: 'Опишите причину фм:',
      leftSymbols: 'Осталось символов:',
      fullName: 'ФИО',
      save: 'Создать фм',
      update: 'Внести изменения',
      delete: 'Удалить',
      addNewFm: 'Добавить новый фм',
      choosePeriod: 'Выбрать новый период',
      statistics: 'Статистика',
      toFmList: 'Перейти в список фм',
      fmCountText: 'Количество фм',
    },
  ],
  ukraine: [
    {
      date: 'Дата',
      reasonFm: 'Причина ФМ',
      successAdded: 'Запис ФМ успішно створено!',
      successUpdated: 'Запис ФМ успішно оновлено!',
      successDeleted: 'Запис ФМ успішно видалено!',
      chooseEmployeeOne: 'Оберіть оператора:',
      chooseEmployeeTwo: 'Оберіть оператора',
      chooseDate: 'Оберіть дату:',
      reasonDescribeFm: 'Опишіть причину фм:',
      leftSymbols: 'Залишилося символів:',
      save: 'Створити фм',
      update: 'Внести зміни',
      delete: 'Видалити',
      fullName: 'ПІБ',
      addNewFm: 'Додати новий фм',
      choosePeriod: 'Обрати новий період',
      statistics: 'Статистика',
      toFmList: 'Перейти до списку фм',
      fmCountText: 'Кількість фм',
    },
  ],
};

export const salaryText = {
  russian: [
    {
      date: 'Дата',
      hoursInVoip: 'Часы в voip',
      bid: 'Ставка',
      oneExtraSalary: 'Оплата одного допа',
      totalSalaryForExtra: 'Общая зарплата за допы',
      prize: 'Премия',
      fines: 'Штрафы',
      paymentForGoods: 'Оплата товара',
      toPayOff: 'К выплате',
      notDataOrError:
        'К сожалению У вас пока нет данных по выплатам или возникла ошибка',
      addNewTable: 'Добавить новую таблицу с выплатами',
      upload: 'Загрузить',
      cancel: 'Отменить',
      fullName: 'ФИО',
      successDelete: 'Данные успешно удалены',
      successUpload: 'Файл успешно загружен',
      chooseDate: 'Выберите дату',
      chooseThisDate: 'Выбрать этот период',
      chooseRemoveThisDate: 'Удалить данные за этот период',
      finesComment: 'Причина штрафа',
      update: 'Внести изменения',
      decline: 'Отменить',
      leftSymbols: 'Осталось символов:',
      successUpdate: "Данные успешно изменены"
    },
  ],
  ukraine: [
    {
      date: 'Дата',
      hoursInVoip: 'Годинни в voip',
      bid: 'Ставка',
      oneExtraSalary: 'Оплата одного допа',
      totalSalaryForExtra: 'Загальна зарплата за допи',
      prize: 'Премія',
      fines: 'Штрафи',
      paymentForGoods: 'Оплата товару',
      toPayOff: 'До виплати',
      notDataOrError:
        'На Жаль у вас поки немає даних по виплатам або виникла помилка',
      addNewTable: 'Додати нову таблицю з виплатами',
      upload: 'Завантажити',
      cancel: 'Скасувати',
      fullName: 'ПІБ',
      successDelete: 'Дані успішно видалено',
      successUpload: 'Файл успішно завантажено',
      chooseDate: 'Оберіть дату',
      chooseThisDate: 'Обрати цей період',
      chooseRemoveThisDate: 'Видалити дані за цей період',
      finesComment: 'Причина штрафу',
      update: 'Внести зміни',
      decline: 'Скасувати',
      leftSymbols: 'Залишилося символів:',
      successUpdate: "Дані успішно змінено"
    },
  ],
};

export const finesText = {
  russian: [
    {
      date: 'Дата',
      reasonFines: 'Причина штрафа',
      successAdded: 'Запись штрафа успешно создана!',
      successUpdated: 'Запись штрафа успешно обновлена!',
      successDeleted: 'Запись штрафа успешно удалена!',
      chooseEmployeeOne: 'Выберите оператора:',
      chooseEmployeeTwo: 'Выберите оператора',
      chooseDate: 'Выберите дату:',
      reasonDescribeFines: 'Опишите причину штрафа:',
      leftSymbols: 'Осталось символов:',
      fullName: 'ФИО',
      save: 'Создать штраф',
      update: 'Внести изменения',
      delete: 'Удалить',
      addNewFm: 'Добавить новый штраф',
      finesPrice: 'Сумма штрафа',
      additionalInformation: 'Дополнительная информация',
      screenshot: 'Скриншот',
      recordTalking: 'Запись разговора',
      choosePeriod: 'Выбрать новый период',
      createNewFines: 'Создать новый штраф',
      enterFinesPrice: 'Введите сумму штрафа',
      addScreenshot: 'Добавить скриншот (необязательно)',
      totalPriceForPeriod: 'Сумма штрафов за период',
      oneFinesPrice: 'Сумма штрафа',
      notDataOrError:
      'К сожалению У вас пока нет данных за выбраными критериями или возникла ошибка',
    },
  ],
  ukraine: [
    {
      date: 'Дата',
      reasonFines: 'Причина штрафа',
      successAdded: 'Запис штрафа успішно створено!',
      successUpdated: 'Запис штрафа успішно оновлено!',
      successDeleted: 'Запис штрафа успішно видалено!',
      chooseEmployeeOne: 'Оберіть оператора:',
      chooseEmployeeTwo: 'Оберіть оператора',
      chooseDate: 'Оберіть дату:',
      reasonDescribeFines: 'Опишіть причину штрафа:',
      leftSymbols: 'Залишилося символів:',
      save: 'Створити штраф',
      update: 'Внести зміни',
      delete: 'Видалити',
      fullName: 'ПІБ',
      addNewFm: 'Додати новий штраф',
      finesPrice: 'Сума штрафa',
      additionalInformation: 'Додаткова інформація',
      screenshot: 'Скріншот',
      recordTalking: 'Запис розмови',
      choosePeriod: 'Обрати новий період',
      createNewFines: 'Створити новий штраф',
      enterFinesPrice: 'Введіть суму штрафа',
      addScreenshot: 'Додати скріншот (необовязково)',
      totalPriceForPeriod: 'Сума штрафів за період',
      oneFinesPrice: 'Сума штрафа',
      notDataOrError:
      'На жаль У вас поки немає даних за обраними критеріями або виникла помилка',
    },
  ],
};

export const dutyText = {
  russian: [
    {
      chooseEmployeeOne: 'Выберите оператора:',
      chooseEmployeeTwo: 'Выберите оператора',
      successCreateGroupe: 'Группа успешно создана.',
      successAddedEmployeeToGroupe:'Оператор успешно добавлен в группу дежурств.',
      successEditGroupeTitle: 'Название группы успешно изменено',
      successRemovedGroupe: 'Группа успешно удалена',
      editDutGroupe: 'Редактировать группы дежурств',
      addGroupe: 'Добавить группу',
      addEmployee: 'Добавить оператора',
      groupeTitleUA: 'Введите название группы на Украинском:',
      groupeTitleRU: 'Введите название группы на Русском:',
      save: 'Сохранить',
      addToGroupe: 'Добавить в группу',
      saveChanges: 'Сохранить изменения',
      remove: 'Удалить',
    },
  ],
  ukraine: [
    {
      chooseEmployeeOne: 'Оберіть оператора:',
      chooseEmployeeTwo: 'Оберіть оператора',
      successCreateGroupe: 'Групу успішно створено.',
      successAddedEmployeeToGroupe:'Оператора успішно додано до групи чергувань.',
      successEditGroupeTitle: 'Назву групи успішно змінено',
      successRemovedGroupe: 'Групу успішно видалено',
      editDutGroupe: 'Редагувати групи чергувань',
      addGroupe: 'Додати групу',
      addEmployee: 'Додати оператора',
      groupeTitleUA: 'Введіть назву групи на Українській:',
      groupeTitleRU: 'Введіть назву групи на Російській:',
      save: 'Зберегти',
      addToGroupe: 'Додати до групи',
      saveChanges: 'Зберігти зміни',
      remove: 'Видалити',
    },
  ],
};

export const productAvailabilityText = {
  russian: [
    {
      informationSuccessSave: 'Информация о товаре сохранена успешно',
      informationSuccessUpdate: 'Информация о товаре обновлена ​​успешно',
      informationSuccessRemoved: 'Товар успешно удалён',
      variationSuccessRemoved: 'Вариация успешно удалена',
      enterProductTitle: 'Введите название товара: ',
      leftSymbols: 'Осталось символов: ',
      enterColorOrSize: 'Введите цвет товара или размер: ',
      colorOrSize: 'Цвет или размер',
      chooseAvailability: 'Выберите наличие товара: ',
      chooseDateWhenProductWillBeAvailable: `Выберите дату, когда появится товар`,
      delete: 'Удалить',
      addColor: 'Добавить цвет',
      addInformation: 'Дополнительная информация о наличии',
      save: 'Сохранить',
      deleteProduct: 'Удалить полностью',
      productTitle: 'Название товара',
      informationAboutVariation: 'Информация о вариации',
      informationAdditional: 'Дополнительная информация',
      colorSize: 'Цвет/размер:',
      availability: 'Наличие:',
      dateWhenBeAvailable: `Дата когда появится:`,
      successUpdate: "Данные успешно изменены",
      controlPanel: 'Панель управления'
    },
  ],
  ukraine: [
    {
      informationSuccessSave: 'Інформацію про товар збережено успішно',
      informationSuccessUpdate: 'Інформацію про товар збережено успішно',
      informationSuccessRemoved: 'Товар успішно видалено',
      variationSuccessRemoved: 'Варіацію успішно видалено',
      enterProductTitle: 'Введіть назву товару: ',
      leftSymbols: 'Залишилось символів: ',
      enterColorOrSize: 'Введіть колір товару або розмір: ',
      colorOrSize: 'Колір або розмір',
      chooseAvailability: 'Оберіть наявність товару: ',
      chooseDateWhenProductWillBeAvailable: `Оберіть дату коли з'явиться товар`,
      delete: 'Видалити',
      addColor: 'Додати колір',
      addInformation: 'Додаткова інформація про наявність',
      save: 'Зберегти',
      deleteProduct: 'Видалити повністю',
      productTitle: 'Назва товару',
      informationAboutVariation: 'Інформація про варіацію',
      informationAdditional: 'Додаткова інформація',
      colorSize: 'Колір/розмір:',
      availability: 'Наявність:',
      dateWhenBeAvailable: `Дата коли з'явиться:`,
      successUpdate: "Дані успішно змінено",
      controlPanel: 'Панeль управліня'
    },
  ],
};

export const failsText = {
  russian: [
    {
      failSuccessSave: 'Ошибка успешно создана',
      youCantEditFail:
        'Вы не можете редактировать записи ошибок, которые не находятся "На рассмотрении"',
      failSuccessUpdate: 'Ошибка успешно обновлена',
      failSuccessRemoved: 'Ошибка успешно удалена',
      chooseCategoryFail: 'Выберите категорию ошибки: ',
      enterOrderIdInFormat: 'Введите ID заказа в формате 111111',
      enterOrderId: 'Введите ID заказа:',
      enterProductTitle: 'Введите название товара:',
      leftSymbols: 'Осталось символов: ',
      employeeComment: 'Напишите с чем возникла ошибка:',
      createFail: 'Создать ошибку',
      saveChanges: 'Сохранить изменения',
      deleteFail: 'Удалить ошибку',
      createNewFail: 'Создать новую ошибку',
      orderID: 'ID заказа ',
      productTitle: 'Название оффера',
      commentEmployee: 'Комментарий к ошибке',
      commentSupervisor: 'Комментарий руководства',
      category: 'Категория',
      changeStatus: 'Для изменения статуса выберите новый:',
      leftYourComment: 'Оставить свой комментарий:',
      fullName: 'ФИО оператора',
      commentEmployeeInManagerPage: 'Комментарий, написанный оператором :',
      notFound: 'Записей не найдено'
    },
  ],
  ukraine: [
    {
      failSuccessSave: 'Помилку успішно створено',
      youCantEditFail:
        'Ви не можете редагувати записи помилок які не знаходяться "На розгляді"',
      failSuccessUpdate: 'Помилку успішно оновлено',
      failSuccessRemoved: 'Помилку успішно видалено',
      chooseCategoryFail: 'Оберіть категорію помилки: ',
      enterOrderIdInFormat: 'Введіть ID заказу в форматі 111111',
      enterOrderId: 'Введіть ID заказу:',
      enterProductTitle: 'Введіть назву товару:',
      leftSymbols: 'Залишилося символів:',
      employeeComment: 'Напишіть з чим виникла помилка:',
      createFail: 'Створити помилку',
      saveChanges: 'Зберегти зміни',
      deleteFail: 'Видалити помилку',
      createNewFail: 'Створити нову помилку',
      orderID: 'ID замовлення',
      productTitle: 'Назва офферу',
      commentEmployee: 'Коментар до помилки',
      commentSupervisor: 'Коментар керівництва',
      category: 'Категорія',
      changeStatus: 'Щоб змінити статус оберіть новий:',
      leftYourComment: 'Залишити свій коментар:',
      fullName: 'ПІБ оператора',
      commentEmployeeInManagerPage: 'Коментар, написаний оператором: ',
      notFound: 'Записів не знайдено'
    },
  ],
};

export const everydayStatisticsText = {
  russian: [
    {
      date: 'Дата',
      fullName: 'ФИО',
      approve: 'Апрувы',
      inProcessing: 'В обработке',
      rejected: 'Отклоненные',
      trash: 'Треш',
      nettoIncome: 'Ср. Маржа с допов по Апруву',
      approvalPercentage: '% Апрува',
      additionalApprovalPercentage: '% Допа по Апрувам',
      checkByApprov: 'Ср. Чек по Апруву',
      timeInSystem: 'Количество рабочих часов по графику',
      timeInConversation: 'Время в разговоре',
      approvalForTime: 'Среднее время на 1 апрув',
      notDataOrError:
        'К сожалению У вас пока нет данных со статистикой или возникла ошибка',
      addNewTable: 'Добавить новую таблицу',
      upload: 'Загрузить',
      cancel: 'Отменить',
      successDelete: 'Данные успешно удалены',
      successUpload: 'Файл успешно загружен',
      chooseDate: 'Выберите дату',
      chooseThisDate: 'Выбрать этот период',
      chooseRemoveThisDate: 'Удалить данные за этот период',
      myStatistics: 'Моя статистика',
      allEmployeeStatistics: 'Статистика всех операторов',
      seeChanges: 'Просмотреть изменения в статистике',
      totalOrder: 'Всего заказов ',
      chooseEmployeeOne: 'Выберите оператора:',
      chooseEmployeeTwo: 'Выберите оператора',
      update: 'Внести изменения',
      choosePeriod: 'Выбрать новый период',
      totalOrderScore: 'Балы за количество заказов',
      approvalPercentageScore: 'Балы за % Апрува',
      inProcessingScore: 'Балы за "В обработке"',
      rejectedScore: 'Балы за Отклоненные',
      trashScore: 'Балы за Треш',
      nettoIncomeScore: 'Балы за маржу',
      additional_approval_percentage_score: 'Балы % Допа по Апрувам',
      checkByApprovScore: 'Балы % Допа по Апрувам',
      totalScore: 'Всего балов',
      totalHour: 'Всего часов в voip',
      totalHourScore: 'Всего баллов за рабочие часы',
      approveByHour: 'Принято в час',
      approveByHourScore: 'Всего баллов принято в час',
      approveScore: 'Балы за апрув',
      additionalApproval: 'Количество допов по выкупу',
      additionalApprovalScore: 'Балы за допы по выкупу',
      ransomed: 'Количество выкупов',
      ransomedScore: 'Балы за выкупы',
      approvalPercentageWithoutTrash: '% Апрува без треша',
      approvalPercentageWithoutTrashScore: 'Балы за % Апрува без треша',
      additionalApprovalPercentage2: 'Процент допов по выкупу',
      additionalApprovalPercentageScore2: 'Были за процент допов по выкупу',
      ransomedPercentage: '% выкупов',
      ransomedPercentageScore: 'Балы за % выкупов',
      profitByRepurchases: 'Маржа  с допродаж',
    },
  ],
  ukraine: [
    {
      date: 'Дата',
      fullName: 'ПІБ',
      approve: 'Апруви',
      inProcessing: 'В обробці',
      rejected: 'Відхилені',
      trash: 'Треш',
      nettoIncome: 'Сeр. Маржа з ​​допів по Апруву',
      approvalPercentage: '% Апрува',
      additionalApprovalPercentage: '% Допа по Апрувам',
      checkByApprov: 'Сeр. Чек по Апруву',
      timeInSystem: 'Кількість робочих годин за графіком',
      timeInConversation: 'Час у розмові',
      approvalForTime: 'Середній час на 1 апрув',
      notDataOrError:
        'На Жаль у вас поки немає даних зі статистикою або виникла помилка',
      addNewTable: 'Додати нову таблицю',
      upload: 'Завантажити',
      cancel: 'Скасувати',
      successDelete: 'Дані успішно видалено',
      successUpload: 'Файл успішно завантажено',
      chooseDate: 'Оберіть дату',
      chooseThisDate: 'Обрати цей період',
      chooseRemoveThisDate: 'Видалити дані за цей період',
      myStatistics: 'Моя статистика',
      allEmployeeStatistics: 'Статистика всіх операторів',
      seeChanges: 'Переглянути зміни в статистиці',
      totalOrder: 'Всього заказів ',
      chooseEmployeeOne: 'Оберіть оператора:',
      chooseEmployeeTwo: 'Оберіть оператора',
      update: 'Внести зміни',
      choosePeriod: 'Обрати новий період',
      totalOrderScore: 'Бали за кількість замовлень',
      approvalPercentageScore: 'Бали за % Апрува',
      inProcessingScore: 'Бали за "В обробці"',
      rejectedScore: 'Бали за Відхилені',
      trashScore: 'Бали за Треш',
      nettoIncomeScore: 'Бали за маржу',
      totalScore: 'Всього балів',
      totalHour: 'Всього годин у voip',
      totalHourScore: 'Всього балів за робочі години',
      approveByHour: 'Прийнято за годину',
      approveByHourScore: 'Всього балів прийнято за годину',
      approveScore: 'Бали за апрув',
      additionalApproval: 'Кількість допів з викупу',
      additionalApprovalScore: 'Бали за допи з викупу',
      ransomed: 'Кількість викупів',
      ransomedScore: 'Бали за викупи',
      approvalPercentageWithoutTrash: '% Апрува без трешу',
      approvalPercentageWithoutTrashScore: 'Бали за % Апрува без трешу',
      additionalApprovalPercentage2: 'Відсоток допів з викупу',
      additionalApprovalPercentageScore2: 'Бали за відсоток допів з викупу',
      ransomedPercentage: '% викупів',
      ransomedPercentageScore: 'Бали за % викупів',
      profitByRepurchases: 'Маржа  з допродажів',
    },
  ],
};

export const finesListText = {
  russian: [
    {
      successSave: 'Запись успешно создана.',
      successUpdate: 'Запись успешно изменена.',
      successRemoved: 'Запись успешно удалена.',
      enterRuTitle: 'Введите причину штрафа на русском языке:',
      enterUaTitle: 'Введите причину штрафа на украинском языке:',
      leftSymbols: 'Осталось символов:',
      finesAmount: 'Введите сумму штрафа:',
      update: 'Внести изменения',
      delete: 'Удалить запись',
      cancel: 'Отменить',
      save: 'Сохранить',
      createNewFines: 'Создать новый штраф',
      finesReason: 'Причина штрафа',
      fineAmount: 'Сумма штрафа',
    },
  ],
  ukraine: [
    {
      successSave: 'Запис успішно створено.',
      successUpdate: 'Запис успішно змінено.',
      successRemoved: 'Запис успішно видалено.',
      enterRuTitle: 'Введіть причину штрафу на російській мові:',
      enterUaTitle: 'Введіть причину штрафу на українській мові:',
      leftSymbols: 'Залишилося символів:',
      finesAmount: 'Введіть суму штрафу:',
      update: 'Внести зміни',
      delete: 'Видалити запис',
      cancel: 'Скасувати',
      save: 'Зберегти',
      createNewFines: 'Створити новий штраф',
      finesReason: 'Причина штрафа',
      fineAmount: 'Сума штрафа',
    },
  ],
};

export const wiretappingCheckList = {
  russian: [
    {
      enterOrderNumber: 'Введите номер заказа:',
      orderStatus: 'Выберите статус заказа:',
      chooseEmployee: 'Выберите оператора:',
      enterProductTitle: 'Введите название товара:',
      yes: 'Да',
      no: 'Нет',
      notChange: 'Без изменений',
      leftSymbols: 'Осталось символов:',
      comment: 'Опишите ошибки оператора:',
      isGreeting: 'Было озвучено приветствие?',
      isIdentifyingNeeds: 'Были выявлены потребности?',
      isPresentation: 'Была озвучена презентация товара?',
      isOfferOfKits:
        'Были предложены комплекты либо предложение своих комплектов (либо нескольких разных товаров как  комплект)?',
      isOfferingAnImprovedVersion:
        'Было озвучено предложение приобрести улучшенную версию товара отдельно?',
      isCrossailOffer: 'Было озвучено предложение приобрести кроссейлы?',
      isTheClientImmediatelyAgreed:
        'Клиент согласился сразу приобрести дополнительные товары?',
      isHandlingObjections: 'Отработал ли оператор возражения?',
      isUndercharacterization:
        'При разговоре с клиентом были озвучены корректные характеристики основного товара?',
      areCharacteristicsStatedCorrectly:
        'Характеристики по товару были озвучены корректные?',
      isFullNameVerification: 'При завершении сделки была сверка ФИО?',
      isFullNameCorrect: 'ФИО в бланке заказа указано верно?',
      isAddressVerification: 'При завершении сделки была сверка адреса?',
      isAddressCorrect: 'Адрес указан верно в бланке заказа?',
      isNpDepartmentVerification:
        'При завершении сделки была сверка отделения НП?',
      isNpDepartmentCorrect: 'Отделение НП в бланке заказа указано корректно?',
      isOrderListVerification:
        'При завершении сделки была сверка состава заказа? (всех позиций)',
      isOrderListCorrect: 'Состав заказа в бланке заказа указано корректно?',
      isTotalOrderAmountVerification:
        'При завершении сделки была сверка итоговой стоимости заказа?',
      isTotalOrderAmountCorrect:
        'Итоговая стоимость в бланке заказа указано корректно?',
      isPlusDeliveryAmount:
        'При завершении сделки было озвучено + стоимость доставки согласно тарифам НП?',
      isOrderApprovedByClient:
        'Клиент дал чёткое согласие на оформление заказа?',
      isMotivationForRansom: 'Была озвучена мотивация на выкуп заказа?',
      isHundredPercentCashback: 'Было озвучено о 100% возвращении кешбека?',
      isOrderStatusCorrect: 'Статус заказа выставлен корректно?',
      isCallTimeExceeded: 'Длительность разговора была менее 7 минут?',
      isThirdPartyVerification:
        'Если заказ был на 3-е лицо была ли сверка данных получателя?',
      isThirdPartyCorrect:
        'Данные 3-го лица указаны корректно в бланке заказа?',
      isCallingBackThirdParty:
        'Если заказ был на 3-е лицо был ли перезвон получателю?',
      isConversationReset: 'Был ли сброс разговора?',
      isTryCallBack: 'Была попытка перезвонить клиенту?',
      isOperatorHandleObjectionToSave:
        'Если клиент позвонил чтобы отказаться от ранее оформленного заказа, старался ли оператор сохранить заказ?',
      fineAmount: 'Сумма штрафа: ',
      checkListComment: '',
      urlImage: 'Укажите урл адрес записи скриншота',
      urlRecord: 'Укажите урл адрес записи разговора',
      isOperatorSpeakCorrectly: 'Разговор с клиентом был корректным?',
      uploadImage: '',
      uploadRecord: '',
      update: 'Внести изменения',
      delete: 'Удалить запись',
      cancel: 'Отменить',
      save: 'Сохранить',
      choosePeriod: 'Выбрать новый период',
      notDataOrError: 'К сожалению, по выбранным критериям чек листов не найдено',
      addNewCheckList: 'Создать новый чек лист',
      createdDate: 'Дата создания',
      orderNumber: 'Номер заказа',
      fullName: 'ФИО',
      productTitle: 'Название оффера',
      greetting: 'Приветствие',
      identifyingNeeds: 'Выявления потребностей',
      presentation: 'Презентация товара',
      statusFines: 'Статус штрафа',
      statusOrder: 'Статус заказа',
      finesAmount: 'Сумма штрафа',
      finesCategory: 'Категории ошибок',
      managerComment: 'Комментарий',
      screenshot: 'Скриншот',
      audioFile: 'Записи разговоров',
      offerOfKits:
        'Предложение комплекты или нескольких различных товаров',
        offeringAnImprovedVersion:
        'Улучшенная версия товара в отдельности',
        crossailOffer: 'Предложение купить кроссейлы',
        theClientImmediatelyAgreed:
        'Клиент согласился сразу приобрести дополнительные товары',
        handlingObjections: 'Отработка оператором возражений',
        undercharacterization:
        'Озвучены корректно характеристики основного товара',
        characteristicsStatedCorrectly:
        'Характеристики всех товаров были озвучены корректно',
        fullNameVerification: 'Сверка ФИО', 
        fullNameCorrect: 'Корректность ФИО',
        addressVerification: 'Сверка адреса',
      addressCorrect: 'Корректность адреса',
      npDepartmentVerification:
        'Сверка отделения НП',
        npDepartmentCorrect:
        'Корректность отделения НП',
        orderListVerification:
        'Сверка состава заказа',
        orderListCorrect:
        'Корректность склада заказа',
        totalOrderAmountVerification:
        'Сверка итоговой стоимости заказа',
        totalOrderAmountCorrect:
        'Корректность итоговой стоимости заказа',
  plusDeliveryAmount:
        'Озвучено + стоимость доставки согласно тарифам ЧП',
        orderApprovedByClient:
        'Клиент дал четкое согласие на оформление',
      motivationForRansom:
      'Мотивация на выкуп заказа',
     hundredPercentCashback:
      'Не 100% возвращение кэшбека',
      orderStatusCorrect:
      'Корректность Статуса заказа',
      callTimeExceeded:
      'Длительность разговора менее 7 минут',
        thirdPartyVerification:
        'Сверка данных получателя (3-е лицо)',
        thirdPartyCorrect:
        'Корректность данных получателя (3-е лицо)',
        callingBackThirdParty:
        'Звонок получателю (3-й человек)',
      conversationReset:
      'Сброс разговора',
      tryCallBack:
      'Попытка перезвонить',
        operatorHandleObjectionToSave:
        'Попытка сохранить заказ (при отказе от ранее оформленного)',
        operatorSpeakCorrectly: 'Разговор корректен?',
        createdFullName: 'ФИО кто создал',
        successSave: 'Запись успешно создана.',
        successUpdate: 'Запись успешно изменена.',
        successRemoved: 'Запись успешно удалена.',
        finesCategoryLabel: 'Категории ошибок:',
        addAudioFile: 'Добавьте аудио файлы:',
        chooseEmployeeTwo: 'Выберите оператора',
        chooseEmployeeOne: 'Выберите оператора:',
        enterSearchQuery: 'Введите название офера, номере заказа или ФИО оператора:',
        chooseManagerTwo: 'Выберите менеджера',
        chooseManagerOne: 'Выберите менеджера:',
        finesStatus: 'Выберите статус штрафа:',
        clearAllSearchValue: 'Очистить все фильтры поиска',
        orderStatistics: 'По заказам',
        total: 'Всего: ',
        notFines: 'Без нарушений: ',
        fines: 'Штрафов: ',
        warning: 'Предупреждений: ',
        errorPercent: 'Процент ошибок: ',
        announced: 'Озвучено: ',
        notAnonced: 'Не озвучено: ',
        percent: 'Ошибок: ',
        statistics: '',
        seeChanges: 'Посмотреть динамику',
        statistic: 'Статистика',
        chooseFilter: 'Выберите значение статистики: ',
        finesStatistic: 'Статистика по штрафам',
      totalWithoutSymbols: 'Всего заказов',
      notFinesWithoutSymbols: 'Без нарушений',
      finesWithoutSymbols: 'Штрафов',
      warningWithoutSymbols: 'Предупреждения',
      errorPercentWithoutSymbols: 'Процент ошибок',
        everydayStatistics: 'Ежедневная статистика',
        oneOrderWorkDuration: 'Среднее время на 1-н заказу',
        categoryStatistics: 'Статистика по категориям штрафов',
        finesReason: 'Причина штрафа',
        isComment: 'Оператор указал комментарий к заказу?',
        isProposalClubCard: 'Была ли попытка поднятия среднего чека путём добавления клубной карты?',
        isOperatorLeftComment: 'Комментарий указан',
        isOperatorProposalClubCard: 'Оператор предлагал клубную карту?',
        isCorrectClaimProcessing: 'Претензия была обработана корреткно?',
        isCorrectInsertIntoBlackList: 'Клиент внесён в черный список корреткно?',
        isEmptyOrderDrain: 'Пустой заказ оформлен корректно?'
    },
  ],
  ukraine: [
    {
      enterOrderNumber: 'Введіть номер замовлення:',
      orderStatus: 'Оберіть статус замовлення:',
      finesStatus: 'Оберіть статус штрафа:',
      chooseEmployee: 'Оберіть  оператора:',
      enterProductTitle: 'Введіть назву товару:',
      yes: 'Так',
      no: 'Ні',
      notChange: 'Без змін',
      leftSymbols: 'Залишилося символів:',
      comment: 'Опишіть помилки оператора:',
      isGreeting: 'Чи було озвучено вітання?',
      greetting: 'Вітання',
      isIdentifyingNeeds: 'Чи були виявлені потреби?',
      identifyingNeeds: 'Виявленя потреб',
      isPresentation: 'Чи була озвучена презентація товару?',
      presentation: 'Презентація товару',
      isOfferOfKits:
        'Були запропоновані комплекти чи пропозицію своїх комплектів (чи кількох різних товарів як комплект)?',
        offerOfKits:
        'Пропозиція комплекти чи кількох різних товарів',
      isOfferingAnImprovedVersion:
        'Була озвучена пропозиція придбати покращену версію товару окремо?',
        offeringAnImprovedVersion:
        'Покращена версія товару окремо',
      isCrossailOffer: 'Чи була озвучена пропозиція придбати кросейли?',
      crossailOffer: 'Пропозиція придбати кросейли',
      isTheClientImmediatelyAgreed:
        'Клієнт погодився одразу придбати додаткові товари?',
        theClientImmediatelyAgreed:
        'Клієнт погодився одразу придбати додаткові товари',
      isHandlingObjections: 'Чи відпрацював оператор заперечення?',
      handlingObjections: 'Відпрацювання оператором запереченнь',
      isUndercharacterization:
        'Під час розмови із клієнтом були озвучені корректні характеристики основного товару?',
        undercharacterization:
        'Озвучені корректно характеристики основного товару',
      areCharacteristicsStatedCorrectly:
        'Характеристики товару були озвучені коректні?',
        characteristicsStatedCorrectly:
        'Характеристики всіх товарів були озвучені коректно',
      isFullNameVerification: 'При завершенні угоди було звіряння ПІБ?',
      fullNameVerification: 'Звіряння ПІБ',
      isFullNameCorrect: 'ПІБ в бланку замовлення вказано правильно?',
      fullNameCorrect: 'Корректність ПІБ',
      isAddressVerification: 'При завершенні угоди було звіряння адреси?',
      addressVerification: 'Звіряння адреси',
      isAddressCorrect: 'Адреса вказана правильно в бланку замовлення?',
      addressCorrect: 'Корректність адреси',
      isNpDepartmentVerification:
        'При завершенні угоди було звіряння відділення НП?',
        npDepartmentVerification:
        'Звіряння відділення НП',
      isNpDepartmentCorrect:
        'Відділення НП у бланку замовлення вказано коректно?',
        npDepartmentCorrect:
        'Корректність відділення НП',
      isOrderListVerification:
        'При завершенні угоди було звіряння складу замовлення? (Всіх позицій)',
        orderListVerification:
        'Звіряння складу замовлення',
      isOrderListCorrect:
        'Склад замовлення у бланку замовлення вказано коректно?',
        orderListCorrect:
        'Корректність складу замовлення',
      isTotalOrderAmountVerification:
        'При завершенні угоди було звіряння підсумкової вартості замовлення?',
        totalOrderAmountVerification:
        'Звіряння підсумкової вартості замовлення',
      isTotalOrderAmountCorrect:
        'Підсумкова вартість у бланку замовлення вказано коректно?',
        totalOrderAmountCorrect:
        'Корректність підсумкової вартості замовлення',
      isPlusDeliveryAmount:
        'При завершенні угоди було озвучено + вартість доставки згідно з тарифами НП?',
        plusDeliveryAmount:
        'Озвучено + вартість доставки згідно з тарифами НП',
      isOrderApprovedByClient:
        'Клієнт дав чітку згоду на оформлення замовлення?',
        orderApprovedByClient:
        'Клієнт дав чітку згоду на оформлення',
      isMotivationForRansom: 'Чи була озвучена мотивація на викуп замовлення?',
      motivationForRansom:
      'Мотивація на викуп замовлення',
      isHundredPercentCashback: 'Чи було озвучено про не 100% повернення кешбеку?',
      hundredPercentCashback:
      'Не 100% повернення кешбеку',
      isOrderStatusCorrect: 'Статус замовлення виставлено коректно?',
      orderStatusCorrect:
      'Корректність Статусу замовлення',
      isCallTimeExceeded: 'Тривалість розмови була менше 7 хвилин?',
      callTimeExceeded:
      'Тривалість розмови менше 7 хвилин',
      isThirdPartyVerification:
        'Якщо замовлення було на 3-ю особу була чи звірка даних одержувача?',
        thirdPartyVerification:
        'Звірка даних отримувача (3-я особа)',
      isThirdPartyCorrect:
        'Дані 3-ї особи вказані коректно у бланку замовлення?',
        thirdPartyCorrect:
        'Корректність даних отримувача (3-я особа)',
      isCallingBackThirdParty:
        'Якщо замовлення було на 3-ю особу чи передзвонили одержувачу?',
        callingBackThirdParty:
        'Дзвінок отримувачу (3-я особа)',
      isConversationReset: 'Чи було скидання розмови?',
      conversationReset:
      'Скидання розмови',
      isTryCallBack: 'Чи була спроба передзвонити клієнту?',
      tryCallBack:
      'Спроба передзвонити',
      isOperatorHandleObjectionToSave:
        'Якщо клієнт зателефонував, щоб відмовитися від раніше оформленого замовлення, чи намагався оператор зберегти замовлення?',
        operatorHandleObjectionToSave:
        'Спроба зберегти замовлення (при відмові ві раніше оформленого)',
      fineAmount: 'Сума штрафу: ',
      checkListComment: '',
      urlImage: 'Вкажіть урл адресу запису скріншота',
      urlRecord: 'Вкажіть урл адресу запису розмови',
      isOperatorSpeakCorrectly: 'Розмова з клієнтом була корректною?',
      operatorSpeakCorrectly: 'Розмова корректна?',
      uploadImage: '',
      uploadRecord: '',
      update: 'Внести зміни',
      delete: 'Видалити запис',
      cancel: 'Скасувати',
      save: 'Зберегти',
      choosePeriod: 'Обрати новий період',
      notDataOrError: 'На жаль за обраними крітеріями чек листів не знайдено',
      addNewCheckList: 'Створити новий чек лист',
      createdDate: 'Дата створення',
      orderNumber: 'Номер замовлення',
      fullName: 'ПІБ',
      productTitle: 'Назва офферу',
      statusFines: 'Статус штрафа',
      statusOrder: 'Статус замовлення',
      finesAmount: 'Сума штрафу',
      finesCategory: 'Категорії помилок',
      managerComment: 'Коментар',
      screenshot: 'Скріншот',
      audioFile: 'Записи розмов',
      createdFullName: 'ПІБ хто створив',
      successSave: 'Запис успішно створено.',
      successUpdate: 'Запис успішно змінено.',
      successRemoved: 'Запис успішно видалено.',
      finesCategoryLabel: 'Категорії помилок:',
      addAudioFile: 'Додайте аудіо файли:',
      chooseEmployeeTwo: 'Оберіть оператора',
      chooseEmployeeOne: 'Оберіть оператора:',
      enterSearchQuery: 'Введіть назву офера, номер замовлення або ПІБ оператора:',
      chooseManagerTwo: 'Оберіть менеджера',
      chooseManagerOne: 'Оберіть менеджера:',
      clearAllSearchValue: 'Очистити всі фільтри пошуку',
      orderStatistics: 'По замовленнях',
      total: 'Всього: ',
      notFines: 'Без порушень: ',
      fines: 'Штрафів: ',
      warning: 'Попередженнь: ',
      errorPercent: 'Відсоток помилок: ',
      announced: 'Озвучено: ',
      notAnonced: 'Не озвучено: ',
      percent: 'Помилок: ',
      seeChanges: 'Переглянути динаміку',
      statistic: 'Статистика',
      chooseFilter: 'Оберіть значеня статистики: ',
      finesStatistic: 'Статистика по штрафам',
      totalWithoutSymbols: 'Всього замовлень',
      notFinesWithoutSymbols: 'Без порушень',
      finesWithoutSymbols: 'Штрафів',
      warningWithoutSymbols: 'Попередженнь',
      errorPercentWithoutSymbols: 'Відсоток помилок',
      everydayStatistics: 'Щоденна статистика',
      oneOrderWorkDuration: 'Середній час на 1-е замовлення',
      categoryStatistics: 'Статистика за категоріями штрафів',
      finesReason: 'Причина штрафа',
      isComment: 'Оператор вказав коментар до замовлення?',
      isProposalClubCard: 'Чи була спроба підняття середнього чеку шляхом додавання клубної карти?',
      isOperatorLeftComment: 'Коментар вказано',
      isOperatorProposalClubCard: 'Оператор пропонував клубну картку?',
      isCorrectClaimProcessing: 'Претензія була оброблена коректно?',
      isCorrectInsertIntoBlackList: 'Клієнт внесено до чорного списку коректно?',
      isEmptyOrderDrain: 'Пусте замовлення оформлене корректно?'
    },
  ],
};

export const reportsText = {
  russian: [
    {
      reportData: 'Посмотреть отчеты',
      seeChanges: 'Посмотреть динамику',
      totalEmployeeChanges: 'Посмотреть статистику по операторам',
      totalOfferChanges: 'Посмотреть статистику по офферам',
    },
  ],
  ukraine: [
    {
      reportData: 'Переглянути звіти',
      seeChanges: 'Переглянути динаміку',
      totalEmployeeChanges: 'Переглянути статистику по операторам',
      totalOfferChanges: 'Переглянути статистику по оферам',
    },
  ],
};

export const callReportsText = {
  russian: [
    {
      addNewReport: 'Добавить новий отчёт',
      chooseDate: 'Выберите дату:',
      enterTime: 'Введите время автообзвона новых заказов в Cloud Voip Time:',
      enterTotalOrderInNewStatus:
        'Введите количество заказов в статусе автообзвон новых в Cloud Voip Time:',
      enterAttemptCalledOperators:
        'Введите значение попытки прозвана операторов на 10:00 в Cloud VoipTime',
      enterTotalOrder:
        'Введите общее количество совершенных звонков за выбранный день:',
      enterTotalSuccessOrder:
        'Введите количество успешных совершенных звонков за выбранный день:',
      enterTotalUnsuccessOrder:
        'Введите количество неуспешных совершенных звонков за выбранный день:',
      enterTotalLostOrder:
        'Введите количество утерянных совершенных звонков за выбранный день:',
      enterLogedInTime:
        'Введите время в статусе Logged In time (время в системе войп тайм):',
      enterTalkingTime:
        'Введите время в статусе Talking time (время в разговоре):',
      enterNotReadyTime:
        'Введите время в статусе Not Ready time (время в статусе "Не готов"):',
      enterReadyTime:
        'Введите время в статусе Ready time (время в статусе "Готов"):',
      enterBreakTime: 'Введите время в статусе "Перерыв":',
      enterApproveTime: 'Введите время в статусе "Оформление заявки":',
      enterTechnicalBreakTime: 'Введите время в статусе "Технический Перерыв":',
      enterPlanningMeetingTime: 'Введите время в статусе "Планерка":',
      enterRealNotReadyTime:
        'Введите реальное время в статусе Not Ready time (время в статусе "Не готов"):',
      enterRealTimeAtWork:
        'Введите реальное время в статусе "Реальное время в работе":',
      enterReceivedPerHourWithoutNotReadyTime:
        'Введите количество принятых заказов в час без Not Ready Time:',
      enterPercentNotReadyTime:
        'Введите процент Not Ready Time от времени в системе:',
      enterPercentBreakTime: 'Введите процент Перерывов от времени в системе:',
      enterPercentApproveTime:
        'Введите процент "Оформления заказа" от времени в системе:',
      enterReportComment: 'Введите комментарий к отчёту:',
      update: 'Внести изменения',
      delete: 'Удалить запись',
      cancel: 'Отменить',
      save: 'Сохранить',
      date: 'Дата',
      time: 'Время автообзвона новых заказов в VoipTime',
      totalOrderInNewStatus: 'Заказов в статусе автообзвон новых в VoipTime',
      attemptCalledOperators: 'Попыток прозвана операторов на 10:00 в VoipTime',
      totalOrder: 'Общее количество звонков',
      totalSuccessOrder: 'Количество успешных звонков',
      totalUnsuccessOrder: 'Количество неуспешных звонков',
      totalLostOrder: 'Количество утерянных звонков',
      logedInTime: 'Время в статусе Logged In time',
      talkingTime: 'Время в статусе Talking time',
      notReadyTime: 'Время в статусе Not Ready time',
      readyTime: 'Время в статусе Ready time',
      breakTime: 'Время в статусе "Перерыв"',
      approveTime: 'Время в статусе "Оформление заявки"',
      technicalBreakTime: 'Время в статусе "Технический Перерыв"',
      planningMeetingTime: 'Время в статусе "Планерка"',
      realNotReadyTime: 'Реальное время в статусе Not Ready time',
      realTimeAtWork: 'Реальное время в статусе "Реальное время в работе"',
      receivedPerHourWithoutNotReadyTime:
        'Количество принятых заказов в час без Not Ready Time',
      percentNotReadyTime: 'Процент Not Ready Time от времени в системе',
      percentBreakTime: 'Процент Перерывов от времени в системе',
      percentApproveTime: 'Процент "Оформления заказа" от времени в системе',
      reportComment: 'Комментарий',
      notDataOrError: 'К сожалению у вас пока нет данных с отчётами',
      successSave: 'Запись успешно создана.',
      successUpdate: 'Запись успешно изменена.',
      successRemoved: 'Запись успешно удалена.',
      choosePeriod: 'Выбрать новый период',
    },
  ],
  ukraine: [
    {
      addNewReport: 'Додати новий звіт',
      chooseDate: 'Оберіть дату:',
      enterTime: 'Введіть час автообзвону нових замовлень у Cloud Voip Time:',
      enterTotalOrderInNewStatus:
        'Введіть кількість замовлень у статусі автообзвон нових у Cloud Voip Time:',
      enterAttemptCalledOperators:
        'Введіть значення спроби прозвону операторів на 10:00 в Cloud VoipTime',
      enterTotalOrder: 'Введіть загальну кількість дзвінків за обрану дату:',
      enterTotalSuccessOrder:
        'Введіть кількість успішних дзвінків за обрану дату:',
      enterTotalUnsuccessOrder:
        'Введіть кількість неуспішних дзвінків за обрану дату:',
      enterTotalLostOrder:
        'Введіть кількість втрачених дзвінків за обрану дату:',
      enterLogedInTime:
        'Введіть час у статусі Logged In time (час у системі войп-тайм):',
      enterTalkingTime: 'Введіть час у статусі Talking time (час у розмові):',
      enterNotReadyTime:
        'Введіть час у статусі Not Ready time (час у статусі "Не готовий"):',
      enterReadyTime:
        'Введіть час у статусі Ready time (час у статусі "Готовий"):',
      enterBreakTime: 'Введіть час у статусі "Перерва":',
      enterApproveTime: 'Введіть час у статусі "Оформлення заявки":',
      enterTechnicalBreakTime: 'Введіть час у статусі "Технічна перерва":',
      enterPlanningMeetingTime: 'Введіть час у статусі "Планерка":',
      enterRealNotReadyTime:
        'Введіть реальний час у статусі Not Ready time (час у статусі "Не готовий"):',
      enterRealTimeAtWork:
        'Введіть реальний час у статусі "Реальний час у роботі":',
      enterReceivedPerHourWithoutNotReadyTime:
        'Введіть кількість прийнятих замовлень за годину без Not Ready Time:',
      enterPercentNotReadyTime:
        'Введіть відсоток Not Ready Time від часу в системі:',
      enterPercentBreakTime: 'Введіть відсоток Перерв від часу в системі:',
      enterPercentApproveTime:
        'Введіть відсоток "Оформлення замовлення" від часу в системі:',
      enterReportComment: 'Введіть коментар до звіту:',
      update: 'Внести зміни',
      delete: 'Видалити запис',
      cancel: 'Скасувати',
      save: 'Зберегти',
      date: 'Дата',
      time: 'Час автообзвону нових замовлень у VoipTime',
      totalOrderInNewStatus: 'Замовлень у статусі автообзвон нових у VoipTime ',
      attemptCalledOperators: 'Спроб прозвону операторів на 10:00 в VoipTime',
      totalOrder: 'Загальна кількість дзвінків',
      totalSuccessOrder: 'Кількість успішних дзвінків',
      totalUnsuccessOrder: 'Кількість неуспішних дзвінків',
      totalLostOrder: 'Кількість втрачених дзвінків',
      logedInTime: 'Час у статусі Logged In time',
      talkingTime: 'Час у статусі Talking time',
      notReadyTime: 'Час у статусі Not Ready time',
      readyTime: 'Час у статусі Ready time',
      breakTime: 'Час у статусі "Перерва"',
      approveTime: 'Час у статусі "Оформлення заявки"',
      technicalBreakTime: 'Час у статусі "Технічна перерва"',
      planningMeetingTime: 'Час у статусі "Планерка"',
      realNotReadyTime: 'Реальний час у статусі Not Ready time',
      realTimeAtWork: 'Реальний час у статусі "Реальний час у роботі"',
      receivedPerHourWithoutNotReadyTime:
        'Кількість прийнятих замовлень за годину без Not Ready Time',
      percentNotReadyTime: 'Відсоток Not Ready Time від часу в системі',
      percentBreakTime: 'Відсоток Перерв від часу в системі',
      percentApproveTime: 'Відсоток "Оформлення замовлення" від часу в системі',
      reportComment: 'Коментар',
      notDataOrError: 'На жаль у вас поки немає даних зі звітами',
      successSave: 'Запис успішно створено.',
      successUpdate: 'Запис успішно змінено.',
      successRemoved: 'Запис успішно видалено.',
      choosePeriod: 'Обрати новий період',
    },
  ],
};

export const employeesEfficiencyText = {
  russian: [
    {
      addNewReport: 'Добавить новий отчёт',
      chooseDate: 'Выберите дату:',
      enterReportComment: 'Введите комментарий к отчёту:',
      update: 'Внести изменения',
      delete: 'Удалить запись',
      cancel: 'Отменить',
      save: 'Сохранить',
      date: 'Дата',
      reportComment: 'Комментарий',
      notDataOrError: 'К сожалению у вас пока нет данных с отчётами',
      successSave: 'Запись успешно создана.',
      successUpdate: 'Запись успешно изменена.',
      successRemoved: 'Запись успешно удалена.',
      choosePeriod: 'Выбрать новый период',
      chooseTopBestEmployeeByApproval:
        'Выберите оператора из топ 5 лучших по проценту апрува, по оформлению:',
      enterApprovePercent: 'Введите процент апрува:',
      enterTotalApprove: 'Введите количество апрувов:',
      topBestEmployeeByApproval: 'Топ 5 лучших операторов по проценту апрува',
      chooseTopWorstEmployeeByApproval:
        'Выберите оператора из топ 5 худших по проценту апрува, по оформлению:',
      topWorstEmployeeByApproval: 'Топ 5 худших операторов по проценту апрува',
      chooseTopBestEmployeeByUpsells:
        'Выберите оператора из топ 5 лучших по проценту дополнительных продаж, по оформлению:',
      topBestEmployeeByUpsells: 'Топ 5 лучших операторов дополнительных продаж',
      enterUpsellsPercent: 'Введите количество дополнительных продаж:',
      chooseTopWorstEmployeeByUpsells:
        'Выберите оператора из топ 5 худших по проценту дополнительных продаж, по оформлению:',
      enterNetIncome: 'Введите показатель маржи:',
      topWorstEmployeeByUpsells:
        'Топ 5 худших операторов по проценту дополнительных продаж',
      chooseTopBestEmployeeByNetIncome:
        'Выберите оператора из топ 5 лучших по марже с дополнительных продаж:',
      topBestEmployeeByNetIncome:
        'Топ 5 лучших операторов по марже с дополнительных продаж',
      chooseTopWorstEmployeeByNetIncome:
        'Выберите оператора из топ 5 худших по марже с дополнительных продаж:',
      topWorstEmployeeByNetIncome:
        'Топ 5 худших операторов по марже с дополнительных продаж',
      chooseTopBestEmployeeByApprovalPerHour:
        'Выберите оператора из топ 5 лучших по количеству принятых в час:',
      enterApprovalPerHour: 'Введите количество принятых в час:',
      topBestEmployeeByApprovalPerHour:
        'Топ 5 лучших операторов по количеству принятых в час',
      chooseTopWorstEmployeeByApprovalPerHour:
        'Выберите оператора из топ 5 худших по количеству принятых в час:',
      topWorstEmployeeByApprovalPerHour:
        'Топ 5 худших операторов по количеству принятых в час',
      enterTopOferEmployeeByUpsells:
        'Выберите оффер из топ 5 лучших по проценту дополнительных продаж (от 10 апров), по оформлению:',
      topBestOferByUpsells:
        'Топ 5 лучших офферов по проценту дополнительных продаж',
      enterTopWorstOferByUpsells:
        'Выберите оффер из топ 5 худших по проценту дополнительных продаж (от 10 апров), по оформлению:',
      topWorstOferByUpsells:
        'Топ 5 худших офферов по проценту дополнительных продаж',
      enterTopOferEmployeeByNetIncome:
        'Выберите оффер из топ 5 лучших по марже с дополнительных продаж:',
      topBestOferByNetIncome:
        'Топ 5 лучших офферов по марже с дополнительных продаж',
      enterTopWorstOferByNetIncome:
        'Выберите оффер из топ 5 худших по марже с дополнительных продаж:',
      topWorstOferByNetIncome:
        'Топ 5 худших офферов по марже с дополнительных продаж',
      chooseEmployeeWithHighestNumberOfErrors: '',
      employeeWithHighestNumberOfErrors: '',
      enterNumberOfInterruptions: '',
      chooseEmployeeTwo: 'Выберите оператора',
      addEmployee: 'Добавить оператора',
      deleteEmployee: 'Удалить последнего оператора',
      addNew: 'Добавить новое поле',
      deleteLast: 'Удалить последнее поле',
      total: 'Всего:',
      fullName: 'ФИО',
      approvePercent: 'Процент апрува',
      totalApprove: 'Количество апрувов',
      netIncome: 'Маржа по дополнительным продажам',
      totalErrorCounts: 'Всего ошибок из логов CRM',
      totalErrorInterruptions: 'Всего обрывов',
      upsellsPercent: 'Процент дополнительных продаж',
      approvePerHour: 'Количество апрувов за рабочий час',
      productTitle: 'Название офера',
    },
  ],
  ukraine: [
    {
      addNewReport: 'Додати новий звіт',
      chooseDate: 'Оберіть дату:',
      enterReportComment: 'Введіть коментар до звіту:',
      update: 'Внести зміни',
      delete: 'Видалити запис',
      cancel: 'Скасувати',
      save: 'Зберегти',
      date: 'Дата',
      reportComment: 'Коментар',
      notDataOrError: 'На жаль у вас поки немає даних зі звітами',
      successSave: 'Запис успішно створено.',
      successUpdate: 'Запис успішно змінено.',
      successRemoved: 'Запис успішно видалено.',
      choosePeriod: 'Обрати новий період',
      chooseTopBestEmployeeByApproval:
        'Оберіть оператора з топ 5 найкращих за відсотком апрува, по оформленню:',
      enterApprovePercent: 'Введіть відсоток апрува:',
      enterTotalApprove: 'Введіть кількість апрувів:',
      topBestEmployeeByApproval:
        'Топ 5 найкращих операторів по відсотку апрува',
      chooseTopWorstEmployeeByApproval:
        'Оберіть оператора з топ 5 найгірших за відсотком апрува, за оформленням:',
      topWorstEmployeeByApproval:
        'Топ 5 найгірших операторів по відсотку апрува',
      chooseTopBestEmployeeByUpsells:
        'Оберіть оператора з топ 5 найкращих за відсотком додаткових продажів, по оформленню:',
      topBestEmployeeByUpsells:
        'Топ 5 найкращих операторів  за відсотком додаткових продажів',
      enterUpsellsPercent: 'Введіть відсоток додаткових продажів:',
      chooseTopWorstEmployeeByUpsells:
        'Оберіть оператора з топ 5 найгірших за відсотком додаткових продажів, по оформленню:',
      topWorstEmployeeByUpsells:
        'Топ 5 найгірших операторів  за відсотком додаткових продажів',
      chooseTopBestEmployeeByNetIncome:
        'Оберіть оператора з топ 5 найкращих за маржою з додаткових продажів:',
      topBestEmployeeByNetIncome:
        'Топ 5 найкращих операторів за маржою з додаткових продажів',
      chooseTopWorstEmployeeByNetIncome:
        'Оберіть оператора з топ 5 найгірших за маржою з додаткових продажів:',
      enterNetIncome: 'Введіть показник маржі:',
      topWorstEmployeeByNetIncome:
        'Топ 5 найгірших операторів за маржою з додаткових продажів',
      chooseTopBestEmployeeByApprovalPerHour:
        'Виберіть оператора з топ 5 кращих за кількістю прийнятих за годину:',
      enterApprovalPerHour: 'Введіть кількость прийнятих за годину:',
      topBestEmployeeByApprovalPerHour:
        'Топ 5 найкращих операторів за кількістю прийнятих за годину',
      chooseTopWorstEmployeeByApprovalPerHour:
        'Виберіть оператора з топ 5 найгірших за кількістю прийнятих за годину:',
      topWorstEmployeeByApprovalPerHour:
        'Топ 5 найгірших операторів за кількістю прийнятих за годину',
      enterTopOferEmployeeByUpsells:
        'Оберіть оффер з топ 5 найкращих за відсотком додаткових продажів (від 10 апрувів), по оформленню:',
      topBestOferByUpsells:
        'Топ 5 найкращих офферів за відсотком додаткових продажів',
      enterTopWorstOferByUpsells:
        'Оберіть оффер з топ 5 найгірших за відсотком додаткових продажів (від 10 апрувів), по оформленню:',
      topWorstOferByUpsells:
        'Топ 5 найгірших офферів за відсотком додаткових продажів',
      enterTopOferEmployeeByNetIncome:
        'Оберіть оффер з топ 5 найкращих  за маржою з додаткових продажів:',
      topBestOferByNetIncome:
        'Топ 5 найкращих офферів за маржою з додаткових продажів',
      enterTopWorstOferByNetIncome:
        'Оберіть оффер з топ 5 найгірших за маржою з додаткових продажів:',
      topWorstOferByNetIncome:
        'Топ 5 найгірших офферів  за маржою з додаткових продажів',
      chooseEmployeeWithHighestNumberOfErrors: '',
      employeeWithHighestNumberOfErrors: '',
      enterNumberOfInterruptions: '',
      chooseEmployeeTwo: 'Оберіть оператора',
      addEmployee: 'Додати оператора',
      deleteEmployee: 'Видалити останнього оператора',
      addNew: 'Додати нове поле',
      deleteLast: 'Видалити останнє поле',
      total: 'Всього:',
      fullName: 'ПІБ',
      approvePercent: 'Відсоток апрува',
      totalApprove: 'Кількість апрувів',
      upsellsPercent: 'Відсоток додаткових продаж',
      netIncome: 'Маржа з додаткових продаж',
      totalErrorCounts: 'Усього помилок з логів CRM',
      totalErrorInterruptions: 'Усього обривів',
      approvePerHour: 'Кількість апрувів за робочу годину',
      productTitle: 'Назва оферу',
    },
  ],
};

export const allDepartmentReportText = {
  russian: [
    {
      addNewReport: 'Добавить новий отчёт',
      chooseDate: 'Выберите дату:',
      enterReportComment: 'Введите комментарий к отчёту:',
      update: 'Внести изменения',
      delete: 'Удалить запись',
      cancel: 'Отменить',
      save: 'Сохранить',
      date: 'Дата',
      reportComment: 'Комментарий',
      notDataOrError: 'К сожалению у вас пока нет данных с отчётами',
      successSave: 'Запись успешно создана.',
      successUpdate: 'Запись успешно изменена.',
      successRemoved: 'Запись успешно удалена.',
      choosePeriod: 'Выбрать новый период',
      enterNumberOfEmployeesOnShift:
        'Введите количество операторов в смене за выбранную дату:',
      numberOfEmployeesOnShift:
        'Количество операторов в смене за выбранную дату:',
      enterTotalNumberOfHoursWorkedPerShift:
        'Введите общее количество отработанных часов операторов за смену:',
      totalNumberOfHoursWorkedPerShift:
        'Общее количество отработанных часов операторов за смену',
      enterNumberOfHoursOnKZ: 'Введите количество часов на КЗ/УЗ:',
      numberOfHoursOnKZ: 'Количество часов на КЗ/УЗ',
      enterNumberOfHoursOnIncoming: 'Введите количество часов на Входяшке:',
      numberOfHoursOnIncoming: 'Количество часов на Входяшке',
      enterNumberOfHoursOnRefusals: 'Введите количество часов на Отказах:',
      numberOfHoursOnRefusals: 'Количество часов на Отказах',
      enterNumberOfHoursOnDownsale: 'Введите количество часов на Downsale:',
      numberOfHoursOnDownsale: 'Количество часов на Downsale',
      enterTotalNumberOfHoursOnUkraine:
        'Введите общее количество часов на Украине:',
      numberTotalOfHoursOnUkraine: 'Общее количество часов на Украине',
      enterShouldBeHoursOnUkraine:
        'Введите сколько должно быть всего часов на Украину включая допы:',
      numberShouldBeHoursOnUkraine:
        'Должно быть всего часов на Украину включая допы',
      enterNumberOfWorkingHoursBeforeChanges:
        'Введите количество рабочих часов по графику(фиксируем до начала внесения допов в график):',
      numberOfWorkingHoursBeforeChanges:
        'Количество рабочих часов по графику(до внесения допов)',
      enterNumberOfOvertimeHours:
        'Введите количество часов подработок (по плану 39):',
      numberOfOvertimeHours: 'Количество часов подработок (по плану 39)',
      enterNotFollowingPlan: 'Введите значение не выполнения плана:',
      notFollowingPlan: 'Значение не выполнения плана',
      enterPercentNotFollowingPlan:
        'Введите процент не выполнения плана по часам:',
      percentNotFollowingPlan: ' Процент не выполнения плана по часам',
      enterNumberOfEmployeesOnEducation:
        'Введите количество операторов на обучении:',
      numberOfEmployeesOnEducation: 'Количество операторов на обучении',
    },
  ],
  ukraine: [
    {
      addNewReport: 'Додати новий звіт',
      chooseDate: 'Оберіть дату:',
      enterReportComment: 'Введіть коментар до звіту:',
      update: 'Внести зміни',
      delete: 'Видалити запис',
      cancel: 'Скасувати',
      save: 'Зберегти',
      date: 'Дата',
      reportComment: 'Коментар',
      notDataOrError: 'На жаль у вас поки немає даних зі звітами',
      successSave: 'Запис успішно створено.',
      successUpdate: 'Запис успішно змінено.',
      successRemoved: 'Запис успішно видалено.',
      choosePeriod: 'Обрати новий період',
      enterNumberOfEmployeesOnShift:
        'Введіть кількість операторів у зміні за обрану дату:',
      numberOfEmployeesOnShift: 'Кількість операторів у зміні за обрану дату',
      enterTotalNumberOfHoursWorkedPerShift:
        'Введіть загальну кількість відпрацьованих годин операторів за зміну:',
      totalNumberOfHoursWorkedPerShift:
        'Загальна кількість відпрацьованих годин операторів за зміну',
      enterNumberOfHoursOnKZ: 'Введіть кількість годин на КЗ/УЗ:',
      numberOfHoursOnKZ: 'Кількість годин на КЗ/УЗ',
      enterNumberOfHoursOnIncoming: 'Введіть кількість годин на Вхідній лінії:',
      numberOfHoursOnIncoming: 'Кількість годин на Вхідній лінії',
      enterNumberOfHoursOnRefusals: 'Введіть кількість годин на Відмовах:',
      numberOfHoursOnRefusals: 'Кількість годин на Відмовах',
      enterNumberOfHoursOnDownsale: 'Введіть кількість годин на Downsale:',
      numberOfHoursOnDownsale: 'Кількість годин на Downsale',
      enterTotalNumberOfHoursOnUkraine:
        'Введіть загальну кількість годин по Україні:',
      numberTotalOfHoursOnUkraine: 'Загальна кількість годин по Україні',
      enterShouldBeHoursOnUkraine:
        'Введіть скільки має бути всього годин по Україні, включаючи допи:',
      numberShouldBeHoursOnUkraine:
        'Має бути всього годин по Україні, включаючи допи.',
      enterNumberOfWorkingHoursBeforeChanges:
        'Введіть кількість робочих годин за графіком (фіксуємо до початку внесення допів у графік):',
      numberOfWorkingHoursBeforeChanges:
        'Кількість робочих годин за графіком (до внесення допів)',
      enterNumberOfOvertimeHours:
        'Введіть кількість годин підробітків (за планом 39):',
      numberOfOvertimeHours: 'Кількість годин підробітків (за планом 39):',
      enterNotFollowingPlan: 'Введіть значення не виконання плану:',
      notFollowingPlan: 'Значення не виконання плану',
      enterPercentNotFollowingPlan:
        'Введіть відсоток не виконання плану за годинами:',
      percentNotFollowingPlan: 'Відсоток не виконання плану за годинами',
      enterNumberOfEmployeesOnEducation:
        'Введіть кількість операторів на навчанні:',
      numberOfEmployeesOnEducation: 'Кількість операторів на навчанні',
      numberOfWorkingHoursBeforeChangesForBarChart:
        'Кількість робочих годин за графіком (до внесення допів)',
    },
  ],
};

export const employeeReportText = {
  russian: [
    {
      addNewReport: 'Добавить новий отчёт',
      chooseDate: 'Выберите дату:',
      enterReportComment: 'Введите комментарий к отчёту:',
      update: 'Внести изменения',
      delete: 'Удалить запись',
      cancel: 'Отменить',
      save: 'Сохранить',
      date: 'Дата',
      reportComment: 'Комментарий',
      notDataOrError: 'К сожалению у вас пока нет данных с отчётами',
      successSave: 'Запись успешно создана.',
      successUpdate: 'Запись успешно изменена.',
      successRemoved: 'Запись успешно удалена.',
      choosePeriod: 'Выбрать новый период',
      enterNetIncomeByApproveBy:
        'Введите значение средней маржи с допов по Апруву (по оформлению):',
      netIncomeByApproveBy: 'Средняя маржа с допов по Апруву (по оформлению)',
      chooseEmployeeWithHighestNumberOfErrors:
        'Выберите операторов с наибольшим количество ошибок с логов CRM:',
      enterNumberOfErrors: 'Введите количество ошибок:',
      employeeWithHighestNumberOfErrors:
        'Операторы с наибольшим количество ошибок с логов CRM',
      chooseEmployeeWithHighestNumberOfInterruptions:
        'Выберите операторов с наибольшим количество обрывов:',
      enterNumberOfInterruptions: 'Введите количество обрывов:',
      employeeWithHighestNumberOfInterruptions:
        'Операторы с наибольшим количество обрывов',
      enterNumberOfInterview: 'Введите количество собеседований:',
      numberOfInterview: 'Количество собеседований',
      enterNumberOfNewEmployee:
        'Введите количество добавленных новых операторов за этот день:',
      numberOfNewEmployee:
        'Количество добавленных новых операторов за этот день',
      chooseNumberOfEmployeesInTraining:
        'Выберите операторов на тренинге ( пофамильно ):',
      numberOfEmployeesInTraining: 'Операторы на тренинге',
      enterHowManyScriptsHaveBeenWritten:
        'Введите сколько написано скриптов (название офферов):',
      enterProductTitle:
        'Введите название офера по которому был написан скрипт:',
      howManyScriptsHaveBeenWritten:
        'Сколько написано скриптов (название офферов)',
      enterRequiredCalls:
        'Введите обязательные созвоны: Прослушка - четверг (9:00), Кз/уз - Четверг (10:00), Входяшка - Четверг 9:30, Старт группа - 11:00+/-:',
      enterDeparment: 'Введите отдел в котором назначен созвон:',
      chooseDay: 'Введите день недели:',
      chooseTime: 'Выберите время:',
      requiredCalls:
        'Обязательные созвоны: Прослушка - четверг (9:00), Кз/уз - Четверг (10:00), Входяшка - Четверг 9:30, Старт группа - 11:00+/-',
      enterNumberOfCorrectedOrders:
        'Введите количество исправленных заказов / день (Чат оплат и удаления):',
      numberOfCorrectedOrders:
        'Количество исправленных заказов / день (Чат оплат и удаления)',
      enterNumberOfClaimsDrawnUp:
        'Введите количество составленных претензий(таблица Входяшка):',
      numberOfClaimsDrawnUp:
        'Количество составленных претензий(таблица Входяшка)',
      chooseWorstEmployeesBasedOnDailyStatistics:
        'Выберите худших операторов по показателям ежедневной статистики:',
      worstEmployeesBasedOnDailyStatistics:
        'Худшие операторы по показателям ежедневной статистики',
      enterNumberOfOrderOutOfStock:
        'Введите количество обратных заказов в статусе "Нет в наличии" (перевод в обработку):',
      numberOfOrderOutOfStock:
        'Количество обратных заказов в статусе "Нет в наличии" (перевод в обработку)',
      enterNumberOfWrongNumber:
        'Введите количество обратных заказов в статусе "Неправильный номер" (перевод в новые):',
      numberOfWrongNumber:
        'Количество обратных заказов в статусе "Неправильный номер" (перевод в новые)',
      chooseEmployeeTwo: 'Выберите оператора',
      addEmployee: 'Добавить оператора',
      deleteEmployee: 'Удалить последнего оператора',
      addNew: 'Добавить новое поле',
      deleteLast: 'Удалить последнее поле',
      total: 'Всего:',
      fullName: 'ФИО',
      totalErrorCounts: 'Всего ошибок из логов CRM',
      totalErrorInterruptions: 'Всего обрывов',
      totalTraining: 'Всего был на тренингах',
      totalWorstEmployee: 'Всего был в худших операторах',
    },
  ],
  ukraine: [
    {
      addNewReport: 'Додати новий звіт',
      chooseDate: 'Оберіть дату:',
      enterReportComment: 'Введіть коментар до звіту:',
      update: 'Внести зміни',
      delete: 'Видалити запис',
      cancel: 'Скасувати',
      save: 'Зберегти',
      date: 'Дата',
      reportComment: 'Коментар',
      notDataOrError: 'На жаль у вас поки немає даних зі звітами',
      successSave: 'Запис успішно створено.',
      successUpdate: 'Запис успішно змінено.',
      successRemoved: 'Запис успішно видалено.',
      choosePeriod: 'Обрати новий період',
      enterNetIncomeByApproveBy:
        'Введіть значення середньої маржі з допів по Апруву (по оформленню):',
      netIncomeByApproveBy: 'Середня маржа з допів по Апруву (по оформленню)',
      chooseEmployeeWithHighestNumberOfErrors:
        'Оберіть операторів з найбільшою кількістю помилок з логів CRM:',
      enterNumberOfErrors: 'Введіть кількість помилок:',
      employeeWithHighestNumberOfErrors:
        'Оператори з найбільшою кількістю помилок з логів CRM',
      chooseEmployeeWithHighestNumberOfInterruptions:
        'Оберіть операторів з найбільшою кількістю обривів:',
      enterNumberOfInterruptions: 'Введіть кількість обривів:',
      employeeWithHighestNumberOfInterruptions:
        'Оператори з найбільшою кількістю обривів',
      enterNumberOfInterview: 'Введіть кількість співбесід:',
      numberOfInterview: 'Кількість співбесід',
      enterNumberOfNewEmployee:
        'Введіть кількість доданих нових операторів за цей день:',
      numberOfNewEmployee: 'Кількість доданих нових операторів за цей день',
      chooseNumberOfEmployeesInTraining:
        'Оберіть операторів на тренінгу (прізвище):',
      numberOfEmployeesInTraining: 'Оператори на тренінгу (прізвище):',
      enterHowManyScriptsHaveBeenWritten:
        'Введіть скільки написано скриптів (назва офферів):',
      enterProductTitle: 'Введіть назву оферу по якому був написаний скрипт:',
      howManyScriptsHaveBeenWritten:
        'Скільки написано скриптів (назва офферів)',
      enterRequiredCalls:
        "Введіть обов'язкові зідзвони: Прослуховування - четвер (9:00), Кз/уз - Четвер (10:00), Входяшка - Четвер 9:30, Старт група - 11:00+/-:",
      requiredCalls:
        "Обов'язкові зідзвони: Прослуховування - четвер (9:00), Кз/уз - Четвер (10:00), Входяшка - Четвер 9:30, Старт група - 11:00+/-",
      enterNumberOfCorrectedOrders:
        'Введіть кількість виправлених замовлень / день (Чат оплати та видалення):',
      numberOfCorrectedOrders:
        'Кількість виправлених замовлень / день (Чат оплати та видалення)',
      enterNumberOfClaimsDrawnUp:
        'Введіть кількість складених претензій (таблиця Входяшка):',
      numberOfClaimsDrawnUp: 'Кількість складених претензій (таблиця Входяшка)',
      chooseWorstEmployeesBasedOnDailyStatistics:
        'Оберіть найгірших операторів за показниками щоденної статистики:',
      worstEmployeesBasedOnDailyStatistics:
        ' Найгірші оператори за показниками щоденної статистики',
      enterNumberOfOrderOutOfStock:
        'Введіть кількість опрацьованих замовлень у статусі "Немає в наявності" (переведення в обробку):',
      numberOfOrderOutOfStock:
        'Кількість опрацьованих замовлень у статусі "Немає в наявності" (переведення в обробку)',
      chartNumberOfOrderOutOfStock:
        'Опрацьованих замовлень у статусі "Немає в наявності"',
      enterNumberOfWrongNumber:
        'Введіть кількість опрацьованих замовлень у статусі "Неправильний номер" (переведення до нових):',
      numberOfWrongNumber:
        'Кількість опрацьованих замовлень у статусі "Неправильний номер" (переведення до нових)',
      chartNumberOfWrongNumber:
        'Опрацьованих замовлень у статусі "Неправильний номер"',
      enterDeparment: 'Введіть відділ, в якому призначено дзвінок:',
      chooseDay: 'Введіть день тижня:',
      chooseTime: 'Оберіть час:',
      chooseEmployeeTwo: 'Оберіть оператора',
      addEmployee: 'Додати оператора',
      deleteEmployee: 'Видалити останнього оператора',
      addNew: 'Додати нове поле',
      deleteLast: 'Видалити останнє поле',
      total: 'Всього:',
      fullName: 'ПІБ',
      totalErrorCounts: 'Усього помилок з логів CRM',
      totalErrorInterruptions: 'Усього обривів',
      totalTraining: 'Всього був на тренінгах',
      totalWorstEmployee: 'Всього був у найгірших операторах',
    },
  ],
};

export const orderReportText = {
  russian: [
    {
      addNewReport: 'Добавить новий отчёт',
      chooseDate: 'Выберите дату:',
      enterReportComment: 'Введите комментарий к отчёту:',
      update: 'Внести изменения',
      delete: 'Удалить запись',
      cancel: 'Отменить',
      save: 'Сохранить',
      date: 'Дата',
      reportComment: 'Комментарий',
      notDataOrError: 'К сожалению у вас пока нет данных с отчётами',
      successSave: 'Запись успешно создана.',
      successUpdate: 'Запись успешно изменена.',
      successRemoved: 'Запись успешно удалена.',
      choosePeriod: 'Выбрать новый период',
      enterNumberOfLeadsByCreation:
        'Введите количество лидов (по оферам, по созданию):',
      enterNumberOfLeadsByApproval:
        'Введите количество лидов (по оферам, по оформлению):',
      enterPercentOfordersNotProcessedOnTime:
        'Введите процент своевременно не обработанных заказов',
      numberOfLeadsByCreation: 'Количество лидов (по оферам, по созданию)',
      numberOfLeadsByApproval: 'Количество лидов (по оферам, по оформлению)',
      percentOfOrdersNotProcessedOnTime:
        'Процент своевременно не обработанных заказов',
      enterNumberOfApproveByCreation:
        'Введите количество апрувов (по оферам, по созданию):',
      enterNumberOfApproveByApproval:
        'Введите количество апрувов (по оферам, по оформлению):',
      numberOfApproveByCreation: 'Количество апрувов (по оферам, по созданию)',
      numberOfApproveByApproval:
        'Количество апрувов (по оферам, по оформлению)',
      enterNumberOfProcessingByCreation:
        'Введите количество в обработке (по оферам, по созданию):',
      enterNumberOfProcessingByApproval:
        'Введите количество в обработке (по оферам, по оформлению):',
      numberOfProcessingByCreation:
        'Количество в обработке (по оферам, по созданию)',
      numberOfProcessingByApproval:
        'Количество в обработке (по оферам, по оформлению)',
      enterNumberOfTrashByCreation:
        'Введите количество треша (по оферам, по созданию):',
      enterNumberOfTrashByApproval:
        'Введите количество треша (по оферам, по оформлению):',
      numberOfTrashByCreation: 'Количество треша (по оферам, по созданию)',
      numberOfTrashByApproval: 'Количество треша (по оферам, по оформлению)',
      enterNumberOfRefusalsByCreation:
        'Введите количество отказов (по оферам, по созданию):',
      enterNumberOfRefusalsByApproval:
        'Введите количество отказов (по оферам, по оформлению):',
      numberOfRefusalsByCreation: 'Количество отказов (по оферам, по созданию)',
      numberOfRefusalsByApproval:
        'Количество отказов (по оферам, по оформлению)',
      enterNumberOfOrdersAcceptedPerHourByApproval:
        'Введите количество принятых заказов в час по оформлению:',
      enterNumberOfOrdersAcceptedPerHourByCreation:
        'Введите количество принятых заказов в час по созданию:',
      numberOfOrdersAcceptedPerHourByApproval:
        'Количество принятых заказов в час по оформлению',
      numberOfOrdersAcceptedPerHourByCreation:
        'Количество принятых заказов в час по созданию',
      enterPercentOfApprovalByOffers: 'Введите процент апрува по офферам:',
      percentOfApprovalByOffers: 'Процент апрува по офферам',
      enterPercentOfApprovalByOffersWithoutTrash:
        'Введите процент апрува по офферам без треша:',
      percentOfApprovalByOffersWithoutTrash:
        'Процент апрува по офферам без треша',
      enterPercentOfUpsells: 'Введите процент допродаж по апруву:',
      percentOfUpsells: 'Процент допродаж по апруву',
      enterPercentOfApproveByEmployeeByCreation:
        'Введите процент апрува по операторам по созданию:',
      percentOfApproveByEmployeeByCreation:
        'Процент апрува по операторам по созданию:',
      enterPercentOfApproveByEmployeeWithoutTrashByCreation:
        'Введите процент апрува без треша по операторам по созданию:',
      percentOfApproveByEmployeeWithoutTrashByCreation:
        'Процент апрува без треша по операторам по созданию',
      enterPercentOfRefusalsWithoutTrash: 'Введите процент отказов без треша:',
      percentOfRefusalsWithoutTrash: 'Процент отказов без треша',
      enterPercentOfProcessingWithoutTrash:
        'Введите процент в обработке без треша:',
      percentOfProcessingWithoutTrash: 'Процент в обработке без треша',
      enterPercentOfTrash: 'Введите процент треша от лидов:',
      percentOfTrash: 'Процент треша от лидов',
      enterPercentOfFreeDelivery: 'Введите процент бесплатных доставок:',
      percentOfFreeDelivery: 'Процент бесплатных доставок',
      enterAverageCheckByApproval: 'Введите значение среднего чека по апруву:',
      averageCheckByApproval: 'Значение среднего чека по апруву',
      enterAverageIncreaseInCheck: 'Введите значение среднего увеличения чека:',
      averageIncreaseInCheck: 'Значение среднего увеличения чека',
    },
  ],
  ukraine: [
    {
      addNewReport: 'Додати новий звіт',
      chooseDate: 'Оберіть дату:',
      enterReportComment: 'Введіть коментар до звіту:',
      update: 'Внести зміни',
      delete: 'Видалити запис',
      cancel: 'Скасувати',
      save: 'Зберегти',
      date: 'Дата',
      reportComment: 'Коментар',
      notDataOrError: 'На жаль у вас поки немає даних зі звітами',
      successSave: 'Запис успішно створено.',
      successUpdate: 'Запис успішно змінено.',
      successRemoved: 'Запис успішно видалено.',
      choosePeriod: 'Обрати новий період',
      enterNumberOfLeadsByCreation:
        'Введіть кількість лідів (за оферами, по створенню):',
      enterNumberOfLeadsByApproval:
        'Введіть кількість лідів (за оферами, по оформленню):',
      enterPercentOfordersNotProcessedOnTime:
        'Введіть відсоток своєчасно не оброблених замовлень',
      numberOfLeadsByCreation: 'Кількість лідів (за оферами, по створенню)',
      numberOfLeadsByApproval: 'Кількість лідів (за оферами, по оформленню)',
      percentOfOrdersNotProcessedOnTime:
        'Відсоток своєчасно не оброблених замовлень',
      enterNumberOfApproveByCreation:
        'Введіть кількість апрувів (за оферами, по створенню):',
      enterNumberOfApproveByApproval:
        'Введіть кількість апрувів (за оферами, по оформленню):',
      numberOfApproveByCreation: 'Кількість апрувів (за оферами, по створенню)',
      numberOfApproveByApproval:
        'Кількість апрувів (за оферами, по оформленню)',
      enterNumberOfProcessingByCreation:
        'Введіть кількість в обробці (за оферами, по створенню):',
      enterNumberOfProcessingByApproval:
        'Введіть кількість в обробці (за оферами, по оформленню):',
      numberOfProcessingByCreation:
        'Кількість в обробці (за оферами, по створенню)',
      numberOfProcessingByApproval:
        'Кількість в обробці (за оферами, по оформленню)',
      enterNumberOfTrashByCreation:
        'Введіть кількість трешу (за оферами, по створенню):',
      enterNumberOfTrashByApproval:
        'Введіть кількість трешу (за оферами, по оформленню):',
      numberOfTrashByCreation: 'Кількість трешу (за оферами, по створенню)',
      numberOfTrashByApproval: 'Кількість трешу (за оферами, по оформленню)',
      enterNumberOfRefusalsByCreation:
        'Введіть кількість відмов (за оферами, по створенню):',
      enterNumberOfRefusalsByApproval:
        'Введіть кількість відмов (за оферами, по оформленню):',
      numberOfRefusalsByCreation:
        'Кількість відмов (за оферами, по створенню)',
      numberOfRefusalsByApproval:
        'Кількість відмов (за оферами, по оформленню)',
      enterNumberOfOrdersAcceptedPerHourByApproval:
        'Введіть кількість прийнятих замовлень за годину по оформленню:',
      enterNumberOfOrdersAcceptedPerHourByCreation:
        'Введіть кількість прийнятих замовлень за годину по створенню:',
      numberOfOrdersAcceptedPerHourByApproval:
        'Кількість прийнятих замовлень за годину по оформленню',
      numberOfOrdersAcceptedPerHourByCreation:
        'Кількість прийнятих замовлень за годину по створенню',
      enterPercentOfApprovalByOffers: 'Введіть відсоток апрува по офферах:',
      percentOfApprovalByOffers: 'Відсоток апрува по офферах',
      enterPercentOfApprovalByOffersWithoutTrash:
        'Введіть відсоток апрува по офферах без трешу:',
      percentOfApprovalByOffersWithoutTrash:
        'Відсоток апрува по офферах без трешу',
      enterPercentOfUpsells: 'Введіть відсоток допродажу по апруву:',
      percentOfUpsells: 'Відсоток допродажу по апруву',
      enterPercentOfApproveByEmployeeByCreation:
        'Введіть відсоток апрува за операторами по створенню:',
      percentOfApproveByEmployeeByCreation:
        'Відсоток апрува за операторами по створенню',
      enterPercentOfApproveByEmployeeWithoutTrashByCreation:
        'Введіть відсоток апрува без трешу за операторами по створенню:',
      percentOfApproveByEmployeeWithoutTrashByCreation:
        'Відсоток апрува без трешу за операторами по створенню',
      enterPercentOfRefusalsWithoutTrash: 'Введіть відсоток відмов без трешу:',
      percentOfRefusalsWithoutTrash: 'Відсоток відмов без трешу',
      enterPercentOfProcessingWithoutTrash:
        'Введіть відсоток в обробці без трешу:',
      percentOfProcessingWithoutTrash: 'Відсоток в обробці без трешу',
      enterPercentOfTrash: 'Введіть відсоток трешу від лідів:',
      percentOfTrash: 'Відсоток трешу від лідів',
      enterPercentOfFreeDelivery: 'Введіть процент безкоштовних доставок:',
      percentOfFreeDelivery: 'Відсоток безкоштовних доставок',
      enterAverageCheckByApproval:
        'Введіть значення середнього чека по апруву:',
      averageCheckByApproval: 'Значення середнього чека по апруву',
      enterAverageIncreaseInCheck:
        'Введіть значення середнього збільшення чека:',
      averageIncreaseInCheck: 'Значення середнього збільшення чека',
    },
  ],
};

export const hoursStatisticText = {
  russian: [
    {
      addNewReport: 'Добавить новий отчёт',
      chooseDate: 'Выберите дату:',
      enterReportComment: 'Введите комментарий к отчёту:',
      update: 'Внести изменения',
      delete: 'Удалить запись',
      cancel: 'Отменить',
      save: 'Сохранить',
      date: 'Дата',
      reportComment: 'Комментарий',
      notDataOrError: 'К сожалению у вас пока нет данных c графиками за выбраный период',
      successSave: 'Запись успешно создана.',
      successUpdate: 'Запись успешно изменена.',
      successRemoved: 'Запись успешно удалена.',
      choosePeriod: 'Выбрать новый период',
    },
  ],
  ukraine: [
    {
      addNewReport: 'Додати новий звіт',
      chooseDate: 'Оберіть дату:',
      enterReportComment: 'Введіть коментар до звіту:',
      update: 'Внести зміни',
      delete: 'Видалити запис',
      cancel: 'Скасувати',
      save: 'Зберегти',
      date: 'Дата',
      reportComment: 'Коментар',
      notDataOrError: 'На жаль у вас поки немає даних з графіками за обраний період',
      successSave: 'Запис успішно створено.',
      successUpdate: 'Запис успішно змінено.',
      successRemoved: 'Запис успішно видалено.',
      choosePeriod: 'Обрати новий період',
    },
  ],
};

export const productsText = {
  russian: [
    {
      addNewProduct: 'Добавить новый товар',
      uploadNewFileWithProduct: 'Загрузить новую таблицу с товарами',
      enterProductTitleRU: "Введите название товара на русском языке:",
      enterProductTitleUA: "Введите название товара на украиснком языке:",
      leftSymbols: 'Осталось символов:',
      enterProductDescribeRU: "Введите описание товара на русском языке:",
      enterProductDescribeUA: "Введите описание товара на украиснком языке:",
      addImage: 'Добавьте фото товара: ',
      addYoutobeURL: 'Добавьте ссылку на обзор товара:',
      update: 'Внести изменения',
      delete: 'Удалить запись',
      cancel: 'Отменить',
      save: 'Сохранить',
      successSave: 'Запись успешно создана.',
      successUpdate: 'Запись успешно изменена.',
      successRemoved: 'Запись успешно удалена.',
      upload: 'Загрузить',
      searchTitle: 'Поиск по названию товара',
      notDataOrError: 'К сожалению у вас пока нет данных c товарами',
      ukraineVersion: 'Украинская версия',
      russianVersion: 'Российская версия',
    },
  ],
  ukraine: [
    {
      addNewProduct: 'Додати новий товар',
      enterProductTitleRU: "Введіть назву товару російською мовою:",
      enterProductTitleUA: "Введіть назву товару українською мовою:",
      leftSymbols: 'Залишилося символів:',
      enterProductDescribeRU: "Введіть опис товару російською мовою:",
      enterProductDescribeUA: "Введіть опис товару українською мовою:",
      addImage: 'Додайте фото товару: ',
      addYoutobeURL: 'Додайте посилання на огляд товару:',
      update: 'Внести зміни',
      delete: 'Видалити запис',
      cancel: 'Скасувати',
      save: 'Зберегти',
      successSave: 'Запис успішно створено.',
      successUpdate: 'Запис успішно змінено.',
      successRemoved: 'Запис успішно видалено.',
      uploadNewFileWithProduct: 'Завантажити нову таблицю з товарами',
      upload: 'Завантажити',
      searchTitle: 'Пошук за назвою товару',
      notDataOrError: 'На жаль у вас поки немає даних з товарами',
      ukraineVersion: 'Українська версія',
      russianVersion: 'Россійська версія',
    },
  ],
};

export const styleText = {
    russian: [
      {
        cancel: 'Отменить',
        addColor: 'Добавить',
        chooseBackgroundColor: 'Выбрать цвет фона:',
        chooseColor: 'Выбрать цвет текста:'
      },
    ],
    ukraine: [
      {
        cancel: 'Скасувати',
        addColor: 'Додати',
        chooseBackgroundColor: 'Обрати колір фону:',
        chooseColor: 'Обрати колір тексту:',
      },
    ],
}

export const claimsControlPanelText = {
  russian: [
    {
      claimsCategory: 'Категории Претензий',
      wirehouse: 'Склады',
      refundStatus: 'Статусы возвратов',
      reasonReturn: 'Причины возврата заказов',
      chooseReturnReasom: 'Выберите причину возврата заказа: ',
      statusFromWarehouse: 'Статусы от склада',
      clientsType: 'Типы клиентов',
      notDataOrError: 'К сожалению, у вас пока нет данных',
      update: 'Внести изменения',
      delete: 'Удалить запись',
      cancel: 'Отменить',
      save: 'Сохранить',
      successSave: 'Запись успешно создана.',
      successUpdate: 'Запись успешно изменена.',
      successRemoved: 'Запись успешно удалена.',
      number: '№',
      city: 'Город',
      status: 'Статус',
      clientConfigured: 'Тип клиента', 
      controlPanel: 'Панель управления',
      addNewCity: 'Добавить новый склад',
      titleUA: 'Введите название на Украинском:',
      titleRU: 'Введите название  на Русском:',
      addNewStatus: 'Добавить новый статус',
      addNewClientConfigured: 'Добавить новый тип клиента',
      category: 'Категория',
      addNewCategory: 'Добавить новую категорию',
      addNewClaim: 'Создать новую претензию',
      enterOrderNumber: 'Введите номер заказа:',
      leftSymbols: 'Осталось символов:',
      enterProductTitle: 'Введите название товара: ',
      claimDescribe: 'Напишите суть претензии: ',
      clientAddress: 'Если клиент хочет обмен, введите адрес клиента: ',
      clientFullName: 'Если клиент хочет обмен, введите ФИО клиента: ',
      clientPhone: 'Если клиент хочет обмен, введите номер телефона клиента: ',
      clientCard: 'Если клиент хочет возврат денег, введите реквизиты клиента: ',
      claimsWarehouse: 'Выберите склад',
      selecteClaimCategory: 'Выберите итог разговора:',
      selecteClientConfigured: 'Выберите тип клиента:',
      checkClient: 'Результат проверки, клиент говорил правду? (Проверка на соответсвие) ',
      refundAddress: 'Адрес для возврата товара Юрченко Денис, отд 2 Одесса +380936562492',
      resultCheck: "Выберите, что нужно сделать по результатам проверки:",
      sendMoneyOrSent: 'Выберите отправлен товар либо вернули деньги клиенту?',
      clientTTN: 'Введите ТТН - возврата (клиент обязан предоставить):',
      refundWasTaken: 'Возврат забрали?',
      ourTTN: 'Введите ТТН по которой отправили посылку:',
      choosePeriod: 'Выбрать новый период',
      clearAllSearchValue: 'Очистить все фильтры поиска',
      enterSearchQuery: 'Введите название офера, адрес клиента или суть претензии:',
      createdDate: 'Дата',
      employeeFullName: 'ФИО кто создал',
      orderId: 'Номер заказа',
      productTitle: 'Название товара',
      claimsDescribe: 'Суть претензии',
      ifClientAddress: 'Адрес клиента',
      clientPhoneNumber: 'Номер телелефона клиента',
      clientFullname: 'ФИО клиента',
      ifCashRefund: 'Номер карты клиента (если хочет возврат денег)',
      callStatus: 'Итог',
      checkClientTrue: 'Клиент говорит правду? (проверка клиента)',
      refundOurAddress: 'Адрес для возврата товара',
      inspectionResults: 'Итог после проверки',
      sendSentOrMoney: 'Отправлено / Деньги вернули ',
      ttnClient: 'ТТН возрата (клиент)',
      redundStatus: 'Статус возврата',
      ttnWarehouse: 'ТТН по которой отправили посылку (склад)',
      clientType: 'Тип клиента',
      chooseManagerTwo: 'Выберите менеджера',
      chooseManagerOne: 'Выберите менеджера:',
      chooseCallResultOne: 'Выберите итоговый статус: ',
      chooseCallResultTwo: 'Выберите итоговый статус',
      chooseClientTypeOne: 'Выберите тип клиента: ',
      chooseClientTypeTwo: 'Выберите тип клиента',
      chooseCallResultOneAfterReCallOne: 'Выберите итоговый статус после проверки: ',
      chooseCallResultOneAfterReCallTwo: 'Выберите итоговый статус после проверки',
      chooseRefundStatusOne: 'Выберите статус возврата: ',
      chooseRefundStatusTwo: 'Выберите статус возврата',
      chooseRefundFromCompanyOne: 'Выберите статус возврата от компании: ',
      chooseRefundFromCompanyTwo: 'Выберите статус возврата от компании',
      chooseWarehouseOne: 'Выберите склад: ',
      chooseWarehouseTwo: 'Выберите склад',
      addNewReport: 'Добавить новый отчет',
      everydayStatistics: 'Ежедневная статистика',
      seeChanges: 'Посмотреть динамику',
      enterOrderProcessed: 'Введите сколько заказов обработано:',
      enterСonsultations: 'Введите сколько предоставлено консультаций: ',
      enterClaimAccepted: 'Введите сколько принято претензий:',
      enterReturnIssued: 'Введите сколько оформлено возврат:',
      enterCompletedExchanges: 'Введите сколько оформлено обменов:',
      enterProblemCustomer: 'Введите сколько проблемных клиентов',
      orderProcessed: 'Обработано',
      consultations: 'Предоставлено консультации',
      claimAccepted: 'Приняты претензии',
      returnIssued: 'Оформлен возврат',
      completedExchanges: 'Оформлено обменов',
      problemCustomer: 'Проблемных клиентов',
      percentConsultations: 'Процент предоставленных консультаций',
      percentClaimAccepted: 'Процент принятых претензий',
      percentReturnIssued: 'Процент оформленных возвратов',
      percentCompletedExchanges: 'Процент оформленных обменов',
      percentProblemCustomer: 'Процент проблемных клиентов',
      oneOrderWorkDuration: 'Среднее время по первому заказу',
      statistics: 'Статистики',
      claimCategoryStatistic: 'По итоговому статусу',
      reasonReturnStatistic: 'По причинам возвратов',
      refundStatusStatistic: 'По статусам возвратов',
      wirehouseCityStatistic: 'По складам',
      verificationStatusStatistic: 'По итогам проверки',
      warehouseStatusStatistic: 'По статусам от склада',
      clientConfiguredStatistic: 'По типам клиентов',
      productStatistic: 'По офферам',
      count: 'Количество',
      comment: 'Комментарий к претензии',
    },
  ],
  ukraine: [
    {
      claimsCategory: 'Категорії Претензій',
      reasonReturn: 'Причини повернень замовлень',
      wirehouse: 'Склади',
      refundStatus: 'Статуси повернень',
      statusFromWarehouse: 'Статуси від складу',
      clientsType: 'Типи клієнтів',
      notDataOrError: 'На жаль у вас поки немає даних',
      update: 'Внести зміни',
      delete: 'Видалити запис',
      cancel: 'Скасувати',
      save: 'Зберегти',
      successSave: 'Запис успішно створено.',
      successUpdate: 'Запис успішно змінено.',
      successRemoved: 'Запис успішно видалено.',
      number: '№',
      city: 'Місто',
      status: 'Статус',
      category: 'Категорія',
      controlPanel: 'Панель управління',
      addNewCity: 'Додати новий склад',
      titleUA: 'Введіть назву українською:',
      titleRU: 'Введіть назву російською:',
      addNewStatus: 'Додати новий статус',
      addNewClientConfigured: 'Додати новий тип клієнта',
      clientConfigured: 'Тип клієнта',
      addNewCategory: 'Додати нову категорію',
      addNewClaim: 'Створити нову претензію',
      enterOrderNumber: 'Введіть номер замовлення:',
      leftSymbols: 'Залишилось символів:',
      enterProductTitle: 'Введіть назву товару: ',
      claimDescribe: 'Напишіть суть претензії: ',
      clientAddress: 'Якщо клієнт хоче обміну, введіть адресу клієнта: ',
      clientFullName: 'Якщо клієнт хоче обмін, введіть ПІБ клієнта: ',
      clientPhone: 'Якщо клієнт хоче обміну, введіть номер телефону клієнта: ',
      clientCard: 'Якщо клієнт хоче повернення грошей, введіть реквізити клієнта: ',
      claimsWarehouse: 'Виберіть склад',
      selecteClaimCategory: 'Виберіть підсумок розмови:',
      selecteClientConfigured: 'Виберіть тип клієнта:',
      checkClient: 'Результат перевірки, клієнт казав правду? (Перевірка на відповідність) ',
      refundAddress: 'Адреса для повернення товару Юрченко Денис, відд 2 Одеса +380936562492',
      resultCheck: "Оберіть, що потрібно зробити за результатами перевірки: ",
      sendMoneyOrSent: 'Виберіть надісланий товар або повернули гроші клієнту?',
      clientTTN: `Введіть ТТН - повернення (клієнт зобов'язаний надати): `,
      refundWasTaken: 'Повернення забрали?',
      ourTTN: 'Введіть ТТН якою відправили посилку:',
      choosePeriod: 'Обрати новий період',
      clearAllSearchValue: 'Очистити всі фільтри пошуку',
      enterSearchQuery: 'Введіть назву офера, адресу клієнта або суть претензії:',
      createdDate: 'Дата',
      employeeFullName: 'ПІБ хто створив',
      orderId: 'Номер замовлення',
      productTitle: 'Назва товару',
      claimsDescribe: 'Суть претензії',
      ifClientAddress: 'Адреса клієнта',
      clientPhoneNumber: 'Номер телефону клієнта',
      clientFullname: 'ПІБ клієнта',
      ifCashRefund: 'Номер картки клієнта (якщо хоче повернення грошей)',
      callStatus: 'Підсумок',
      checkClientTrue: 'Клієнт каже правду? (перевірка клієнта)',
      refundOurAddress: 'Адреса для повернення товару',
      inspectionResults: 'Підсумок після перевірки',
      sendSentOrMoney: 'Відправлено / Гроші повернули ',
      ttnClient: 'ТТН повернення (клієнт)',
      redundStatus: 'Статус повернення',
      ttnWarehouse: 'ТТН за якою відправили посилку (склад)',
      clientType: 'Тип клієнта',
      chooseManagerTwo: 'Оберіть менеджера',
      chooseManagerOne: 'Оберіть менеджера:',
      chooseCallResultOne: 'Виберіть підсумковий статус: ',
      chooseCallResultTwo: 'Виберіть підсумковий статус',
      chooseClientTypeOne: 'Виберіть тип клієнта: ',
      chooseClientTypeTwo: 'Виберіть тип клієнта',
      chooseCallResultOneAfterReCallOne: 'Виберіть підсумковий статус після перевірки: ',
      chooseCallResultOneAfterReCallTwo: 'Виберіть підсумковий статус після перевірки',
      chooseRefundStatusOne: 'Виберіть статус повернення:',
      chooseRefundStatusTwo: 'Виберіть статус повернення',
      chooseRefundFromCompanyOne: 'Виберіть статус повернення від компанії:',
      chooseRefundFromCompanyTwo: 'Виберіть статус повернення від компанії',
      chooseWarehouseOne: 'Виберіть склад:',
      chooseWarehouseTwo: 'Виберіть склад',
      chooseReturnReasom: 'Виберіть причину повернення замовлення: ',
      addNewReport: 'Додати новий звіт',
      everydayStatistics: 'Щодена статистика',
      seeChanges: 'Подивитись динаміку',
      enterOrderProcessed: 'Введіть скільки заказів оброблено: ',
      enterСonsultations: 'Введіть скільки надано консультацій: ',
      enterClaimAccepted: 'Введіть скільки прийнято претензій: ',
      enterReturnIssued: 'Введіть скільки оформлено повернення: ',
      enterCompletedExchanges: 'Введіть скільки оформлено обмінів: ',
      enterProblemCustomer: 'Введіть скільки проблемних клієнтів',
      orderProcessed: 'Оброблено',
      consultations: 'Надано консультацій',
      claimAccepted: 'Прийнято претензій',
      returnIssued: 'Оформлено повернення',
      completedExchanges: 'Оформлено обмінів',
      problemCustomer: 'Проблемних клієнтів',
      percentConsultations: 'Відсоток наданих консультацій',
      percentClaimAccepted: 'Відсоток прийнятих претензій',
      percentReturnIssued: 'Відсоток оформлених поверненнь',
      percentCompletedExchanges: 'Відсоток оформлено обмінів',
      percentProblemCustomer: 'Відсоток проблемних клієнтів',
      oneOrderWorkDuration: 'Середній час на 1-е замовлення',
      claimCategoryStatistic: 'За підсумковим статусом',
      reasonReturnStatistic: 'За причинами повернення',
      refundStatusStatistic: 'За статусами повернень',
      wirehouseCityStatistic: 'За складами',
      verificationStatusStatistic: 'За підсумками перевірки',
      warehouseStatusStatistic: 'За статусами від складу',
      clientConfiguredStatistic: 'За типами клієнтів',
      productStatistic: 'За офферами',
      count: 'Кількість',
  comment: 'Коментар до претензії',
    },
  ],
}

export const knowledgeBaseText = {
  russian: [
    {
      addNewProduct: 'Добавить новый документ',
      enterProductTitleRU: "Введите название на русском языке:",
      enterProductTitleUA: "Введите название на украиснком языке:",
      leftSymbols: 'Осталось символов:',
      enterProductDescribeRU: "Введите описание на русском языке:",
      enterProductDescribeUA: "Введите описание на украиснком языке:",
      update: 'Внести изменения',
      delete: 'Удалить запись',
      cancel: 'Отменить',
      save: 'Сохранить',
      successSave: 'Запись успешно создана.',
      successUpdate: 'Запись успешно изменена.',
      successRemoved: 'Запись успешно удалена.',
      upload: 'Загрузить',
      searchTitle: 'Поиск по названию',
      notDataOrError: 'К сожалению у вас пока нет данных',
      ukraineVersion: 'Украинская версия',
      russianVersion: 'Российская версия',
    },
  ],
  ukraine: [
    {
      addNewProduct: 'Додати новий документ',
      enterProductTitleRU: "Введіть назву російською мовою:",
      enterProductTitleUA: "Введіть назву українською мовою:",
      leftSymbols: 'Залишилося символів:',
      enterProductDescribeRU: "Введіть опис російською мовою:",
      enterProductDescribeUA: "Введіть опис українською мовою:",
      update: 'Внести зміни',
      delete: 'Видалити запис',
      cancel: 'Скасувати',
      save: 'Зберегти',
      successSave: 'Запис успішно створено.',
      successUpdate: 'Запис успішно змінено.',
      successRemoved: 'Запис успішно видалено.',
      upload: 'Завантажити',
      searchTitle: 'Пошук за назвою',
      notDataOrError: 'На жаль у вас поки немає даних',
      ukraineVersion: 'Українська версія',
      russianVersion: 'Россійська версія',
    },
  ],
}

export const scriptText = {
  russian: [
    {
      addNewProduct: 'Добавить новый',
      chooseCategory: 'Выберите категорию: ',
      enterProductTitleRU: "Введите название на русском языке:",
      enterProductTitleUA: "Введите название на украиснком языке:",
      leftSymbols: 'Осталось символов:',
      enterProductDescribeRU: "Введите описание на русском языке:",
      enterProductDescribeUA: "Введите описание на украиснком языке:",
      update: 'Внести изменения',
      delete: 'Удалить запись',
      cancel: 'Отменить',
      save: 'Сохранить',
      successSave: 'Запись успешно создана.',
      successUpdate: 'Запись успешно изменена.',
      successRemoved: 'Запись успешно удалена.',
      upload: 'Загрузить',
      searchTitle: 'Поиск по названию',
      notDataOrError: 'К сожалению у вас пока нет данных',
      ukraineVersion: 'Украинская версия',
      russianVersion: 'Российская версия',
      addNewTemplate: 'Добавить новый',
      templateList: 'Список шаблонов',
      categoryDisplay: 'Список категорий',
      number: '№',
      categoryTitle: 'Название категории',
      controlPanel: "Отображение в скрипте"
    },
  ],
  ukraine: [
    {
      addNewProduct: 'Додати новий',
      chooseCategory: 'Оберіть категорію: ',
      enterProductTitleRU: "Введіть назву російською мовою:",
      enterProductTitleUA: "Введіть назву українською мовою:",
      leftSymbols: 'Залишилося символів:',
      enterProductDescribeRU: "Введіть опис російською мовою:",
      enterProductDescribeUA: "Введіть опис українською мовою:",
      update: 'Внести зміни',
      delete: 'Видалити запис',
      cancel: 'Скасувати',
      save: 'Зберегти',
      successSave: 'Запис успішно створено.',
      successUpdate: 'Запис успішно змінено.',
      successRemoved: 'Запис успішно видалено.',
      upload: 'Завантажити',
      searchTitle: 'Пошук за назвою',
      notDataOrError: 'На жаль у вас поки немає даних',
      ukraineVersion: 'Українська версія',
      russianVersion: 'Россійська версія',
      addNewTemplate: 'Додати новий',
      templateList: 'Список шаблонів',
      categoryDisplay: 'Список категорій',
      number: '№',
      categoryTitle: 'Назва категорії',
      controlPanel: "Відображення у скрипті"
    },
  ],
}

export const onlineUsersText = {
  russian: [
    {
      fullName: 'ФИО',
      status: 'Статус',
      lastSeen: 'Был в онлайне в'
    },
  ],
  ukraine: [
    {
      fullName: 'ПІБ',
      status: 'Статус',
      lastSeen: 'Був в  онлайні в'
    },
  ],
}