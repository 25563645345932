import { wiretappingCheckList } from "../../../services/componentText";
import React, { useContext, useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { UserContext } from "../../Context/UserContext";
import {
  fetchOrderStatus,
  createCheckList,
  updateCheckList,
  fetchCheckList,
} from "../../../api/wiretapping";
import { useQuery } from "react-query";
import { fetchUsers } from "../../../api/users";
import { fetchFinesList } from "../../../api/finesList";
import {
  ListContainer,
  ItemContainer,
  CheckListInput,
  CheckListLabel,
  AddedCheckListSelect,
  AddedCheckListOption,
  CheckListCommentContainer,
  CheckListTextArea,
  SaveButton,
  ButtonContainer,
  DeleteReportButton,
  UploadFileContainer,
  CheckListImage,
  UploadAudioFileContainer,
  FaudioIconContainer,
  AudioText,
  UploadAudioTitle,
  CheckListFinesStatusContainer,
  CheckListFinesCategoryContainer,
  AddedCheckListSelectSmall,
} from "./CheckListItem.styled";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlinePlus } from "react-icons/ai";
import ReactH5AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { FaFileAudio } from "react-icons/fa";
import { fetchFineStatuses } from "../../../api/fines";
import { Main } from "../../Main/Main";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { format } from "date-fns";

const createImageURL = (imageData) => {
  const binaryData = new Uint8Array(imageData?.data);
  const blob = new Blob([binaryData], { type: `image/${imageData?.type}` });
  return URL.createObjectURL(blob);
};

const checkValue = (fieldName, value) => {
  if (value !== null && Number(value) === 1) {
    return 1;
  } else if (value !== null && Number(value) === 0) {
    return "0";
  } else {
    return value;
  }
};

export const CheckListItem = () => {
  const { currentUser, language } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [checkList, setCheckList] = useState({
    id: "",
    order_id: null,
    product_title: "",
    employee_full_name: null,
    employee_create_full_name: currentUser?.full_name,
    status_message_id: 1,
    status_id: null,
    is_greeting: null,
    is_identifying_needs: null,
    is_presentation: null,
    is_offer_of_kits: null,
    is_offering_an_improved_version: null,
    is_crosssail_offer: null,
    is_the_client_immediately_agreed: null,
    is_handling_objections: null,
    is_undercharacterization: null,
    are_characteristics_stated_correctly: null,
    is_address_verification: null,
    is_address_correct: null,
    is_np_department_verification: null,
    is_np_department_correct: null,
    is_order_list_verification: null,
    is_order_list_correct: null,
    is_total_order_amount_verification: null,
    is_total_order_amount_correct: null,
    is_plus_delivery_amount: null,
    is_order_approved_by_client: null,
    is_motivation_for_ransom: null,
    is_hundred_percent_cashback: null,
    is_order_status_correct: null,
    is_call_time_exceeded: null,
    is_third_party_verification: null,
    is_calling_back_third_party: null,
    is_conversation_reset: null,
    is_try_call_back: null,
    is_operator_handle_objection_to_save: null,
    is_full_name_verification: null,
    is_full_name_correct: null,
    fine_amount: 0,
    check_list_comment: null,
    is_third_party_correct: null,
    is_operator_speak_correctly: null,
    is_comment: null,
    is_proposal_clubcard: null,
    is_correct_claim_processing: null,
    is_correct_insert_into_black_list: null,
    is_empty_order_drain: null,
    url_image_1: null,
    url_record_1: null,
    url_record_2: null,
    url_record_3: null,
    url_record_4: null,
    url_record_5: null,
    fines_status_id: 4,
    fines_category: [],
  });
  const isCreateNewCheckList =
    location?.pathname === "/wiretapping/new-check-list";

  const [selectedImage, setSelectedImage] = useState(null);
  const maxLength = 250;
  const currentDay = new Date();
  const formattedCurrentDate = format(currentDay, "yyyy.MM.dd");

  const { data: orderStatusData } = useQuery("orderStatus", fetchOrderStatus);

  const { data: usersData } = useQuery(["getAllEmployees"], () =>
    fetchUsers({ sortBy: "full_name", order: "ASC", isBlocked: "0" })
  );

  const { data: finesListData } = useQuery("finesListData", fetchFinesList);

  const { data: finesStatusData } = useQuery(
    "finesStatusData",
    fetchFineStatuses
  );

  const handleFinesAmount = async (fineStatusId, changeValue, newCheckList) => {
    const updatedFinesCategory = [...checkList.fines_category];
    const findUser = usersData?.usersData?.filter(
      (user) => user.full_name === checkList?.employee_full_name
    );
    let differenceDays = null;
    let updatedCheckList = {};

    if (findUser && findUser[0]?.createdAt) {
      const userCreationDate = new Date(findUser[0]?.createdAt);
      const differenceTime = Math.abs(currentDay - userCreationDate);
      differenceDays = Math.ceil(differenceTime / (1000 * 60 * 60 * 24));
    }

    if (Number(changeValue) === 0) {
      const valuesToAdd = finesListData?.filter(
        (item) => item.id === fineStatusId
      );

      valuesToAdd.forEach(async (value) => {
        if (!updatedFinesCategory.some((item) => item.id === value.id)) {
          updatedFinesCategory.push(value);

          updatedCheckList = { ...newCheckList };
          if (differenceDays > 30) {
            const response = await fetchCheckList({
              startDate: formattedCurrentDate,
              endDate: formattedCurrentDate,
              employee_full_name: findUser[0]?.full_name,
            });

            if (response?.status !== 200) {
              return;
            }

            const fetchData = response?.data?.data;

            if (!fetchData || fetchData.length === 0) {
              updatedCheckList = {
                ...newCheckList,
                fine_amount:
                  (newCheckList.fine_amount || 0) + value.fine_amount,
                fines_status_id: 1,
                fines_category: [...updatedFinesCategory],
              };
            } else {
              try {
                const checkId = fetchData.filter(
                  (item) => item.id !== checkList.id
                );
                // const statusMatchesCount = checkId.reduce((count, item) => {
                //   const finesCategory = JSON.parse(item.fines_category || '[]');
                //   const matchingStatuses = finesCategory?.filter(status => status?.id === fineStatusId);
                //   return count + matchingStatuses.length;
                // }, 0);

                // if (statusMatchesCount > 0) {
                //   updatedCheckList = {
                //     ...newCheckList,
                //     fine_amount: (newCheckList.fine_amount || 0) + 0,
                //     fines_status_id: 1,
                //     fines_category: [...updatedFinesCategory],
                //   };
                // } else {
                updatedCheckList = {
                  ...newCheckList,
                  fine_amount: Number(
                    (newCheckList.fine_amount || 0) + value.fine_amount
                  ),
                  fines_status_id: 1,
                  fines_category: [...updatedFinesCategory],
                };
                // }
              } catch (error) {
                updatedCheckList = {
                  ...newCheckList,
                  fine_amount: Number(
                    (newCheckList.fine_amount || 0) + value.fine_amount
                  ),
                  fines_status_id: 1,
                  fines_category: [...updatedFinesCategory],
                };
              }
            }

            setCheckList(updatedCheckList);
          }

          if (differenceDays < 30) {
            updatedCheckList = {
              ...newCheckList,
              fines_status_id: 2,
              fines_category: [...updatedFinesCategory],
            };
          }

          return setCheckList(updatedCheckList);
        }

        return setCheckList(newCheckList);
      });
    } else {
      const valuesToRemove = updatedFinesCategory?.filter(
        (item) => item?.id === fineStatusId
      );
      let finesStatus = checkList.fines_status_id;

      const numZerosMotivation = [
        newCheckList.is_address_verification,
        newCheckList.is_np_department_verification,
        newCheckList.is_order_list_verification,
        newCheckList.is_total_order_amount_verification,
        newCheckList.is_plus_delivery_amount,
        newCheckList.is_order_approved_by_client,
        newCheckList.is_motivation_for_ransom,
        newCheckList.is_full_name_verification,
      ].reduce((acc, value) => {
        return value === 0 || value === "0" ? acc + 1 : acc;
      }, 0);

      const numZerosDelivery = [
        newCheckList.is_np_department_correct,
        newCheckList.isAddressCorrect,
      ].reduce((acc, value) => {
        return value === 0 || value === "0" ? acc + 1 : acc;
      }, 0);

      const numZerosOffer = [
        newCheckList.is_crosssail_offer,
        newCheckList.is_offering_an_improved_version,
      ].reduce((acc, value) => {
        return value === 0 || value === "0" ? acc + 1 : acc;
      }, 0);

      if (valuesToRemove?.length === 0) {
        const updatedCheckList = {
          ...newCheckList,
        };
        setCheckList(updatedCheckList);
      }

      if (valuesToRemove?.length > 0) {
        if (fineStatusId === 9 && numZerosMotivation >= 1) {
          const updatedCheckList = {
            ...newCheckList,
          };
          return setCheckList(updatedCheckList);
        }

        if (fineStatusId === 11 && numZerosDelivery >= 1) {
          const updatedCheckList = {
            ...newCheckList,
          };
          return setCheckList(updatedCheckList);
        }

        if (fineStatusId === 21 && numZerosOffer >= 1) {
          const updatedCheckList = {
            ...newCheckList,
          };
          return setCheckList(updatedCheckList);
        }

        valuesToRemove.forEach(async (value) => {
          const index = updatedFinesCategory.findIndex(
            (item) => item.id === value.id
          );

          if (index !== -1) {
            updatedFinesCategory.splice(index, 1);
          }

          if (updatedFinesCategory?.length === 0) {
            finesStatus = 4;
          }

          const response = await fetchCheckList({
            startDate: formattedCurrentDate,
            endDate: formattedCurrentDate,
            employee_full_name: findUser[0]?.full_name,
          });

          if (response.status !== 200) {
            return;
          }

          const fetchData = response?.data?.data;

          if (!fetchData || fetchData.length === 0) {
            const updatedCheckList = {
              ...newCheckList,
              fine_amount: Math.max(
                newCheckList?.fine_amount -
                  (valuesToRemove[0]?.fine_amount || 0),
                0
              ),
              fines_category: [...updatedFinesCategory],
              fines_status_id: finesStatus,
            };
            setCheckList(updatedCheckList);
            return;
          }

          // const checkId = fetchData.filter(item => item.id !== checkList.id);
          // const statusMatchesCount = checkId.reduce((count, item) => {
          //   const finesCategory = JSON.parse(item.fines_category || '[]');
          //   const matchingStatuses = finesCategory.filter(status => status?.id === fineStatusId);
          //   return count + matchingStatuses.length;
          // }, 0);

          // if (statusMatchesCount > 0) {
          //   const updatedCheckList = {
          //     ...newCheckList,
          //     fine_amount: Number(newCheckList?.fine_amount -  0),
          //     fines_category: [...updatedFinesCategory],
          //     fines_status_id: finesStatus,
          //   };
          //   setCheckList(updatedCheckList);
          //   return
          // }

          const updatedCheckList = {
            ...newCheckList,
            fine_amount: Math.max(
              newCheckList?.fine_amount - (valuesToRemove[0]?.fine_amount || 0),
              0
            ),
            fines_category: [...updatedFinesCategory],
            fines_status_id: finesStatus,
          };

          setCheckList(updatedCheckList);
        });
      }
    }
  };

  const handleFileChange = (fieldName, files) => {
    setCheckList({
      ...checkList,
      [fieldName]: files,
    });
  };

  const handleImageFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      const updatedCheckList = {
        ...checkList,
        url_image_1: file,
      };
      setCheckList(updatedCheckList);

      reader.onload = (readerEvent) => {
        setSelectedImage(readerEvent.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    if (selectedImage) {
      document.getElementById("wiretappingImageInput").click();
    }
  };

  const audioPlayers = useRef([1, 2, 3, 4, 5].map(() => React.createRef()));

  useEffect(() => {
    const fetchCheckListData = async () => {
      if (isCreateNewCheckList) {
        return;
      }

      const checkListIdFromPath = location?.pathname?.replace(
        /^\/wiretapping\/change-check-list\//,
        ""
      );

      const response = await fetchCheckList({ id: checkListIdFromPath });

      if (response?.status === 200) {
        const fetchData = response?.data?.data;

        try {
          const checkData =
            fetchData[0]?.fines_category !== undefined
              ? fetchData[0]?.fines_category
              : "[]";
          const checkImage =
            fetchData[0]?.url_image_1 !== undefined
              ? createImageURL(fetchData[0]?.url_image_1)
              : null;
          const parsedData = JSON.parse(checkData);
          const newData = {};
          Object.entries(fetchData[0]).forEach(([fieldName, value]) => {
            newData[fieldName] = checkValue(fieldName, value);
          });
          setCheckList({
            ...newData,
            fines_category: [...parsedData],
            status_message_id: 1,
          });
          setSelectedImage(checkImage);
        } catch (error) {
          setCheckList({
            ...fetchData[0],
            fines_category: [],
          });
          const checkImage =
            fetchData[0]?.url_image_1 !== undefined
              ? createImageURL(fetchData[0]?.url_image_1)
              : null;
          setSelectedImage(checkImage);
        }
      }
    };

    fetchCheckListData();
  }, [location, isCreateNewCheckList]);

  const createAudioURL = (audioData) => {
    if (audioData === null) {
      return "";
    }

    if (audioData && audioData.data && audioData?.type !== null) {
      const binaryData = new Uint8Array(audioData.data);
      const blob = new Blob([binaryData], {
        type: `audio/${audioData?.type ? audioData?.type : "mp3"}`,
      });
      return URL.createObjectURL(blob);
    }

    if (
      typeof audioData === "object" &&
      audioData?.type === undefined &&
      audioData?.data === undefined
    ) {
      return URL.createObjectURL(audioData[0]);
    }
  };

  const handleSubmit = async (checkList) => {
    const formData = new FormData();

    Object.entries(checkList).forEach(([key, value]) => {
      if (key === "url_image_1" || key.startsWith("url_record_")) {
        if (value instanceof FileList) {
          for (let i = 0; i < value?.length; i++) {
            formData.append(key, value[i]);
          }
        } else if (value instanceof File) {
          formData.append(key, value);
        }
      } else if (key.toLowerCase() === "fines_category") {
        const finesCategory = Array.isArray(value) ? value : [];
        formData.append(key, JSON.stringify(finesCategory));
      } else if (
        value !== null &&
        value !== "Без змін" &&
        value !== "Без изменений"
      ) {
        formData.append(key, value);
      }
    });

    if (isCreateNewCheckList) {
      const response = await createCheckList(formData);

      if (response?.status === 201) {
        toast.success(wiretappingCheckList[language][0]?.successSave);
        return navigate("/wiretapping/check-list");
      }
    }

    if (!isCreateNewCheckList) {
      const response = await updateCheckList(formData);

      if (response?.status === 201) {
        toast.success(wiretappingCheckList[language][0]?.successUpdate);
        return navigate("/wiretapping/check-list");
      }
    }
  };

  const handleCancelClick = () => {
    return navigate("/wiretapping/check-list");
  };

  return (
    <Main>
      <ListContainer>
        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.enterOrderNumber}

            <CheckListInput
              type="number"
              placeholder={wiretappingCheckList[language][0]?.enterOrderNumber}
              value={checkList?.order_id || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  order_id: e.target.value,
                };
                setCheckList(updatedCheckList);
              }}
            />
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.orderStatus}

            <AddedCheckListSelect
              value={checkList?.status_id || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  status_id: e.target.value,
                };
                setCheckList(updatedCheckList);
              }}
              style={{ backgroundColor: "#f5f7fa" }}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.orderStatus}
              </AddedCheckListOption>
              {orderStatusData?.map((status) => (
                <AddedCheckListOption value={status?.id} key={status?.id}>
                  {language === "russian"
                    ? status?.status_title_ru
                    : status?.status_title_ua}
                </AddedCheckListOption>
              ))}
            </AddedCheckListSelect>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.enterProductTitle}

            <div style={{ width: "60%" }}>
              <CheckListInput
                style={{ width: "95%" }}
                value={checkList?.product_title}
                onChange={(e) => {
                  const updatedCheckList = {
                    ...checkList,
                    product_title: e.target.value,
                  };
                  if (updatedCheckList?.product_title?.length <= maxLength) {
                    setCheckList(updatedCheckList);
                  }
                }}
                placeholder={
                  wiretappingCheckList[language][0]?.enterProductTitle
                }
                maxLength={maxLength}
                required
              />
              <p style={{ margin: "0" }}>
                {wiretappingCheckList[language][0]?.leftSymbols}{" "}
                {maxLength - checkList?.product_title?.length}/{maxLength}
              </p>
            </div>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.chooseEmployee}

            <AddedCheckListSelect
              value={checkList?.employee_full_name || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  employee_full_name: e.target.value,
                };
                setCheckList(updatedCheckList);
              }}
              style={{ backgroundColor: "#f5f7fa" }}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.chooseEmployee}
              </AddedCheckListOption>
              {usersData?.usersData?.map(
                (user) =>
                  (Number(user?.id_job_title) === 3  ||
                    Number(user?.id_job_title) === 5 ||
                    Number(user?.id_job_title) === 9 ||
                    Number(user?.id_job_title) === 10 ||
                    Number(user?.id_job_title) === 11 ||
                    Number(user?.id_job_title) === 12 ||
                    Number(user?.id_job_title) === 13) && (
                    <AddedCheckListOption
                      value={user?.full_name}
                      key={user?.full_name}
                    >
                      {user?.full_name}
                    </AddedCheckListOption>
                  )
              )}
            </AddedCheckListSelect>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isGreeting}
            <AddedCheckListSelectSmall
              value={checkList?.is_greeting || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_greeting: e.target.value,
                };
                setCheckList(updatedCheckList);
              }}
              backgroundcolor={checkList?.is_greeting}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isIdentifyingNeeds}
            <AddedCheckListSelectSmall
              value={checkList?.is_identifying_needs || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_identifying_needs: e.target.value,
                };
                setCheckList(updatedCheckList);
              }}
              backgroundcolor={checkList?.is_identifying_needs}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isPresentation}
            <AddedCheckListSelectSmall
              value={checkList?.is_presentation || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_presentation: e.target.value,
                };
                setCheckList(updatedCheckList);
              }}
              backgroundcolor={checkList?.is_presentation}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isOfferOfKits}
            <AddedCheckListSelectSmall
              value={checkList?.is_offer_of_kits || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_offer_of_kits: e.target.value,
                };
                setCheckList(updatedCheckList);
              }}
              backgroundcolor={checkList?.is_offer_of_kits}
              style={{ width: "170px" }}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isOfferingAnImprovedVersion}

            <AddedCheckListSelectSmall
              value={checkList?.is_offering_an_improved_version || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_offering_an_improved_version: e.target.value,
                };
                handleFinesAmount(21, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_offering_an_improved_version}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isCrossailOffer}
            <AddedCheckListSelectSmall
              value={checkList?.is_crosssail_offer || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_crosssail_offer: e.target.value,
                };
                handleFinesAmount(21, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_crosssail_offer}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isTheClientImmediatelyAgreed}
            <AddedCheckListSelectSmall
              value={checkList?.is_the_client_immediately_agreed || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_the_client_immediately_agreed: e.target.value,
                };
                setCheckList(updatedCheckList);
              }}
              backgroundcolor={checkList?.is_the_client_immediately_agreed}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isHandlingObjections}

            <AddedCheckListSelectSmall
              value={checkList?.is_handling_objections || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_handling_objections: e.target.value,
                };
                handleFinesAmount(14, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_handling_objections}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isUndercharacterization}

            <AddedCheckListSelectSmall
              value={checkList?.is_undercharacterization || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_undercharacterization: e.target.value,
                };
                handleFinesAmount(25, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_undercharacterization}
              style={{ width: "140px" }}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {
              wiretappingCheckList[language][0]
                ?.areCharacteristicsStatedCorrectly
            }

            <AddedCheckListSelectSmall
              value={checkList?.are_characteristics_stated_correctly || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  are_characteristics_stated_correctly: e.target.value,
                };
                handleFinesAmount(19, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.are_characteristics_stated_correctly}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isFullNameVerification}

            <AddedCheckListSelectSmall
              value={checkList?.is_full_name_verification || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_full_name_verification: e.target.value,
                };
                handleFinesAmount(9, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_full_name_verification}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isFullNameCorrect}

            <AddedCheckListSelectSmall
              value={checkList?.is_full_name_correct || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_full_name_correct: e.target.value,
                };
                handleFinesAmount(10, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_full_name_correct}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isAddressVerification}

            <AddedCheckListSelectSmall
              value={checkList?.is_address_verification || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_address_verification: e.target.value,
                };
                handleFinesAmount(9, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_address_verification}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isAddressCorrect}

            <AddedCheckListSelectSmall
              value={checkList?.is_address_correct || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_address_correct: e.target.value,
                };
                handleFinesAmount(11, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_address_correct}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isNpDepartmentVerification}

            <AddedCheckListSelectSmall
              value={checkList?.is_np_department_verification || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_np_department_verification: e.target.value,
                };
                handleFinesAmount(9, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_np_department_verification}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isNpDepartmentCorrect}

            <AddedCheckListSelectSmall
              value={checkList?.is_np_department_correct || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_np_department_correct: e.target.value,
                };
                handleFinesAmount(11, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_np_department_correct}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isOrderListVerification}

            <AddedCheckListSelectSmall
              value={checkList?.is_order_list_verification || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_order_list_verification: e.target.value,
                };
                handleFinesAmount(9, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_order_list_verification}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isOrderListCorrect}

            <AddedCheckListSelectSmall
              value={checkList?.is_order_list_correct || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_order_list_correct: e.target.value,
                };
                handleFinesAmount(12, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_order_list_correct}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isTotalOrderAmountVerification}

            <AddedCheckListSelectSmall
              value={checkList?.is_total_order_amount_verification || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_total_order_amount_verification: e.target.value,
                };
                handleFinesAmount(9, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_total_order_amount_verification}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isTotalOrderAmountCorrect}
            <AddedCheckListSelectSmall
              value={checkList?.is_total_order_amount_correct || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_total_order_amount_correct: e.target.value,
                };
                handleFinesAmount(13, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_total_order_amount_correct}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isPlusDeliveryAmount}
            <AddedCheckListSelectSmall
              value={checkList?.is_plus_delivery_amount || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_plus_delivery_amount: e.target.value,
                };
                handleFinesAmount(9, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_plus_delivery_amount}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isOrderApprovedByClient}
            <AddedCheckListSelectSmall
              value={checkList?.is_order_approved_by_client || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_order_approved_by_client: e.target.value,
                };
                handleFinesAmount(9, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_order_approved_by_client}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isMotivationForRansom}

            <AddedCheckListSelectSmall
              value={checkList?.is_motivation_for_ransom || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_motivation_for_ransom: e.target.value,
                };
                handleFinesAmount(9, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_motivation_for_ransom}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isHundredPercentCashback}
            <AddedCheckListSelectSmall
              value={checkList?.is_hundred_percent_cashback || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_hundred_percent_cashback: e.target.value,
                };
                handleFinesAmount(26, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_hundred_percent_cashback}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isOrderStatusCorrect}
            <AddedCheckListSelectSmall
              value={checkList?.is_order_status_correct || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_order_status_correct: e.target.value,
                };
                handleFinesAmount(15, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_order_status_correct}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isCallTimeExceeded}
            <AddedCheckListSelectSmall
              value={checkList?.is_call_time_exceeded || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_call_time_exceeded: e.target.value,
                };
                handleFinesAmount(20, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_call_time_exceeded}
            >
              <AddedCheckListOption value={2} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isThirdPartyVerification}

            <AddedCheckListSelectSmall
              value={checkList?.is_third_party_verification || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_third_party_verification: e.target.value,
                };
                setCheckList(updatedCheckList);
              }}
              backgroundcolor={checkList?.is_third_party_verification}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isThirdPartyCorrect}

            <AddedCheckListSelectSmall
              value={checkList?.is_third_party_correct || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_third_party_correct: e.target.value,
                };
                setCheckList(updatedCheckList);
              }}
              backgroundcolor={checkList?.is_third_party_correct}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isCallingBackThirdParty}

            <AddedCheckListSelectSmall
              value={checkList?.is_calling_back_third_party || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_calling_back_third_party: e.target.value,
                };
                setCheckList(updatedCheckList);
              }}
              backgroundcolor={checkList?.is_calling_back_third_party}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isConversationReset}

            <AddedCheckListSelectSmall
              value={checkList?.is_conversation_reset || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_conversation_reset: e.target.value,
                };
                setCheckList(updatedCheckList);
              }}
              backgroundcolor={checkList?.is_conversation_reset}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isOperatorHandleObjectionToSave}
            <AddedCheckListSelectSmall
              value={checkList?.is_operator_handle_objection_to_save || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_operator_handle_objection_to_save: e.target.value,
                };
                handleFinesAmount(23, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_operator_handle_objection_to_save}
              style={{ width: "200px" }}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isTryCallBack}
            <AddedCheckListSelectSmall
              value={checkList?.is_try_call_back || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_try_call_back: e.target.value,
                };
                setCheckList(updatedCheckList);
              }}
              backgroundcolor={checkList?.is_try_call_back}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isOperatorSpeakCorrectly}

            <AddedCheckListSelectSmall
              value={checkList?.is_operator_speak_correctly || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_operator_speak_correctly: e.target.value,
                };
                handleFinesAmount(8, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_operator_speak_correctly}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isComment}

            <AddedCheckListSelectSmall
              value={checkList?.is_comment || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_comment: e.target.value,
                };
                handleFinesAmount(27, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_comment}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isProposalClubCard}

            <AddedCheckListSelectSmall
              value={checkList?.is_proposal_clubcard || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_proposal_clubcard: e.target.value,
                };
                handleFinesAmount(28, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_proposal_clubcard}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isCorrectClaimProcessing}

            <AddedCheckListSelectSmall
              value={checkList?.is_correct_claim_processing || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_correct_claim_processing: e.target.value,
                };
                handleFinesAmount(29, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_correct_claim_processing}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isCorrectInsertIntoBlackList}

            <AddedCheckListSelectSmall
              value={checkList?.is_correct_insert_into_black_list || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_correct_insert_into_black_list: e.target.value,
                };
                handleFinesAmount(31, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_correct_insert_into_black_list}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <ItemContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.isEmptyOrderDrain}

            <AddedCheckListSelectSmall
              value={checkList?.is_empty_order_drain || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  is_empty_order_drain: e.target.value,
                };
                handleFinesAmount(32, e.target.value, updatedCheckList);
              }}
              backgroundcolor={checkList?.is_empty_order_drain}
            >
              <AddedCheckListOption value={null} key={0}>
                {wiretappingCheckList[language][0]?.notChange}
              </AddedCheckListOption>
              <AddedCheckListOption value={1} key={1}>
                {wiretappingCheckList[language][0]?.yes}
              </AddedCheckListOption>
              <AddedCheckListOption value={0} key={2}>
                {wiretappingCheckList[language][0]?.no}
              </AddedCheckListOption>
            </AddedCheckListSelectSmall>
          </CheckListLabel>
        </ItemContainer>

        <CheckListCommentContainer>
          <UploadAudioTitle>
            {wiretappingCheckList[language][0]?.addAudioFile}
          </UploadAudioTitle>
          <div>
            {[1, 2, 3, 4, 5].map((index) => {
              return (
                <UploadAudioFileContainer
                  key={index}
                  style={{ position: "relative", marginBottom: "10px" }}
                >
                  {!checkList[`url_record_${index}`]?.length &&
                  !checkList[`url_record_${index}`]?.type ? (
                    <FaudioIconContainer>
                      <AiOutlinePlus
                        style={{
                          cursor: "pointer",
                          fontSize: "24px",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        onClick={() =>
                          document.getElementById(`audioInput_${index}`).click()
                        }
                        size={"80px"}
                        fill="#5cbcf6"
                      />
                    </FaudioIconContainer>
                  ) : (
                    <>
                      <div style={{ display: "flex", height: "100px" }}>
                        <FaudioIconContainer>
                          <FaFileAudio
                            style={{
                              cursor: "pointer",
                              fontSize: "24px",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                            onClick={() =>
                              document
                                .getElementById(`audioInput_${index}`)
                                .click()
                            }
                            size={"80px"}
                            fill="#5cbcf6"
                          />
                        </FaudioIconContainer>
                        <div style={{ width: "80%", height: "100%" }}>
                          <ReactH5AudioPlayer
                            ref={audioPlayers.current[index - 1]}
                            autoPlayAfterSrcChange={false}
                            src={createAudioURL(
                              checkList[`url_record_${index}`]
                            )}
                            style={{ height: "80%" }}
                          />

                          <AudioText>
                            {checkList[`url_record_${index}`]?.[0]?.name !==
                              null &&
                            checkList[`url_record_${index}`]?.[0]?.name !==
                              undefined
                              ? checkList[`url_record_${index}`][0].name
                              : ""}
                          </AudioText>
                        </div>
                      </div>
                    </>
                  )}
                  <input
                    id={`audioInput_${index}`}
                    type="file"
                    name={`audioFiles_${index}`}
                    accept="audio/*"
                    multiple
                    onChange={(e) =>
                      handleFileChange(`url_record_${index}`, e.target.files)
                    }
                    style={{
                      position: "absolute",
                      width: "20%",
                      height: "100%",
                      top: 0,
                      left: 0,
                      opacity: 0,
                      cursor: "pointer",
                    }}
                  />
                </UploadAudioFileContainer>
              );
            })}
          </div>
        </CheckListCommentContainer>

        <CheckListCommentContainer>
          <CheckListLabel>
            Додайте скрішот:
            <UploadFileContainer onClick={() => handleImageClick}>
              {!checkList.url_image_1 && (
                <AiOutlinePlus
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  size={100}
                  fill="#5cbcf6"
                />
              )}
              {selectedImage && (
                <CheckListImage src={selectedImage} alt="Скріншот" />
              )}
              <input
                id="wiretappingImageInput"
                type="file"
                name="wiretappingImage"
                accept="image/*"
                onChange={handleImageFileChange}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  opacity: 0,
                  cursor: "pointer",
                }}
              />
            </UploadFileContainer>
          </CheckListLabel>
        </CheckListCommentContainer>

        <CheckListCommentContainer>
          <CheckListLabel>
            {wiretappingCheckList[language][0]?.comment}
            <CheckListTextArea
              value={checkList?.check_list_comment || ""}
              onChange={(e) => {
                const updatedCheckList = {
                  ...checkList,
                  check_list_comment: e.target.value,
                };
                setCheckList(updatedCheckList);
              }}
            />
          </CheckListLabel>

          <CheckListLabel>
            {wiretappingCheckList[language][0]?.fineAmount}

            <div style={{ marginTop: "10px" }}>
              <CheckListInput
                type="number"
                value={checkList?.fine_amount || 0}
                disabled
              />{" "}
              грн
            </div>
          </CheckListLabel>

          <CheckListLabel>
            Статус:
            {finesStatusData?.map(
              (item) =>
                item?.id === checkList?.fines_status_id && (
                  <CheckListFinesStatusContainer
                    style={{ marginTop: "10px" }}
                    key={item.id}
                  >
                    {language === "russian"
                      ? item.fine_status_title_ru
                      : item.fine_status_title_ua}
                  </CheckListFinesStatusContainer>
                )
            )}
          </CheckListLabel>

          <CheckListLabel>
            {wiretappingCheckList[language][0]?.finesCategoryLabel}
            <CheckListFinesCategoryContainer style={{ marginTop: "10px" }}>
              {isCreateNewCheckList &&
                checkList?.fines_category?.map((item, index) => (
                  <span key={index} style={{ display: "inline-block" }}>
                    {index !== 0 && <br />}
                    {language === "russian"
                      ? item?.fine_title_ru
                      : item?.fine_title_ua}
                  </span>
                ))}
              {!isCreateNewCheckList &&
                Array.isArray(checkList?.fines_category) &&
                checkList.fines_category.map((item, index) => (
                  <span key={index} style={{ display: "inline-block" }}>
                    {index !== 0 && <br />}
                    {language === "russian"
                      ? item?.fine_title_ru
                      : item?.fine_title_ua}
                  </span>
                ))}
            </CheckListFinesCategoryContainer>
          </CheckListLabel>
        </CheckListCommentContainer>
      </ListContainer>
      {isCreateNewCheckList && (
        <ButtonContainer>
          <SaveButton onClick={() => handleSubmit(checkList)}>
            {wiretappingCheckList[language][0]?.save}
          </SaveButton>
          <DeleteReportButton onClick={() => handleCancelClick()}>
            {wiretappingCheckList[language][0]?.cancel}
          </DeleteReportButton>
        </ButtonContainer>
      )}
      {!isCreateNewCheckList && (
        <ButtonContainer>
          <SaveButton onClick={() => handleSubmit(checkList)}>
            {wiretappingCheckList[language][0]?.update}
          </SaveButton>
          <DeleteReportButton
            onClick={() => {
              const updatedCheckList = {
                ...checkList,
                status_message_id: 4,
              };
              handleSubmit(updatedCheckList);
            }}
          >
            {wiretappingCheckList[language][0]?.delete}
          </DeleteReportButton>
        </ButtonContainer>
      )}
    </Main>
  );
};
