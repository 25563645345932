import { makeRequestSQL } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptMiddleware } from '../services/decryptMiddleware';

export const fetchScriptTemplate = async ({
    sortBy,
    order,
    searchQuery,
    currentPage,
    pageLimit,
    id
  }) => {
    try {
      const response = await makeRequestSQL.get('/scripts/script-template/', {
        params: {
          sortBy,
          order,
          search: searchQuery,
          page: currentPage,
          limit: pageLimit,
          id
        },
      });
  
      const decryptedData = await decryptMiddleware(response.data);

      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };
  
  export const updateScriptTemplate = async data => {
    try {
      const response = await makeRequestSQL.put('/scripts/script-template/', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };
  
  export const createScriptTemplate = async data => {
    try {
      const response = await makeRequestSQL.post('/scripts/script-template/', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };
  
  export const deleteScriptTemplate = async id => {
    try {
      const response = await makeRequestSQL.delete('/scripts/script-template/', {
        params: {
          id,
        },
      });
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };
  
  export const fetchScriptCategory = async () => {
    try {
      const response = await makeRequestSQL.get('/scripts/script-category/')
  
      const decryptedData = await decryptMiddleware(response.data);

      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const updateScriptCategory = async data => {
    try {
      const response = await makeRequestSQL.put('/scripts/script-category/', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };
  
  export const fetchScript = async ({
    sortBy,
    order,
    searchQuery,
    currentPage,
    pageLimit,
    id
  }) => {
    try {
      const response = await makeRequestSQL.get('/scripts/', {
        params: {
          sortBy,
          order,
          search: searchQuery,
          page: currentPage,
          limit: pageLimit,
          id
        },
      });
  
      const decryptedData = await decryptMiddleware(response.data);

      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const fetchScriptTitle = async ({
    sortBy,
    order,
    searchQuery,
    currentPage,
    pageLimit,
    id
  }) => {
    try {
      const response = await makeRequestSQL.get('/scripts/title', {
        params: {
          sortBy,
          order,
          search: searchQuery,
          page: currentPage,
          limit: pageLimit,
          id
        },
      });
  
      const decryptedData = await decryptMiddleware(response.data);

      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };
  
  export const updateScript = async data => {
    try {
      const response = await makeRequestSQL.put('/scripts/', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };
  
  export const createScript = async data => {
    try {
      const response = await makeRequestSQL.post('/scripts/', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };
  
  export const deleteScript = async id => {
    try {
      const response = await makeRequestSQL.delete('/scripts/', {
        params: {
          id,
        },
      });
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };