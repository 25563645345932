import { Modal } from "../../Modal/Modal";
import { Pagination } from "../../Pagination/Pagination";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import React, { useContext, useEffect, useState } from "react";
import {
  fetchFindFail,
  fetchFindFailCategories,
  createMutationFindFail,
  updateMutationFindFail,
  deleteMutationFindFail,
} from "../../../api/findFail";
import { UserContext } from "../../Context/UserContext";
import { useQuery } from "react-query";
import {
  AddedNewFailButton,
  ModalContainerFails,
  FailsSelect,
  FailsOption,
  FailsInput,
  SaveButton,
  ButtonContainer,
  Thead,
  TableContainer,
  ScheduleTable,
  ScheduleTr,
  ScheduleHeadTr,
  ScheduleTd,
  ScheduleTh,
  DeleteFailsButton,
} from "./FindFailUser.styled";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formattedDateToDayMonthYear } from "../../../helpers/dateAndTime/formattedDateToDayMonthYear";
import { failsText } from "../../../services/componentText";
import { getBackgroundColorForStatus } from "../../../helpers/backgroundColor/getBackgroundColorForStatus";

export const FindFailUser = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenUpdate, setIsModalOpenUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [employeeComment, setEmployeeComment] = useState("");
  const [productTitle, setProductTitle] = useState("");
  const sortBy = "date";
  const order = "DESC";
  const [fails, setFails] = useState([
    {
      find_fail_id: null,
      employee_full_name: currentUser?.full_name,
      order_id: null,
      product_title: productTitle,
      category_id: 1,
      employee_comment: employeeComment,
      status_id: 1,
    },
  ]);
  const maxLength = 250;

  const { data: failsData, refetch: refetchFindFail } = useQuery(
    ["failsData", currentPage, pageLimit, sortBy, order, currentUser.full_name],
    () =>
      fetchFindFail({
        currentPage,
        pageLimit,
        sortBy,
        order,
        full_name: currentUser.full_name,
      })
  );

  const { data: failCategoriesData } = useQuery(
    "failCategoriesData",
    fetchFindFailCategories
  );

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (failsData) {
      setTotalPages(failsData?.totalPages);
    }
  }, [failsData, pageLimit]);

  const handleEmployeeCommentChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue?.length <= maxLength) {
      setEmployeeComment(inputValue);
      const updatedFails = [...fails];
      updatedFails[0].employee_comment = e.target.value;
      setFails(updatedFails);
    }
  };

  const handleProductTitleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue?.length <= maxLength) {
      setProductTitle(inputValue);
      const updatedFails = [...fails];
      updatedFails[0].product_title = e.target.value;
      setFails(updatedFails);
    }
  };

  const handleSaveFail = async () => {
    const response = await createMutationFindFail(fails);

    if (response?.status === 204) {
      setProductTitle("");
      setFails([
        {
          find_fail_id: null,
          employee_full_name: currentUser?.full_name,
          order_id: null,
          product_title: productTitle,
          category_id: 1,
          employee_comment: employeeComment,
          status_id: 1,
        },
      ]);
      setEmployeeComment("");
      setIsModalOpen(false);
      refetchFindFail();
      toast.success(failsText[language][0]?.failSuccessSave);
    }
  };

  const handleClick = async (fail) => {
    if (
      currentUser?.full_name === fail?.employee_full_name &&
      fail?.status_id !== 1
    ) {
      toast.warning(failsText[language][0]?.youCantEditFail);
    }

    if (
      currentUser?.full_name === fail?.employee_full_name &&
      fail?.status_id === 1
    ) {
      setIsModalOpenUpdate(true);
      const updatedFails = [...fails];
      updatedFails[0].find_fail_id = fail?.find_fail_id;
      updatedFails[0].employee_full_name = fail?.employee_full_name;
      updatedFails[0].order_id = fail?.order_id;
      updatedFails[0].product_title = fail?.product_title;
      updatedFails[0].category_id = fail?.category_id;
      updatedFails[0].employee_comment = fail?.employee_comment;
      setFails(updatedFails);
      setEmployeeComment(fail?.employee_comment);
      setProductTitle(fail?.product_title);
    }
  };

  const handleUpdateFail = async () => {
    const response = await updateMutationFindFail(fails);

    if (response?.status === 204) {
      setProductTitle("");
      setEmployeeComment("");
      setFails([
        {
          find_fail_id: null,
          employee_full_name: currentUser?.full_name,
          order_id: null,
          product_title: productTitle,
          category_id: 1,
          employee_comment: employeeComment,
          status_id: 1,
        },
      ]);
      setIsModalOpenUpdate(false);
      refetchFindFail();
      toast.success(failsText[language][0]?.failSuccessUpdate);
    }
  };

  const handleDeleteFail = async () => {
    const response = await deleteMutationFindFail(fails[0]?.find_fail_id);

    if (response?.status === 200) {
      setProductTitle("");
      setEmployeeComment("");
      setFails([
        {
          find_fail_id: null,
          employee_full_name: currentUser?.full_name,
          order_id: null,
          product_title: productTitle,
          category_id: 1,
          employee_comment: employeeComment,
          status_id: 1,
        },
      ]);
      setIsModalOpenUpdate(false);
      refetchFindFail();
      toast.success(failsText[language][0]?.failSuccessRemoved);
    }
  };

  return (
    <div>
      {isModalOpen && (
        <Modal closeModal={() => setIsModalOpen(false)}>
          <ModalContainerFails>
            <div>
              <label>{failsText[language][0]?.chooseCategoryFail}</label>
              <FailsSelect
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  const updatedFails = [...fails];
                  updatedFails[0].category_id = e.target.value;
                  setFails(updatedFails);
                }}
              >
                {failCategoriesData &&
                  failCategoriesData.map((category) => (
                    <FailsOption
                      key={category?.category_id}
                      value={category?.category_id}
                    >
                      {language === "russian"
                        ? category?.category_title_RU
                        : category?.category_title_UA}
                    </FailsOption>
                  ))}
              </FailsSelect>
            </div>
            <div>
              <label>{failsText[language][0]?.enterOrderId}</label>
              <FailsInput
                placeholder={failsText[language][0]?.enterOrderIdInFormat}
                type="number"
                onChange={(e) => {
                  const updatedFails = [...fails];
                  updatedFails[0].order_id = e.target.value;
                  setFails(updatedFails);
                }}
                style={{ marginTop: "5px" }}
              />
            </div>
            <div>
              <label>{failsText[language][0]?.enterProductTitle}</label>
              <FailsInput
                type="text"
                onChange={handleProductTitleChange}
                value={productTitle}
                style={{ marginTop: "5px" }}
              />
              <p style={{ marginTop: "0" }}>
                {failsText[language][0]?.leftSymbols}{" "}
                {maxLength - productTitle?.length}/{maxLength}
              </p>
            </div>
            <div>
              <label>{failsText[language][0]?.employeeComment}</label>
              <FailsInput
                type="text"
                onChange={handleEmployeeCommentChange}
                value={employeeComment}
                style={{ marginTop: "5px" }}
              />
              <p style={{ marginTop: "0" }}>
                {failsText[language][0]?.leftSymbols}{" "}
                {maxLength - employeeComment?.length}/{maxLength}
              </p>
            </div>
            <ButtonContainer>
              <SaveButton onClick={handleSaveFail}>
                {failsText[language][0]?.createFail}
              </SaveButton>
            </ButtonContainer>
          </ModalContainerFails>
        </Modal>
      )}
      {isModalOpenUpdate && (
        <Modal closeModal={() => setIsModalOpenUpdate(false)}>
          <ModalContainerFails>
            <div>
              <label>{failsText[language][0]?.chooseCategoryFail}</label>
              <FailsSelect
                style={{ marginTop: "5px" }}
                value={fails[0].category_id}
                onChange={(e) => {
                  const updatedFails = [...fails];
                  updatedFails[0].category_id = e.target.value;
                  setFails(updatedFails);
                }}
              >
                {failCategoriesData &&
                  failCategoriesData.map((category) => (
                    <FailsOption
                      key={category?.category_id}
                      value={category?.category_id}
                    >
                      {language === "russian"
                        ? category?.category_title_RU
                        : category?.category_title_UA}
                    </FailsOption>
                  ))}
              </FailsSelect>
            </div>
            <div>
              <label>{failsText[language][0]?.enterOrderId}</label>
              <FailsInput
                placeholder={failsText[language][0]?.enterOrderIdInFormat}
                type="number"
                value={fails[0]?.order_id}
                onChange={(e) => {
                  const updatedFails = [...fails];
                  updatedFails[0].order_id = e.target.value;
                  setFails(updatedFails);
                }}
                style={{ marginTop: "5px" }}
              />
            </div>
            <div>
              <label>{failsText[language][0]?.enterProductTitle}</label>
              <FailsInput
                type="text"
                onChange={handleProductTitleChange}
                value={productTitle}
                style={{ marginTop: "5px" }}
              />
              <p style={{ marginTop: "0" }}>
                {failsText[language][0]?.leftSymbols}{" "}
                {maxLength - productTitle?.length}/{maxLength}
              </p>
            </div>
            <div>
              <label>{failsText[language][0]?.employeeComment}</label>
              <FailsInput
                type="text"
                onChange={handleEmployeeCommentChange}
                value={employeeComment}
                style={{ marginTop: "5px" }}
              />
              <p style={{ marginTop: "0" }}>
                {failsText[language][0]?.leftSymbols}{" "}
                {maxLength - employeeComment?.length}/{maxLength}
              </p>
            </div>
            <ButtonContainer>
              <SaveButton onClick={() => handleUpdateFail()}>
                {failsText[language][0]?.saveChanges}
              </SaveButton>
              <DeleteFailsButton
                onClick={() => handleDeleteFail(fails[0]?.find_fail_id)}
              >
                {" "}
                {failsText[language][0]?.deleteFail}
              </DeleteFailsButton>
            </ButtonContainer>
          </ModalContainerFails>
        </Modal>
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <AddedNewFailButton onClick={() => setIsModalOpen(true)}>
          {failsText[language][0]?.createNewFail}
        </AddedNewFailButton>
        <PageLimitSelector
          pageLimit={pageLimit}
          onChange={handlePageLimitChange}
        />
      </div>
      <TableContainer>
        <ScheduleTable>
          <Thead>
            <ScheduleHeadTr>
              <ScheduleTh>Дата</ScheduleTh>
              <ScheduleTh>{failsText[language][0]?.orderID}</ScheduleTh>
              <ScheduleTh style={{ width: "206px" }}>
                {failsText[language][0]?.productTitle}
              </ScheduleTh>
              <ScheduleTh style={{ width: "206px" }}>
                {failsText[language][0]?.commentEmployee}
              </ScheduleTh>
              <ScheduleTh>Статус</ScheduleTh>
              <ScheduleTh style={{ width: "205px" }}>
                {failsText[language][0]?.commentSupervisor}
              </ScheduleTh>
              <ScheduleTh>{failsText[language][0]?.category}</ScheduleTh>
            </ScheduleHeadTr>
          </Thead>
          <tbody>
            {failsData?.length > 0 ? (
              <>
                {failsData?.failsData?.map((fail) => {
                  const checkUser =
                    currentUser.full_name === fail?.employee_full_name;
                  const failStatusColor = getBackgroundColorForStatus(
                    fail?.status_id
                  );
                  return (
                    <ScheduleTr
                      key={fail?.find_fail_id}
                      onClick={() => handleClick(fail)}
                      backgroundColor={checkUser && "orange"}
                    >
                      <ScheduleTd>
                        {formattedDateToDayMonthYear(fail?.date)}
                      </ScheduleTd>
                      <ScheduleTd>{fail?.order_id}</ScheduleTd>
                      <ScheduleTd style={{ width: "213px" }}>
                        {fail?.product_title}
                      </ScheduleTd>
                      <ScheduleTd style={{ width: "213px" }}>
                        {fail?.employee_comment}
                      </ScheduleTd>
                      <ScheduleTd backgroundColor={failStatusColor}>
                        {language === "russian"
                          ? fail?.status_title_RU
                          : fail?.status_title_UA}
                      </ScheduleTd>
                      <ScheduleTd style={{ width: "213px" }}>
                        {fail.supervisor_comment
                          ? fail?.supervisor_comment
                          : ""}
                      </ScheduleTd>
                      <ScheduleTd>
                        {language === "russian"
                          ? fail?.category_title_RU
                          : fail?.category_title_UA}
                      </ScheduleTd>
                    </ScheduleTr>
                  );
                })}
              </>
            ) : (
              <>
                <ScheduleTd style={{width: '100%'}}>
                {failsText[language][0]?.notFound}
                </ScheduleTd>
              </>
            )}
          </tbody>
        </ScheduleTable>

        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </TableContainer>
      <ToastContainer autoClose={4000} />
    </div>
  );
};
