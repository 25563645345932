import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Thead,
  Table,
  Tr,
  HeadTr,
  Td,
  Th,
  SearchContainer,
  SearchInput,
  SearchButton,
  Input,
  IconButton,
  IconLinkContainer,
  TrashIconButton,
  PrdocutAvailabilityContainer,
  PrdocutAvailabilityNameContainer,
  PrdocutAvailabilityStatusContainer,
  Select,
  Option,
  PrdocutAvailabilityEditContainer,
} from "./ProductAvailabilityManager.styled";
import { UserContext } from "../../Context/UserContext";
import { AiOutlineSearch } from "react-icons/ai";
import { productAvailabilityText } from "../../../services/componentText";
import {  updateProduct, fetchProductAvailability } from "../../../api/product";
import { fetchProductStatus } from "../../../api/productAvailability";
import { LanguageSelector } from "../../LanguageSelector/LanguageSelector";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import { MdCancel, MdOutlineAddCircle } from "react-icons/md";
import { FaTrash, FaEdit, FaSave } from "react-icons/fa";
import { Pagination } from "../../Pagination/Pagination";

export const ManagerProductAvailability = () => {
  const { language } = useContext(UserContext);
  const [totalPages, setTotalPages] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [isRussianTitle, setIsRussianTitle] = useState(
    language === "russian" ? true : false
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(100);
  const [initialValues, setInitialValues] = useState({
    id: "",
    product_availability: [],
    title_ua: "",
    title_ru: "",
  });

  const { data: productData, refetch } = useQuery(
    ["productData", pageLimit, currentPage, searchQuery],
    () =>
      fetchProductAvailability({
        pageLimit,
        currentPage,
        sortBy: "title_ua",
        order: "ASC",
        searchQuery,
      })
  );

  useEffect(() => {
    if (productData) {
      setTotalPages(productData?.totalPages);
    }
  }, [productData, pageLimit]);

  const handleProductChange = (item) => {
    setInitialValues({
      ...item,
      statuses_message: 1,
    });
  };

  const handleAddProductOption = () => {
    initialValues?.product_availability?.length > 0
      ? setInitialValues({
          ...initialValues,
          product_availability: [
            ...initialValues.product_availability,
            { name: "", status: "" },
          ],
        })
      : setInitialValues({
          ...initialValues,
          product_availability: [{ name: "", status: "" }],
        });
  };

  const handleRemoveProductOption = (title) => {
    setInitialValues({
      ...initialValues,
      product_availability: initialValues.product_availability.filter(
        (item) => item.name !== title
      ),
      statuses_message: 1,
    });
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setSearchQuery(inputValue);
  };

  const resetValue = () => {
    setInitialValues({
      id: "",
      product_availability: [],
      title_ua: "",
      title_ru: "",
      description_ua: "",
      description_ru: "",
      statuses_message: 1,
    });
  };

  const handleUpdate = async () => {
    const response = await updateProduct(initialValues);

    if (response?.status === 201) {
      resetValue();
      toast.success(productAvailabilityText[language][0]?.successUpdate);
      refetch();
    }
  };

  const { data: productStatus } = useQuery("productStatus", fetchProductStatus);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  const handleCancelEdit = () => {
    resetValue();
  };

  const getProductAvailabilityStatus = (id) => {
    const findStatus = productStatus?.filter(
      (item) => Number(item?.product_status_id) === Number(id)
    );

    return language === "russian"
      ? findStatus[0]?.product_status_title_RU
      : findStatus[0]?.product_status_title_UA;
  };

  const checkStatusBackgroundColor = (id) => {
    const findStatus = productStatus?.filter(
      (item) => Number(item?.product_status_id) === Number(id)
    );

    if (findStatus[0]?.product_status_id === 1) {
      return "#27ae60";
    }

    if (findStatus[0]?.product_status_id === 2) {
      return "rgb(165, 52, 39)";
    }

    if (findStatus[0]?.product_status_id === 3) {
      return "rgb(231, 76, 60)";
    }
  };

  const handleUpdateField = (index, field, value) => {
    const updatedAvailability = [...initialValues.product_availability];
    updatedAvailability[index] = {
      ...updatedAvailability[index],
      [field]: value,
    };
    setInitialValues({
      ...initialValues,
      product_availability: updatedAvailability,
    });
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <SearchContainer onSubmit={(e) => handleSearch(e)}>
          <SearchInput
            type="text"
            placeholder={
              productAvailabilityText[language][0]?.enterProductTitle
            }
            value={inputValue}
            onChange={handleInputChange}
          />
          <SearchButton type="submit" onClick={(e) => handleSearch(e)}>
            <AiOutlineSearch size="24px" />
          </SearchButton>
        </SearchContainer>

        <PageLimitSelector
          pageLimit={pageLimit}
          onChange={handlePageLimitChange}
        />

        <LanguageSelector
          language={isRussianTitle}
          onChange={(value) =>
            setIsRussianTitle(value === "true" ? true : false)
          }
        />
      </div>
      <Table>
        <Thead>
          <HeadTr>
            <Th style={{ width: "28px" }}>№</Th>
            <Th>{productAvailabilityText[language][0]?.productTitle}</Th>
            <Th style={{ width: "500px" }}>
              {" "}
              {productAvailabilityText[language][0]?.informationAboutVariation}
            </Th>
            <Th style={{ width: "80px" }}>
              {productAvailabilityText[language][0]?.controlPanel}
            </Th>
          </HeadTr>
        </Thead>
        <tbody>
          {productData?.data?.length > 0 && (
            <React.Fragment>
              {productData?.data?.map((item, index) => (
                <Tr key={item?.id}>
                  {initialValues?.id === item?.id ? (
                    <>
                      <Td style={{ width: "50px" }}>{index + 1}</Td>
                      <Td>
                        {isRussianTitle  ? item.title_ru : item.title_ua}
                      </Td>
                      <Td style={{ width: "522px" }}>
                        {initialValues?.product_availability?.length > 0 ? (
                          initialValues?.product_availability?.map(
                            (availability, i) => (
                              <PrdocutAvailabilityEditContainer
                                key={i}
                                style={{ marginBottom: "10px" }}
                              >
                                <Input
                                  type="text"
                                  value={availability.name}
                                  onChange={(e) =>
                                    handleUpdateField(i, "name", e.target.value)
                                  }
                                  placeholder={
                                    productAvailabilityText[language][0]
                                      ?.enterColorOrSize
                                  }
                                />
                                <Select
                                  value={availability.status}
                                  onChange={(e) =>
                                    handleUpdateField(
                                      i,
                                      "status",
                                      e.target.value
                                    )
                                  }
                                  style={{ marginRight: "5px" }}
                                >
                                  <Option value="">
                                    {
                                      productAvailabilityText[language][0]
                                        ?.chooseAvailability
                                    }
                                  </Option>
                                  {productStatus?.map((status) => (
                                    <Option
                                      key={status?.product_status_id}
                                      value={status?.product_status_id}
                                    >
                                      {language === "russian"
                                        ? status?.product_status_title_RU
                                        : status?.product_status_title_UA}
                                    </Option>
                                  ))}
                                </Select>
                                <TrashIconButton
                                  onClick={() =>
                                    handleRemoveProductOption(availability.name)
                                  }
                                  style={{ width: "20px" }}
                                >
                                  <FaTrash size="18px" />
                                </TrashIconButton>
                              </PrdocutAvailabilityEditContainer>
                            )
                          )
                        ) : (
                          <IconButton
                            type="button"
                            onClick={handleAddProductOption}
                            style={{ width: "94%" }}
                          >
                            <MdOutlineAddCircle size="25px" />
                          </IconButton>
                        )}
                        {initialValues?.product_availability?.length > 0 && (
                          <div>
                            <IconButton
                              type="button"
                              onClick={handleAddProductOption}
                              style={{ width: "94%" }}
                            >
                              <MdOutlineAddCircle size="25px" />
                            </IconButton>
                          </div>
                        )}
                      </Td>

                      <Td style={{ width: "88px" }}>
                        <IconLinkContainer>
                          <IconButton onClick={handleUpdate}>
                            <FaSave size="18px" />
                          </IconButton>
                          <TrashIconButton onClick={() => handleCancelEdit()}>
                            <MdCancel size="18px" />
                          </TrashIconButton>
                        </IconLinkContainer>
                      </Td>
                    </>
                  ) : (
                    <>
                      <Td style={{ width: "50px" }}>
                        {(currentPage - 1) * pageLimit + (index + 1)}
                      </Td>
                      <Td>
                        {language === "russian" ? item.title_ru : item.title_ua}
                      </Td>
                      <Td style={{ width: "522px" }}>
                        {item?.product_availability &&
                          Array.isArray(item.product_availability) &&
                          item?.product_availability?.map((product) => (
                            <PrdocutAvailabilityContainer
                              key={product?.name + item.id}
                              checkStatus={checkStatusBackgroundColor(
                                product?.status
                              )}
                            >
                              <PrdocutAvailabilityNameContainer>
                                {product?.name}
                              </PrdocutAvailabilityNameContainer>
                              <PrdocutAvailabilityStatusContainer>
                                {getProductAvailabilityStatus(product?.status)}
                              </PrdocutAvailabilityStatusContainer>
                            </PrdocutAvailabilityContainer>
                          ))}
                      </Td>
                      <Td style={{ width: "88px" }}>
                        {initialValues?.id !== item?.id && (
                          <IconLinkContainer>
                            <IconButton
                              onClick={() => handleProductChange(item)}
                            >
                              <FaEdit size="20px" />
                            </IconButton>
                          </IconLinkContainer>
                        )}
                      </Td>
                    </>
                  )}
                </Tr>
              ))}
            </React.Fragment>
          )}
        </tbody>
      </Table>

      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};
